<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">设备管理</p>
    </div>
    <div class="tableBox" style="top:50px;left:16px;right:16px">
      <div class="views-main-search">
        <el-select v-model="type" placeholder="选择类型" class="iw">
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="status" placeholder="选择状态" class="iw">
          <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="bind" placeholder="选择是否绑定" class="iw">
          <el-option v-for="item in bindOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="sendQuery">查询</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="dlist" stripe style="width: 100%">
          <el-table-column prop="DEVICENO" label="编号"></el-table-column>
          <el-table-column prop="TYPE" label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.TYPE=='1'">手机</span>
              <span v-else-if="scope.row.TYPE=='2'">平板</span>
              <span v-else-if="scope.row.TYPE=='3'">保密</span>
            </template>
          </el-table-column>
          <el-table-column prop="STATUS" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.STATUS=='1'">空闲</span>
              <span v-else-if="scope.row.STATUS=='2'" style="color:#67C23A">在用</span>
              <span v-else-if="scope.row.STATUS=='3'" style="color:#F56C6C">损坏</span>
              <span v-else >未知</span>
            </template>
          </el-table-column>
          <el-table-column prop="USERRESPONSENAME" label="已绑人员"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              
              <el-button @click="unBindUser(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="scope.row.USERRESPONSENAME!=''">解绑</el-button>
              <span v-else>/</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog title="绑定人员" :visible.sync="showBindModal" width="500px">
      <el-form :model="userBindItem" status-icon :rules="bindrules" ref="bindForm" label-width="100px" class="demo-bindForm">
        <el-form-item label="人员" prop="USERGUID">
          <el-select v-model="userBindItem.USERGUID" placeholder="选择人员" class="iw">
            <el-option v-for="item in ulist" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
       
        <el-form-item>
          <el-button type="primary" @click="submitBindForm()">提交</el-button>
          <el-button  @click="closeBindModal()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="人员解绑" :visible.sync="unBindModal">
      <el-form :model="unBindItem" status-icon  ref="passForm" label-width="100px" class="demo-passForm">
        <el-form-item label="人员">
            <el-select v-model="unBindItem.user" placeholder="请选择人员">
              <el-option v-for="item in unBindItem.users" :key="item.GUID" :label="item.TRUENAME" :value="item.GUID"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="sendUNBind()">提交</el-button>
          <el-button @click=" unBindModal=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "DeviceManage",
  data() {
    var uid = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择一个用户'));
      } else {
        callback();
      }
    };
    return {
      unBindModal:false,
      showBindModal:false,
      type:"",
      status:"",
      bind:"",
      typeOptions: [
        {
          value: "",
          label: "全部类型",
        },
        {
          value: "1",
          label: "手机",
        },
        {
          value: "2",
          label: "平板",
        },
      ],
      statusOptions: [
        {
          value: "",
          label: "全部状态",
        },
        {
          value: "1",
          label: "空闲",
        },
        {
          value: "2",
          label: "在用",
        },
        {
          value: "3",
          label: "损坏",
        },
      ],
      bindOptions: [
        {
          value: "",
          label: "全部绑定情况",
        },
        {
          value: "1",
          label: "已绑定",
        },
        {
          value: "0",
          label: "未绑定",
        },
      ],
      unBindItem:{
        user:"",
        users:"",
        guid:"",
      },
      userBindItem:{
        USERGUID:"",
        DEVICENO:"",
      },
      ulist: [
        {
          value: "",
          label: "点击选择用户",
        },
        {
          value: "1",
          label: "方强",
        },
        {
          value: "2",
          label: "赵从瑞",
        },
        {
          value: "3",
          label: "何童升",
        },
        {
          value: "4",
          label: "董至忠",
        },
        {
          value: "5",
          label: "王秋雪",
        },
      ],
      bindrules: {
        USERGUID: [
          { validator: uid, trigger: 'blur' }
        ],
      },
      dlist:[],
      PageIndex:1,
      PageSize:10,
      Total:0,
    };
  },
  mounted() {
    this.loadItem=null
     this.getList()
  },
  methods: {
    sendQuery(){
      this.PageIndex=1
      this.getList()
    },
    getList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        TYPE:this.type,
        STATUS:this.status,
        ISBINDUSER:this.bind,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetDeviceList", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              var d=response.data.DATA
              var l=[]
              for(var i=0;i<d.length;i++){
                var di=d[i]
                di.USERRESPONSENAME="/"
                if(di.USERRESPONSE.length>0){
                  di.USERRESPONSENAME=di.USERRESPONSE[0].TRUENAME
                  for(var j=1;j<di.USERRESPONSE.length;j++){
                      di.USERRESPONSENAME+="、"+di.USERRESPONSE[j].TRUENAME
                  }
                }
                l.push(di)
              }
              this.dlist=l
              this.Total=response.data.TOTALCOUNT
          } else {
              this.dlist=[]
              this.Total=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
         this.hideLoading()
          this.dlist=[]
          this.Total=0
      });
    },
    PGChange(e){
      this.PageIndex=e
      this.getList()
    },
    bindUser(e){
      this.userBindItem={
        USERGUID:"",
        DEVICENO:e.DEVICENO
      }
      this.showBindModal=true
    },
    unBindUser(e){
      this.unBindItem={
        guid:e.GUID,
        users:e.USERRESPONSE,
        user:e.USERRESPONSE[0].GUID
      }
      this.unBindModal=true
    },
    sendUNBind(){
      this.showLoading("请稍后")
      var params={
        GUID:this.unBindItem.guid,
        USERGUID:this.unBindItem.user
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/DevUnBind", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if(response.data.SUCCESS){   
            var dl=this.dlist
            var l=[]
            var nu=[]
            var name='/'
            for(var i=0;i<this.unBindItem.users.length;i++){
              if(this.unBindItem.users[i].GUID!=this.unBindItem.user){
                nu.push(this.unBindItem.users[i])
              }
            }
            if(nu.length>0){
              name=nu[0].TRUENAME
              for(var j=1;j<nu.length;j++){
                  name+="、"+nu.TRUENAME
              }
            }
            for(var k=0;k<dl.length;k++){
              var ddl=dl[k]
              if(dl[k].GUID==this.unBindItem.guid){
                ddl.USERRESPONSENAME=name
                ddl.USERRESPONSE=nu
              }
              l.push(ddl)
            }
            this.dlist=l
            this.$message.success("已解绑")
            this.unBindModal=false
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
      });
    },
    submitBindForm() {
      this.$refs.bindForm.validate((valid) => {
        if (valid) {
          var p=this.userBindItem
          var name=""
          for(var i=0;i<this.ulist.length;i++){
            if(this.ulist[i].value == p.USERGUID){
              name =this.ulist[i].label
            }
          }
          this.$confirm('确定要将设备与'+name+'绑定?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.sendBind(name)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });          
          });

        } else {
          return false;
        }
      });
    },
    sendBind(name){
      this.$message({
        type: 'success',
        message: '已绑定!'
      });
          
      var dl=[]
      for(var i=0;i<this.dlist.length;i++){
        var item=this.dlist[i]
        if(item.DEVICENO == this.userBindItem.DEVICENO){
          item.STATUS="2"
          item.USERNAME=name
        }
        dl.push(item)
      }
      this.showBindModal=false
    },
    closeBindModal(){
      this.showBindModal=false
    }

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>

</style>
