<template>
  <div class="views-main views-full">
      <div class="views-main-title">
          <p class="txt">任务下发</p>
          <el-button type="warning" icon="el-icon-refresh" class="fr ml10" @click="UpdateDefaultData" size="small">修改默认数据</el-button>
          <!-- <el-button type="primary" icon="el-icon-download" class="fr ml10" @click="exportObject" size="small">任务导出</el-button> -->
          <!-- <div class="switchBox">
            <el-switch style="display: block" v-model="showModal" active-color="#13ce66" inactive-color="#13ce66"  inactive-text="列表模式" active-text="地图模式"></el-switch>
          </div>  -->
      </div>

      <div class="mapBox" v-if="showModal">
          <MAP ref="map" class="mapItem" :move="true" :draw="true" :task="false"></MAP>
      </div>
      <div class="tableBox" v-else>
          <div class="views-main-search">
              <el-cascader v-model="xzq"  :props="xzqprops" placeholder="搜索或选择地区" clearable filterable></el-cascader>
              <el-select v-model="lb" placeholder="选择类型" class="ml10">
                <el-option v-for="item in lbOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-select v-model="zt" placeholder="选择状态" class="ml10">
                <el-option v-for="item in ztOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-button type="primary" @click="queryTaskList" class="ml10">查询</el-button>
              
              <!-- <input type="file" id="fileDom" name="avatar" accept="aplication/zip,.zip,.rar"  style="display:none"   @change="checkFile"> -->
              <div style="overflow:hidden;margin-top:10px;float:right">
                <el-button type="warning" icon="el-icon-download" class="fr ml10" @click="issueTasksVillage" size="small">整村下发</el-button>
                <el-button type="info" icon="el-icon-download" class="fr ml10" @click="issueTasks" size="small">批量下发</el-button>
                <el-dropdown  class="fr ml10">
                  <el-button type="primary" icon="el-icon-upload" class="fr ml10" size="small">任务导入</el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="importTask('1')">城镇房屋</el-dropdown-item>
                    <el-dropdown-item @click.native="importTask('2')">农村住宅</el-dropdown-item>
                    <el-dropdown-item @click.native="importTask('3')">农村非住宅</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown  class="fr ml10">
                  <el-button type="success" icon="el-icon-plus" size="small">新建任务</el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="addTask('1')">城镇房屋</el-dropdown-item>
                    <el-dropdown-item @click.native="addTask('2')">农村住宅</el-dropdown-item>
                    <el-dropdown-item @click.native="addTask('3')">农村非住宅</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
          </div>
          <div class="views-main-body">
            <el-table :data="taskList" stripe style="width: 100%" @selection-change="handleSelectionChange">

                <el-table-column type="expand">
                  <template slot-scope="props" >
                    <el-form label-position="right" inline class="table-expand" style="text-align:right!important" v-if="props.row.USERINFO">
                      <span class="expandDown">姓名：{{ props.row.USERINFO.TRUENAME }}</span>
                      <span class="expandDown">所属部门：{{ props.row.USERINFO.DEPARTMENT }}</span>
                      <span class="expandDown">手机号：{{ props.row.USERINFO.MOBILEPHONE }}</span>
                      <span v-if="props.row.USERINFO.SEX=='1'" class="expandDown">性别：男</span>
                      <span v-else-if="props.row.USERINFO.SEX=='2'" class="expandDown">性别：女</span>
                      <span v-else class="expandDown">性别：未知</span>
                    </el-form>
                    <el-form label-position="right" inline class="table-expand" v-else>
                        <span class="expandDown">未下发或未查到人员信息</span>
                    </el-form>
                  </template>
                </el-table-column>  


              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="PROVINCE" label="省" width="100"></el-table-column>
              <el-table-column prop="CITY" label="市" width="100"></el-table-column>
              <el-table-column prop="DISTRICT" label="区" width="100"></el-table-column>
              <el-table-column prop="TOWN" label="镇"></el-table-column>
              <el-table-column prop="VILLAGE" label="村"></el-table-column>
              <el-table-column prop="FWBH" label="房屋编号"></el-table-column>


              <el-table-column prop="FWLB" label="房屋类别" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.FWLB=='0110'">城镇住宅房屋</span>
                  <span v-else-if="scope.row.FWLB=='0120'">城镇非住宅房屋</span>
                  <span v-else>其他</span>
                </template>
              </el-table-column>
              <el-table-column prop="TYPE" label="房屋类型" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.TYPE=='1'">城镇房屋</span>
                  <span v-else-if="scope.row.TYPE=='2'">农村住宅</span>
                  <span v-else-if="scope.row.TYPE=='3'">农村非住宅</span>
                </template>
              </el-table-column>
              <el-table-column prop="STATUS" label="状态" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS=='1'" style="color:#409EFF">已下发</span>
                  <span v-else-if="scope.row.STATUS=='0'" style="color:#909399">未下发</span>
                  <span v-else-if="scope.row.STATUS=='2'" style="color:#E6A23C">审核中</span>
                  <span v-else-if="scope.row.STATUS=='3'" style="color:#F56C6C">重新调查</span>
                  <span v-else-if="scope.row.STATUS=='4'" style="color:#67C23A">已完成</span>
                </template>
              </el-table-column>
              <el-table-column prop="STATUS" label="下发人" width="120">
                <template slot-scope="scope">
                   <span v-for="(item,i) in scope.row.USERLIST" :key="item.GUID">
                        <span v-if="i==0">{{item.TRUENAME}}</span>
                        <span v-else>,{{item.TRUENAME}}</span>  
                    </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                  <el-button @click="edit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS=='0'">编辑</el-button>
                  <el-button @click="del(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="scope.row.STATUS=='0'">删除</el-button>
                  <el-button @click="change(scope.row)"  type="text" size="small"  v-if="scope.row.STATUS=='0'">转类型</el-button>

                  <el-button @click="show(scope.row)" type="text" size="small" style="color:#67C23A" v-if="scope.row.STATUS!='0'">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
          </div>
      </div>
      <div class="editBox" v-if="showEditBox">
        <CZFW v-if="editType=='1'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></CZFW>
        <NCZZ v-if="editType=='2'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></NCZZ>
        <NCFZZ v-if="editType=='3'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></NCFZZ>
      </div>
      <el-dialog title="选择人员" :visible.sync="chooseUserModal" width="400px"  :append-to-body="true">
        <el-form  status-icon  ref="cuForm" label-width="100px" class="demo-cpForm">
          <el-form-item label="姓名">
            <el-select v-model="chooseUserGUID" placeholder="请选择人员" multiple filterable>
                    <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitChooseUserModal()">确定</el-button>
            <el-button @click="closeChooseUserModal()">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="房屋类型切换" :visible.sync="changeHouseType" width="400px"  :append-to-body="true">
        <el-form  status-icon  ref="cuForm" label-width="100px" class="demo-cpForm">
           <el-form-item label="当前" style="width:315px">
            <el-input   autocomplete="off" v-model="baseChange.label" readonly></el-input>
          </el-form-item>
          <el-form-item label="切换至">
            <el-select v-model="changeToType" placeholder="请选择人员">
                    <el-option v-for="item in changeToTypes" :key="item.value" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitChangeHouseType()">确定</el-button>
            <el-button @click="closeChangeHouseType()">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".zip,.rar,application/zip"
              action="http://192.168.1.126:8077/Manage/ImportRiskCensus"
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传zip/rar文件</div>
            </el-upload>
      </el-dialog>

      <el-dialog title="整村下发" :visible.sync="villageTaskModal" width="800px"  :append-to-body="true" top="50px">
          <div class="selectV">
              <span>地区搜索：</span>
              <el-cascader v-model="xzqV"  :props="xzqpropsV" placeholder="搜索或选择地区" clearable filterable></el-cascader>
              <el-select v-model="ztV" placeholder="选择状态" class="ml10">
                  <el-option label="未下发" value="0"></el-option>
                  <el-option label="已下发" value="1"></el-option>
              </el-select>
              <el-button type="primary" @click="queryVillageListA" class="ml10">查询</el-button>
          </div>
          <div class="selectV">
              <span>选择人员：</span>
             <el-select v-model="chooseUserGUID" placeholder="请选择人员" multiple filterable style="width:690px;">
                    <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </div>  
          <div class="tableV">
              <el-table :data="villageTaskList" stripe style="width: 100%" >
                <el-table-column prop="VILLAGENAME" label="行政村名称"></el-table-column>
                <el-table-column prop="VILLAGECODE" label="行政村编码"></el-table-column>
                <el-table-column prop="COUNT" label="数量"></el-table-column>
                <el-table-column label="操作" width="80">
                  <template slot-scope="scope">
                    <el-button @click="villageTask(scope.row)" type="text" size="small" style="color:#E6A23C">下发</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndexV" :page-size="PageSizeV" :total="TotalV" class="pg" @current-change="PGChangeV"></el-pagination>
          </div>
      </el-dialog>

      <el-dialog title="任务导出" :visible.sync="exportModal" width="800px" top="50px" :append-to-body="true">
        <div style="margin-bottom:30px;">
           <el-cascader v-model="xzqE"  :props="xzqpropsE" placeholder="搜索或选择地区" clearable filterable class="mr10"></el-cascader> 
           <el-button type="primary" @click="submitExport()">确定</el-button>
        </div>
        <div style="overflow:hidden">
          <el-table :data="fileList" stripe style="width: 100%">
            <el-table-column prop="CODE" label="行政区"></el-table-column>
            <el-table-column prop="COMPLATETIME" label="开始导出时间"></el-table-column>
            <el-table-column prop="DOWNLOADTIME" label="导出完成时间"></el-table-column>
            <el-table-column prop="STATUS" label="状态" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS=='1'" style="color:#67C23A">可下载</span>
                  <span v-else style="color:#E6A23C">导出中</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="downloadFile(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS=='1'">下载</el-button>
                <span v-else>/</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndexF" :page-size="PageSizeF" :total="TotalF" class="pg" @current-change="PGChangeF"></el-pagination>
        </div>
      </el-dialog>
      <div class="exportDownBox" v-if="showExportDownIcon">
        <i class="el-icon-circle-close closeDown" @click="closeExportLoading"></i>
        <div class="exportMsg" v-if="exportIng">
            <i class="el-icon-loading exportLoadingIcon"></i>
            <span class="exportLoadingTxt" style="color:#409EFF">正在下载,请稍后...</span>
        </div>
        <div class="exportMsg" v-else>
            <i class="el-icon-check exportLoadingIcon" v-if="exportOk" style="color:#67C23A" @click="toDownExport"></i>
            <span class="exportLoadingTxt" style="color:#67C23A" v-if="exportOk"  @click="toDownExport">下载完成，点击下载</span>
            <i class="el-icon-close exportLoadingIcon" v-if="!exportOk" style="color:#F56C6C" @click="refDownExport"></i>
            <span class="exportLoadingTxt" style="color:#F56C6C" v-if="!exportOk"  @click="refDownExport">下载失败，重新下载</span>
        </div>
      </div>
  </div>
</template>
<script>
import MAP from "../components/OpenLayers"
import CZFW from "../components/ListForCZFW"
import NCZZ from "../components/ListForNCZZ"
import NCFZZ from "../components/ListForNCFZZ"
export default {
  name: "TaskIssued",
  components:{
    MAP,
    CZFW,
    NCZZ,
    NCFZZ,
  },
  data() {
    return {

      showModal:false,
      xzq:[],
      xzqCode:"",
      xzqprops: {
          lazy: true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      lb:"1",
      lbOptions:[
        {
          value:"1",
          label:"城镇房屋",
        },
        {
          value:"2",
          label:"农村住宅",
        },
        {
          value:"3",
          label:"农村非住宅",
        },
      ],
      zt:"",
      ztOptions:[
        {
          value:"",
          label:"全部状态",
        },
        {
          value:"1",
          label:"已下发",
        },
        {
          value:"0",
          label:"未下发",
        },
        {
          value:"2",
          label:"审核中",
        },
        {
          value:"3",
          label:"重新调查",
        },
        {
          value:"4",
          label:"已完成",
        },
      ],
      taskList:[],
      checkList:[],
      PageIndex:1,
      PageSize:10,
      Total:0,

      showEditBox:false,

      editType:"",
      handleType:"1",
      handleBH:"",

      chooseUserModal:false,
      chooseUserGUID:[],
      userOptions: [],

      chooseFileModal:false,
      importType:"",

      baseChangeInfo:{},
      changeHouseType:false,
      changeToType:"",
      changeToTypes:[],
      baseChange:{},
      fromDataA:{
          BH:"",
          PROVINCE:"",
          CITY:"",
          DISTRICT:"",
          TOWN:"",
          VILLAGE:"",
          FWBH:"",
          FWLB:"",
          XCDCQK:"",
          BXDCYY:"",
          MC:"",
          LU_QG:"",
          HAO_QG:"",
          DONG_QG:"",
          JZMC:"",
          TAOSHU_QG:"",
          BUILD_TIME:"",
          DSCS:"",
          DXCS:"",
          DCMJ:"",
          GD:"",
          SFCQDJ:"",
          CQDW:"",
          CZFWJGLX:"",
          QTJGLX:"",
          SUB_JGLX:"",
          FWYT:"",
          SUB_FWYT:"",
          QTFWYT:"",
          SFCYJGZ:"",
          SFBHXJZ:"",
          SFZYSJJZ:"",
          SFSZCG:"",
          GZSJ:"",
          SFKZJG:"",
          JGSJ:"",
          YWLFBXQX:"",
          LFBXQXZP:"",
          YWWYGL:"",
          PHOTO:"",
          DCR:"",
          LXDH:"",
          BZ:"",
          DCSJ:"",
          STATUS:"1",
      },
      fromDataB:{
          BH:"",
          PROVINCE:"",
          CITY:"",
          DISTRICT:"",
          TOWN:"",
          VILLAGE:"",
          FWBH:"",
          FWLB:"1030",
          HOUSE_TYPE:"1",
          XCDCQK:"",
          BXDCYY:"",
          FWLX:"",
          JZMC:"",
          LDH_QG:"",
          AZHS:"",
          JZND_QG:"",
          ZZ_BH_QG:"",
          DBKJQT_QG:"",
          JZFS_QG:"",
          HZXM:"",
          HZLX_QG:"",
          QTJZFS_QG:"",
          CS:"",
          ZU_QG:"",
          LU_QG:"",
          HAO_QG:"",
          CZQT_QG:"",
          TMJG_QG:"",
          LWG_QG:"",
          DCMJ:"",
          JGLX:"",
          QTJGLX:"",
          SFKZJG:"",
          JGSJ:"",
          KZGZCS_QG:"",
          KZCSZP_QG:"",
          SFKZ_QG:"", 
          FWSJFS:"",
          YWLFBXQX:"",
          LFBXQXZP:"",
          SFJGAQJD:"",
          AQJDNF:"",
          AQJDJL:"",
          JDSFAQ:"",
          PHOTO:"",
          DCR:"",
          LXDH:"",
          BZ:"",
          DCSJ:"",
          ORG_NAME:"",
          STATUS:"1",
      },
      fromDataC:{
          BH:"",
          PROVINCE:"",
          CITY:"",
          DISTRICT:"",
          TOWN:"",
          VILLAGE:"",
          FWBH:"",
          FWLB:"1030",
          HOUSE_TYPE:"1",
          XCDCQK:"",
          BXDCYY:"",
          JZMC:"",
          JZND_QG:"",
          DBKJQT_QG:"",
          JZFS_QG:"",
          HZXM:"",
          HZLX_QG:"",
          QTJZFS_QG:"",
          CS:"",
          ZU_QG:"",
          LU_QG:"",
          HAO_QG:"",
          CZQT_QG:"",
          JZYT_QG:"",
          QTJZYT_QG:"",
          JYSS_QG:"",
          YLSS_QG:"",
          TMJG_QG:"",
          LWG_QG:"",
          DCMJ:"",
          JGLX:"",
          QTJGLX:"",
          SFKZJG:"",
          JGSJ:"",
          KZGZCS_QG:"",
          KZCSZP_QG:"",
          SFKZ_QG:"", 
          FWSJFS:"",
          YWLFBXQX:"",
          LFBXQXZP:"",
          SFJGAQJD:"",
          AQJDNF:"",
          AQJDJL:"",
          JDSFAQ:"",
          PHOTO:"",
          DCR:"",
          LXDH:"",
          BZ:"",
          DCSJ:"",
          ORG_NAME:"",
          STATUS:"1",
      },
      fromDataNow:{},

      ztV:"0",
      villageTaskModal:false,
      xzqV:[],
      xzqCodeV:"",
      xzqpropsV: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      villageTaskList:[],
      PageIndexV:1,
      PageSizeV:10,
      TotalV:0,
      
      showExportDownIcon:false,
      exportUrl:"",
      exportOk:true,
      exportIng:false,
      exportModal:false,
      
      xzqE:[],
      xzqCodeE:"",
      xzqpropsE: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      fileList:[],
      PageIndexF:1,
      PageSizeF:10,
      TotalF:0,

    };
  },
  mounted() {
    this.loadItem=null
    this.getUserList()
    //this.getTaskList()
  },
  computed: {

  },
  watch: {
    xzq(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCode=""
      }else{
        this.xzqCode=newVal[newVal.length-1]
      }
    },
    xzqV(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCodeV=""
      }else{
        this.xzqCodeV=newVal[newVal.length-1]
      }
    },
    xzqE(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCodeE=""
      }else{
        this.xzqCodeE=newVal[newVal.length-1]
      }
    },
  },
  methods: {
    //获取人员
    getUserList(){
      var params={
        PAGEINDEX:1,
        PAGESIZE:2000,
        ROLEGUID:"3"
      }
      //userOptions
      var uos=[]
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetUserList", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
              var d=response.data.DATA
              for(var i=0;i<d.length;i++){
                uos.push({label:d[i].TRUENAME+"("+d[i].LOGINNAME+")",value:d[i].GUID})
              }
              this.userOptions=uos
          } 
        })
    },
    //获取任务
    queryTaskList(){
      this.PageIndex=1
      this.getTaskList()
    },
    getTaskList(){
     this.showLoading("请稍后...")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        TYPE:this.lb,
        STATUS:this.zt,
        CODE:this.xzqCode,
        USERGUID:this.$store.state.guid,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetAllObject", md5_data)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
              this.taskList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
          } else {
              this.taskList=[]
              this.Total=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.taskList=[]
          this.Total=0
          this.hideLoading()
      });
    },
    //切换页码
    PGChange(e){
      this.PageIndex=e
      this.getTaskList()
    },
    //新建任务
    addTask(type){
      this.editType=type
      this.handleType="4"
      this.showEditBox=true
      this.$store.commit("setTaskStatus","")
      this.$store.commit("setTaskGuid","")
    },
    //导入任务
    importTask(type){
      this.importType=type
      // var d={IMPORTTYPE:type}
      // this.fileData =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.chooseFileModal=true
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      // fd.append("IMPORTTYPE",  this.importType);
      // fd.append("TOKEN", this.chooseFileModal.TOKEN);
      // fd.append("DATA", this.chooseFileModal.DATA);
      // fd.append("DATASIGN", this.chooseFileModal.DATASIGN);
      // //console.log(fd.get("file"))
      this.$upload.post("Manager/ImportRiskCensus?IMPORTTYPE="+this.importType, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("上传成功")
             this.$refs.upload.clearFiles()
             this.chooseFileModal=false
             this.getTaskList()
             var d=response.data.DATA
             var fstr=''
             if(d.FAILCOUNT>0){
                var fwbh=''
                for(var i=0;i<d.FAILLIST.length;i++){
                    if(i==0){
                      fwbh = d.FAILLIST[i].FWBH
                    }else{
                      fwbh=fwbh+"</br>"+d.FAILLIST[i].FWBH
                    }
                }
                fstr='<div class="rowItem"><p class="lb">失败房屋编号：</p><p class="con" style="color:#F56C6C">'+fwbh+'</p></div>'
             }
             var str='<div class="afterImportMsg"><div class="rowItem"><p class="lb">导入成功数量：</p><p class="con" style="color:#67C23A">'+d.SUCCESSCOUNT+'</p></div><div class="rowItem"><p class="lb">导入失败数量：</p><p class="con" style="color:#F56C6C">'+d.FAILCOUNT+'</p></div>'+fstr+'</div>'
             this.$alert(str, '任务导入结果', {
               dangerouslyUseHTMLString: true
             });
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },


    checkFile(e){
      //选择文件
      var d={IMPORTTYPE:this.importType}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      var f=document.getElementById("fileDom").files[0]
      var fd = new FormData();
      fd.append("file", f);
      fd.append("TIMESTAMP", md5_data.TIMESTAMP);
      fd.append("TOKEN", md5_data.TOKEN);
      fd.append("DATA", d);
      fd.append("DATASIGN", md5_data);


      // this.showLoading("请稍后...")
      // 

      // this.$http.post("Manager/GetDeviceList", md5_data)
      //   .then((response) => {
      //     //console.log(response)
      //    this.hideLoading()
      //     if (response.data.SUCCESS) {
      //         var d=response.data.DATA
      //         var l=[]
      //         for(var i=0;i<d.length;i++){
      //           var di=d[i]
      //           di.USERRESPONSENAME="/"
      //           if(di.USERRESPONSE.length>0){
      //             di.USERRESPONSENAME=di.USERRESPONSE[0].TRUENAME
      //             for(var j=1;j<di.USERRESPONSE.length;j++){
      //                 di.USERRESPONSENAME+="、"+di.USERRESPONSE[j].TRUENAME
      //             }
      //           }
      //           l.push(di)
      //         }
      //         this.dlist=l
      //         this.Total=response.data.TOTALCOUNT
      //     } else {
      //         this.dlist=[]
      //         this.Total=0
      //     }
      //   })
      //   .catch((error) => {
      //    this.hideLoading()
      //     this.dlist=[]
      //     this.Total=0
      // });
    },
  
    //选择任务
    handleSelectionChange(e){
      this.checkList=e
    },
    //查看任务
    show(e){
      this.handleType="5"
      this.handleBH=e.BH
      this.editType=e.TYPE
      this.showEditBox=true
      this.$store.commit("setTaskStatus",e.STATUS)
      this.$store.commit("setTaskGuid","")
    },
    //编辑任务
    edit(e){
      this.handleType="1"
      this.handleBH=e.BH
      this.editType=e.TYPE
      this.showEditBox=true
      this.$store.commit("setTaskStatus",e.STATUS)
      this.$store.commit("setTaskGuid","")
    },
    //关闭编辑
    closeEditTask(f){
      //console.log(f)
      if(f){
        this.getTaskList()
      }
      this.showEditBox=false
    },
    //删除任务
    del(e){
        this.$confirm('确定删除任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(e)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    sendDel(e){
      var d={TYPE:e.TYPE,BH:e.BH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/DeleteObjectInfo", md5_data)
        .then((response) => {
         //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.$message.success("删除成功")
             this.getTaskList()
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("提交失败，请稍后再试！");
        }); 
    },

    //批量下发
    issueTasks(){
      console.log(this.checkList)
       //console.log(this.checkList)
       if(this.checkList.length==0){
          this.$message.error('请至少选择一个任务');
       }else{
         this.chooseUserGUID=[]
         this.chooseUserModal=true
       }
    },
    submitChooseUserModal(){
      if(this.chooseUserGUID.length==0){
        this.$message.error('请至少选择一个人员');
      }else{
        this.showLoading("请稍后...")
        var d={CREATORGUID:this.$store.state.guid,EXECUTORGUID:this.chooseUserGUID,TASKLIST:this.checkList}
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/TaskRelease", md5_data)
          .then((response) => {
            //console.log(response)
           this.hideLoading()
            if (response.data.SUCCESS) {
                this.chooseUserModal=false
                this.$message.success('已下发！');
                this.getTaskList()
            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          }); 
      }
    },
    closeChooseUserModal(){
      this.chooseUserModal=false
    },

    //类型切换
    change(e){
      this.baseChangeInfo=e
      var d={}
      var l=[]
      for(var i=0;i<this.lbOptions.length;i++){
         if(this.lbOptions[i].value!=""){
            if(e.TYPE == this.lbOptions[i].value){
              d=this.lbOptions[i]
            }else{
                l.push(this.lbOptions[i])
            }
         }
      }
      this.changeToType=l[0].value
      this.changeToTypes=l
      this.baseChange=d
      this.changeHouseType=true

    },
    submitChangeHouseType(){
        this.$confirm('确定进行房屋类型转换吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.showLoading("请稍后...")
          this.getNewInfo()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    closeChangeHouseType(){
       this.changeHouseType=false
    },
    getNewInfo(){
      var d={TYPE:this.baseChangeInfo.TYPE,BH:this.baseChangeInfo.BH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/GetObjectInfo", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
              this.fromDataNow=response.data.DATA
              this.sendChange()
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
    },
    sendChange(){
      var d={}
      if(this.changeToType=="1"){
        d=this.fromDataA
      }else if(this.changeToType=="2"){
        d=this.fromDataB
      }else if(this.changeToType=="3"){
        d=this.fromDataC
      }
      for(var item in this.fromDataNow){
         if(d[item] !=undefined){
           d[item] = this.fromDataNow[item]
         }
      }
      var params={
        OLDTYPE:this.baseChangeInfo.TYPE,
        NEWTYPE:this.changeToType,
        SUBMITDATA:d
      }
      //console.log(params)
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/ReplaceObjInfo", md5_data)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if(response.data.SUCCESS){   
            this.changeHouseType=false
            this.$message.success("转换成功")
            this.getTaskList()
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
         this.hideLoading()
      });

    },

    issueTasksVillage(){
      this.villageTaskList=[]
      this.PageIndexV=1
      this.TotalV=0
      this.xzqV=[]
      this.chooseUserGUID=[]
      this.queryVillageList()
      this.villageTaskModal=true
    },
    queryVillageListA(){
        this.PageIndexV=1
        this.queryVillageList()
    },
    queryVillageList(){
        this.showLoading("请稍后...")
        var d={
          PAGEINDEX:this.PageIndexV,
          PAGESIZE:this.PageSizeV,
          VILLAGECODE:this.xzqCodeV,
          STATUS:this.ztV,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/GetVillageQuoteList", md5_data)
          .then((response) => {
            this.hideLoading()
            //console.log(response)
            if (response.data.SUCCESS) {
               this.villageTaskList=response.data.DATA
               this.TotalV=response.data.TOTALCOUNT
            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
              this.villageTaskList=[]
              this.TotalV=0
            }
          })
          .catch((error) => {
            this.villageTaskList=[]
            this.TotalV=0
            this.hideLoading()
          }); 
    } ,
    PGChangeV(e){
      this.PageIndexV=e
      this.queryVillageList()
    } ,
    villageTask(e){
      var u=this.chooseUserGUID
      if(u.length==0){
        this.$message.error("请至少选择一个人员")
      }else{
        var name=""
        var uo=this.userOptions
        for(var i=0;i<u.length;i++){
          for(var j=0;j<uo.length;j++){
              if(uo[j].value==u[i]){
                if(i==0){
                  name=uo[j].label
                }else{
                  name=name+"、"+uo[j].label
                }
              }
          }
        }
        var vn=""
        if(e.VILLAGENAME){
          vn=e.VILLAGENAME
        }else{
          vn=e.VILLAGECODE
        }
        this.$confirm('确定要将'+vn+"的所有任务下发给 "+name+" 吗？", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendVillage(e)
        })
      }
    },
    sendVillage(e){
      this.showLoading("下发中,请稍后...")
      var d={VILLAGECODE:e.VILLAGECODE,EXECUTORGUID:this.chooseUserGUID,CREATORGUID:this.$store.state.guid}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/VillageTaskRelease", md5_data)
        .then((response) => {
          this.hideLoading()
          //console.log(response)
          if (response.data.SUCCESS) {
              this.$message.success("下发成功！")
              this.villageTaskModal=true
              this.queryVillageList()
              this.getTaskList()
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          }); 
    },

    exportObject(){
      // if(this.exportIng){
      //   this.$message.error("您正在下载数据，请等待本次下载完成！")
      // }else{
      //   this.exportModal=true
      // }
      this.PageIndexF=1
      this.getFileList()
      this.exportModal=true
    },
    closeExport(){
      this.exportModal=false
    },
    submitExport(){
      if(this.xzqCodeE==""){
        this.$message.error("请选择一个村")

      }else{
        // this.exportIng=true
        // this.showExportDownIcon=true
        // this.exportOk=false
        // this.exportUrl=""
        var d={
          CODE:this.xzqCodeE,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/ExportObject", md5_data)
          .then((response) => {
            // if(response.data.SUCCESS){
            //     this.exportUrl=response.data.DATA
            //     this.showExportDownIcon=true
            //     this.exportOk=true
            // } else{
            //   this.exportOk=false   
            // }
            // this.exportIng=false
            if(!response.data.SUCCESS){
              this.$message.error("当前行政区数据正在导出中，请选择其他行政区！")
            }
            
          })
          .catch((error) => {
            // this.exportOk=false   
            // this.exportIng=false
            this.$message.error("请求出错，请稍后再试！")
          }); 
          // this.$confirm('后台正在下载数据，请勿刷新页面\r\n您可以在界面左下角查看下载情况', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //     this.exportModal=false
          //   }).catch(() => {
          //     this.exportModal=false
          // });
          setTimeout(()=>{
            this.getFileList()
          },1000)
      }
      
    },
    closeExportLoading(){
      this.showExportDownIcon=false
    },
    toDownExport(){
      if(this.exportUrl==""){
         this.$confirm('下载地址异常，请刚重新下载！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
      }else{
        window.open(this.exportUrl);
      }
    },
    refDownExport(){
      this.showExportDownIcon=false
      this.exportModal=true
    },
    getFileList(){
     var params={
        PAGEINDEX:this.PageIndexF,
        PAGESIZE:this.PageSizeF,
        TYPE:"1",
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/ExportList", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
              this.fileList=response.data.DATA
              this.TotalF=response.data.TOTALCOUNT
          } else {
              this.fileList=[]
              this.TotalF=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.fileList=[]
          this.TotalF=0
      });
    },

    PGChangeF(e){
      //
      this.PageIndexF=e
      this.getFileList()
    },
    downloadFile(e){
      window.open(e.DOWNLOADURL);
    },

    //修改默认数据
    UpdateDefaultData(){
      this.showLoading("请稍后...")
      var params={}
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Api/UpdateDefaultData", md5_data)
        .then((response) => {
          //console.log(response)
           this.hideLoading()
          if (response.data.SUCCESS) {
              this.getTaskList()
          } 
        })
        .catch((error) => {
          this.hideLoading()
      });
    }
  },
};
</script>
<style scoped>
  .switchBox{float:right;margin-top:6px;margin-left: 20px;}
  .editBox{position:absolute;left: 0;right: 0;bottom: 0;top: 0;z-index: +1;overflow: hidden;background-color: #FFFFFF;}
  .mapBox{position: absolute;left: 0;right: 0;top: 42px;bottom:0;z-index: +1;}
  .mapItem{width: 100%;height: 100%;}
  .table-expand{text-align: center;}
  .expandDown{font-size: 14px;margin-right: 20px;color: #48A2FF;}
  .selectV{margin-bottom: 10px;}
  .tableV{overflow: hidden;}
</style>
