<template>
    <div class="main">
        <p class="title">举证信息平台</p>
        <div class="loginBox">
            <div class="row rowIpt">
                <img src="statics/img/user.png"/>
                <input placeholder="请输入账号" style="-webkit-user-select:text !important;" type="text"  v-model="login.LOGINNAME"  />
            </div>
            <div class="row rowIpt">
                <img src="statics/img/pwd.png" />
                <input placeholder="请输入密码" style="-webkit-user-select:text !important;" type="password" v-model="login.PASSWORD"/>
            </div>
            <div class="row rowBtn" @click="sendLogin">登 录</div>
            <div class="regrow">
              <span class="regbtn" @click="registerModal = true">新用户注册</span>
            </div>
        </div>
        <div class="modelCover" v-show="registerModal" @click="closeRegBox"></div>
        <div class="modelItem regBox" v-show="registerModal">
          <div class="mtitle">用户注册</div>
          <p class="close"  @click="closeRegBox">×</p>
          <div class="content">
              <mt-field label="登录名" placeholder="请输入登录名" v-model="reg.LOGINNAME"></mt-field>
              <mt-field label="密码" placeholder="请输入密码" v-model="reg.PASSWORD" type="password"></mt-field>
              <mt-field label="密码确认" placeholder="请再次输入密码" v-model="reg.PASSWORDB" type="password"></mt-field>
              <mt-field label="真实姓名" placeholder="请输入真实姓名" v-model="reg.TRUENAME"></mt-field>
              <mt-field label="手机号" placeholder="请输入手机号" v-model="reg.MOBILE"></mt-field>
              <mt-field label="性别">
                 <el-radio v-model="reg.SEX" label="1">男</el-radio>
                 <el-radio v-model="reg.SEX" label="2">女</el-radio>
              </mt-field>
          </div>
          <div class="bottom">
              <div class="btn ok" @click="register">确定</div>
              <div class="btn no" @click="closeRegBox">取消</div>
          </div>
        </div>
    </div>
</template>
<style src="../../public/statics/css/mobile.css"></style>
<style src="../../public/statics/css/mobileLogin.css"></style>
<script>
export default {
  name: "Login",
  data() {
    return {
      login:{
        LOGINNAME:"",
        PASSWORD:"",
      },
      reg:{
        LOGINNAME:"",
        PASSWORD:"",
        PASSWORDB:"",
        TRUENAME:"",
        MOBILE:"",
        SEX:"1",
      },
      registerModal:false,
      loginName:"",
      loginPwd:"",

    };
  },
  mounted() { 
    this.loadItem=null
    var  code=  this.comjs.GetQueryString("code")
    this.$store.commit('setCode', code);  

    var token=this.$cookies.get("token")
    var et=this.$cookies.get("token_end")
    if(token && et){
       var b=new Date().getTime()
       var e=new Date(et).getTime()
       if(b<e){
          this.$router.replace("/MobileTaskList")
       }else{
          this.$cookies.remove("token")
          this.$cookies.remove("token_end")
       }
    }
    //this.mint.Toast('Upload Complete');

    // var  type=this.$cookies.get("user_type")
    //   if(type){
    //     if(type=="1"){
    //       this.$router.replace("/UserManage") 
    //     }else if(type=="2"){
    //         this.$router.replace("/TaskIssued")
    //     }
    //   }
  },
  methods: {
    closeRegBox(){
      this.registerModal=false
    },

    sendLogin(){
        if(this.login.LOGINNAME==""){
          this.mint.Toast('请输入登录名');
        }else if(this.login.PASSWORD==""){
          this.mint.Toast('请输入密码');
        }else{
          this.showLoading("请稍后")

          var md5_data =this.comjs.requestDataEncrypt(this.login, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.login)).toUpperCase())
          this.$http.post("Login/H5Login", md5_data)
            .then((response) => {
              console.log(response)
              this.hideLoading()
              if (response.data.SUCCESS) {
                    this.$cookies.set("token",response.data.DATA.TOKEN)
                    this.$cookies.set("token_end",response.data.DATA.ENDTIME)
                    this.$router.replace("/MobileTaskList")
              }else{
                this.mint.Toast("response.data.MSG")
              }
            })
            .catch((error) => {
               this.hideLoading()
            });
        }
    },
    register(){
      const phoneStr = /^1[3456789]\d{9}$/
      console.log(this.reg)
      if(this.reg.LOGINNAME==""){
        this.mint.Toast('请输入登录名');
      }else if(this.reg.PASSWORD==""){
        this.mint.Toast('请输入密码');
      }else if(this.reg.PASSWORD !=this.reg.PASSWORDB){
        this.mint.Toast('两次密码不一致');
      }else if (this.reg.MOBILE!="" && !phoneStr.test(this.reg.MOBILE)) {
        this.mint.Toast("请输入正确的手机号")
      } else{
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.reg, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.reg)).toUpperCase())
        this.$http.post("Login/H5RegisterUser", md5_data)
        .then((response) => {
          console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
              this.mint.Toast({
                message: '注册成功',
                iconClass: 'icon icon-success'
              });
              this.closeRegBox()
          }else{
             this.mint.Toast("response.data.MSG")
          }
        })
        .catch((error) => {
         this.hideLoading()
        });
      }
      

     
      console.log(md5_data)
    },
    submitUserForm(){
      this.$refs.uForm.validate((valid) => {
        if (valid) {
          this.registerModal=false
          this.$alert('注册成功，请等待管理员审核！', '操作提示', {
            confirmButtonText: '确定',
            callback: action => {
              console.log("1")
            }
          });
        } else {
          return false;
        }
      });


    },
    closeUserForm(){
      this.$message.info('已取消');
      this.registerModal=false
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
