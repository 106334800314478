<template>
  <div class="map" id="mapbox">
    <OLMOVE class="mapMove" ref="olmove" :map="map" :view="view" v-if="move"
    ></OLMOVE>
    <OLDRAW class="mapDraw" ref="oldraw" :map="map" v-if="draw"></OLDRAW>
    <div class="mapType">
      <el-radio-group v-model="mapType" size="mini" @change="mapTypeChange">
        <el-radio-button label="矢量"></el-radio-button>
        <el-radio-button label="影像"></el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="task" class="task">
        <div class="titles">
            <p class="zs">总数：23</p>
            <p class="yxf">已下发：16</p>
            <p class="wxf">未下发：7</p>
        </div>
        <div class="checkItem">
           <p class="txt">当前选择：</p>
           <div class="infoBox">
              <p class="infoItem">房屋编号：{{infoBH}}</p>
              <p class="infoItem">房屋类型：{{infoTYPE}}</p>
              <p class="infoItem">下发状态：{{infoSTATUS}}</p>
              <p class="infoItem">调查地址：{{infoADDRESS}}</p>
           </div>
           <br>
           <div class="handleBox">
              <div v-if="handleStatus=='0'" class="inrow">
                  <el-button type="success" class="btnItem">下发</el-button>
                  <el-button type="warning" class="btnItem">编辑</el-button>
                  <el-button type="danger" class="btnItem">删除</el-button>
                  <el-button type="primary" class="btnItem" @click="startCut">拆分</el-button>
                  <el-button type="primary" class="btnItem">合并</el-button>
              </div>
              <div v-else class="inrow">
                  <div class="inrow" v-if="handleType=='1'">
                      <span>请绘制一条拆分该图斑的线段</span>
                       <el-button type="primary" class="btnItem" @click="drawCutLine" v-if="cutLine==null">绘制线段</el-button>
                       <el-button type="info" class="btnItem" @click="removeCutLine" v-else>删除线段</el-button>
                      <el-button type="success" class="btnItem" @click="sureCutLine">确定拆分</el-button>
                      <el-button type="danger" class="btnItem" @click="cancelCutLine">取消拆分</el-button>
                  </div>
                  <div class="inrow" v-else-if="handleType=='2'">
                      <span>请点击一个要合并的图斑</span>
                      <el-button type="success" class="btnItem">确定合并</el-button>
                      <el-button type="danger" class="btnItem">取消合并</el-button>
                  </div>
              </div>
              
           </div>
        </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<style scoped>
.map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.mapMove {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
}
.mapDraw {
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 100;
}
.mapType {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}
.task{position: absolute;left: 0;bottom: 0;right: 0;border-radius: 0;background-color: #FFFFFF;z-index: 666;overflow: hidden;padding: 0 20px;height: 157px;}
.task .titles{height: 50px;overflow: hidden;}
.task .zs{font-size: 16px;float:left;margin-top: 15px;}
.task .yxf{font-size: 16px;color: #67C23A;float:left;margin-left: 20px;margin-top: 15px;} 
.task .wxf{font-size: 16px;color: #48A2FF;float:left;margin-left: 20px;margin-top: 15px;} 
.checkItem{overflow: hidden;width: 100%;}
.checkItem .txt{font-size: 18px;float:left;}
.checkItem .infoBox{overflow: hidden;float:left;margin-top: 3px;margin-left: 20px;}
.checkItem .infoBox .infoItem{color: #444444;float: left;margin-right: 20px;font-size: 16px;}
.handleBox{overflow: hidden;width: 100%;margin-top: 10px;}
.handleBox .btnItem{float:left;margin-right: 20px;width: 120px!important;}
</style>
<script>
import "ol/ol.css";

import Map from "ol/Map";
import View from "ol/View";
import Overlay from 'ol/Overlay';
import XYZ from "ol/source/XYZ";
import Projection from 'ol/proj/Projection';
import { OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Circle, Style } from "ol/style";
import {boundingExtent,getCenter} from 'ol/extent';
// import proj4 from 'proj4'
import {register} from 'ol/proj/proj4';
import OLMOVE from "../components/OpenLayersMove";
import OLDRAW from "../components/OpenLayersDraw";
import * as turf from '@turf/turf'
export default {
  components: {
    OLMOVE,
    OLDRAW,
  },
  name: "OLMAP",
  props: {
    task: {
      type: Boolean,
      default: false,
    },
    move: {
      type: Boolean,
      default: false,
    },
    draw: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 5,
    },
    center: {
      type: Array,
      default: function () {
        return [127.2279134001,44.9312070273];
      },
    },
  },
  data() {
    return {
      mapType: "矢量",
      map: null,
      view: null,
      mapLayerA: null,
      mapLayerB: null,
      mapLayerC: null,
      mapLayerD: null,
      mapSourceA: null,
      mapSourceB: null,
      mapSourceC: null,
      mapSourceD: null,


      handleType:"0",
      handleStatus:"0",
      cutLine:null,
      mergeFea:null,
      checkFea:null,
      checkTask:false,
      polygons: [],
      taskItemInfo:{
        BH:"695d7bc4c0984efeb66c1a3fb615f112",
        CITY: "230100",
        DISTRICT: "五常市",
        FWBH: "341021000076108",
        FWLB: "0130",
        PROVINCE: "230000",
        STATUS: "0",
        STATUSNAME: "0",
        TABLENAME: "RISK_CENSUS_COUNTRY_HOUSE",
        TOWN: "230184103000",
        TYPE: "2",
        TYPENAME: "2",
        VILLAGE: "双山村村委会",
        VILLAGECODE: "230184103207",
      }
    };
  },
  mounted() {
    //查看器
    this.loadItem=null
     proj4.defs(
        "EPSG:4490",
        "+proj=longlat +ellps=GRS80 +no_defs"
      )
      register( proj4);
      

      var Extent=[80,20,134,50]
      var p=new Projection({
        code:"EPSG:4490",
        extent:Extent
      })
      this.view=new View({
          projection: p,
          center: this.center,
          maxZoom:15,
          minZoom:1,
          zoom: 10,
      }),
    this.view.addEventListener("change", function (e) {
      //console.log(e)
    });

    //console.log(this.view.getProjection())
    //地图层矢量
    this.mapSourceA = new XYZ({
      //url: "https://investigation.17fbfb.com/Map/DataServer?T=vec_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      //url: "https://t4.tianditu.com/DataServer?T=vec_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      url:"https://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    //地图层矢量标注
    this.mapSourceB = new XYZ({
      //url: "https://investigation.17fbfb.com/Map/DataServer?T=cva_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      //url: "https://t4.tianditu.com/DataServer?T=cva_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      url:"https://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    //地图影像
    this.mapSourceC = new XYZ({
      //url: "https://investigation.17fbfb.com/Map/DataServer?T=img_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      //url: "https://t4.tianditu.com/DataServer?T=img_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      url:"https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    //影像标注
    this.mapSourceD = new XYZ({
      //url: "https://investigation.17fbfb.com/Map/DataServer?T=cia_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      //url: "https://t4.tianditu.com/DataServer?T=cia_w&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b&x={x}&y={y}&l={z}",
      url:"https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
    })
    // this.mapSource=new OSM()
    this.mapLayerA = new TileLayer({
      source: this.mapSourceA,
      zIndex: 2,
    })
    this.mapLayerA.set("name", "vec_c");
    this.mapLayerB = new TileLayer({
      source: this.mapSourceB,
      zIndex: 3,
    });
    this.mapLayerB.set("name", "cva_c");
    this.mapLayerC = new TileLayer({
      source: this.mapSourceC,
      zIndex: 2,
      visible: false,
    });
    this.mapLayerC.set("name", "img_c");
    this.mapLayerD = new TileLayer({
      source: this.mapSourceD,
      zIndex: 3,
      visible: false,
    });
    this.mapLayerD.set("name", "cia_c");
    //地图实例
    this.map = new Map({
      //图层
      layers: [this.mapLayerA, this.mapLayerB, this.mapLayerC, this.mapLayerD],
      target: "mapbox",
      view: this.view,
    });
    //console.log("地图加载完毕")
    //移除默认控件
    var contros = this.map.getControls().array_;
    for (var i = 0; i < contros.length; i++) {
      this.map.removeControl(contros[i]);
    }
    //地图点击事件
    // this.map.addEventListener('click', function(e){
    //   console.log(e)
    // });
    if (this.task) {
      this.map.on('singleclick', e => {
        //console.log(this.$refs.oldraw.drawing)
        if(!this.$refs.oldraw.drawing){
          //没有在拆分或合并时
          if(this.handleType=="0" && this.handleStatus=="0"){
              //取消选中
              if(this.checkFea){
                this.setFeaStyle(this.checkFea,"rgba(237,63,20,0.2)","#ed3f14","rgba(237,63,20,1)")
                this.checkFea=null
                this.checkTask=false
              }
              //当前选中的图形
              this.map.forEachFeatureAtPixel(
                e.pixel,
                (fea) => {
                    if(fea.get("tbtype")=="task"){
                      this.checkFea=fea
                      this.checkTask=true
                      this.setFeaStyle(fea,"rgba(103,194,58,0.2)","#67C23A","rgba(103,194,58,1)")
                      this.getTaskInfoById(fea.getId())
                    }
                },
                {
                  hitTolerance: 20
                }
              );
          }else{
            //在合并时
            if(this.handleType=="2" && this.handleStatus=="1"){
                //当前选中的图形
                this.map.forEachFeatureAtPixel(
                  e.pixel,
                  (fea) => {
                      this.mergeFea=fea
                      this.setFeaStyle(fea,"rgba(64,158,255,0.2)","#409EFF","rgba(64,158,255,1)")
                  },
                  {
                    hitTolerance: 20
                  }
                );
            }
          }

        }

      });
    }
    // setTimeout(()=>{
    //   this.addFeatureFromTask()
    // },2000)
  },
  methods: {
    //通过图斑ID查询图斑信息
    getTaskInfoById(id){
      console.log(id)
    },

    /*
     *拆分
     */
    //开始
    startCut(){
      if(this.checkTask){
         this.handleType="1"
        this.handleStatus="1"
        this.cutLine=null
      }else{
        this.$message.error("请选择一个图形")
      }
     
    },
    //画线段
    drawCutLine(){
      this.$refs.oldraw.beginDraw("LineString", false, null, this.afterDrawLine);
    },
    afterDrawLine(fea){
        this.cutLine=fea
        this.endDraw()
        var fid=fea.getId()
        //移除绘制时创建的删除按钮
        var  overlays=this.map.getOverlays().array_
        for(var i=0;i<overlays.length;i++){
            if(overlays[i].get("fid")== fid){ 
              this.map.removeOverlay(overlays[i])
            }
        }

    },
    //取消
    cancelCutLine(){
      this.handleType="0"
      this.handleStatus="0"
      if(this.cutLine){
        this.delDrawFeature(this.cutLine.getId(),"LineString")
      }
      this.cutLine=null
      this.endDraw()
    },
    //删除线段
    removeCutLine(){
       if(this.cutLine){
        this.delDrawFeature(this.cutLine.getId(),"LineString")
      }
      this.cutLine=null
    },
    //开始拆分
    sureCutLine(){
      if(this.checkFea){
        if(this.cutLine){
            this.computeCutLine()
        }else{
          this.$message.error("请先绘制线段")
        }
      }else{
        this.$message.error("请先选择要拆分的图形")
      }
    },

    //计算拆分
    computeCutLine(){
        //截取线
        var lineCoordinates=this.cutLine.getGeometry().getCoordinates()
        console.log("初始线")
        console.log(lineCoordinates)
        var startPoint=lineCoordinates[0]
        var endPoint=lineCoordinates[lineCoordinates.length-1]
        //多边形
        var polygonCoordinates=this.checkFea.getGeometry().getCoordinates()
        //起点和终点在多边形外
        if(!this.flagPointInPolygon(polygonCoordinates,startPoint) && !this.flagPointInPolygon(polygonCoordinates,endPoint) ){
            //多边形线段集合
            var polygonForLineList=polygonCoordinates[0]
            console.log("初始面")
            console.log(polygonForLineList)

            var cutCount=this.computeLineIntersectCount(polygonForLineList,lineCoordinates)
            if(cutCount!=2){
              this.$message.error("线段只能与图形有两个交点，请重新绘制！")
              return;
            }
            //计算第一个交点信息
            var  startCut=this.computeLineIntersect(polygonForLineList,lineCoordinates,null)
            console.log("开始点")
            console.log(startCut)
            //计算地二个交点信息
            var endCut=this.computeLineIntersect(polygonForLineList,lineCoordinates,startCut.point)
            console.log("结束点")
            console.log(endCut)
          
            //截取前后部分多余线段
            var lineByEnd=[]
            // var zf=this.flagDirection(startCut.point,lineCoordinates[0],lineCoordinates[lineCoordinates.length-1])
            var zf=true
            if(startCut.lineIndex > endCut.lineIndex){
              zf=false
            }
            if(zf){
              lineByEnd.push(startCut.point)
              for(var i=startCut.lineIndex+1;i<=endCut.lineIndex;i++){
                 lineByEnd.push(lineCoordinates[i])
              }
              lineByEnd.push(endCut.point)
            }else{
                lineByEnd.push(endCut.point)
                for(var i=endCut.lineIndex+1;i<=startCut.lineIndex;i++){
                  lineByEnd.push(lineCoordinates[i])
                }
                lineByEnd.push(startCut.point)
            }

            console.log("最终线")
            console.log(lineByEnd)
            var newPolygonA=[]
            var newPolygonB=[]

            if(zf){
              //第一个面
              newPolygonA.push(startCut.point)
              for(var k=startCut.poligonIndex+1;k<=endCut.poligonIndex;k++){
                newPolygonA.push(polygonForLineList[k])
              }
              for(var b=lineByEnd.length-1;b>=0;b--){
                newPolygonA.push(lineByEnd[b])
              }
              //第二个面
              newPolygonB.push(endCut.point)
              for(var x=endCut.poligonIndex+1;x<polygonForLineList.length;x++){
                newPolygonB.push(polygonForLineList[x])
              }
              if(startCut.poligonIndex>0){
                for(var y=1;y<=startCut.poligonIndex;y++){
                  newPolygonB.push(polygonForLineList[y])
                }
              }
              for(var v=0;v<lineByEnd.length;v++){
                  newPolygonB.push(lineByEnd[v])
              }
            }else{
              //第一个面
              newPolygonA.push(endCut.point)
              for(var n=endCut.poligonIndex+1;n<=startCut.poligonIndex;n++){
                newPolygonA.push(polygonForLineList[n])
              }
              for(var m=0;m<lineByEnd.length;m++){
                newPolygonA.push(lineByEnd[m])
              }
              //第二个面
              newPolygonB.push(startCut.point)
              for(var s=startCut.poligonIndex+1;s<polygonForLineList.length;s++){
                newPolygonB.push(polygonForLineList[s])
              }
              if(endCut.poligonIndex>0){
                for(var g=1;g<=endCut.poligonIndex;g++){
                  newPolygonB.push(polygonForLineList[g])
                }
              }
              for(var u=lineByEnd.length-1;u>=0;u--){
                newPolygonB.push(lineByEnd[u])
              }


            }


            // //截第一个面
            // var newPolygonA=[]
            // newPolygonA.push(startCut.point)
           

            // console.log("第一个面")
            // console.log(newPolygonA)
            // //截第二个面
            // var newPolygonB=[]
  
            // console.log("第二个面")
            // console.log(newPolygonB)
        }else{
          this.$message.error("无效的线段，请重新绘制！")
        }
    },
    //是否在多边形内
    flagPointInPolygon(polygon,point){
       var pt = turf.point(point);
       var poly = turf.polygon(polygon)
       return turf.booleanPointInPolygon(pt, poly);
    },

    //计算交点个数
    computeLineIntersectCount(lines,line,filter){
      var count=0
      for(var i=0;i<lines.length-1;i++){
          var dlp=[lines[i],lines[i+1]]
          var dl= turf.lineString(dlp);
          for(var j=0;j<line.length-1;j++){
            var llp=[line[j],line[j+1]]
            var ll= turf.lineString(llp)
            let intersects =turf.lineIntersect(ll, dl).features
            //有交点
            if(intersects.length==1){
              count++
            }
          }
      }
      return count
    },
    //计算线段与多边形每个线段相交的点   filter排除的线段 返回 相交的点和 所在的线段
    computeLineIntersect(lines,line,filter){
      var result={point:[],lineIndex:0,poligonIndex:0}
      for(var i=0;i<lines.length-1;i++){
          var dlp=[lines[i],lines[i+1]]
          // console.log("-----------")
          // console.log("第"+i+"个多边形线段")
          // console.log(dlp)
          var dl= turf.lineString(dlp);
          let hasCut=false
          for(var j=0;j<line.length-1;j++){
            var llp=[line[j],line[j+1]]
            // console.log("第"+j+"个切线线段")
            // console.log(llp)
            var ll= turf.lineString(llp)
            let intersects =turf.lineIntersect(ll, dl).features
            // console.log("结果")
            // console.log(intersects)
            //有交点
            if(intersects.length==1){
              var ips=intersects[0].geometry.coordinates
              if(!filter){
                  result.point=ips
                  result.lineIndex=j
                  result.poligonIndex=i
                  hasCut=true
                  break;
              }else{
                 if(filter[0] != ips[0] || filter[1] != ips[1]) {
                    result.point=ips
                    result.lineIndex=j
                    result.poligonIndex=i
                    hasCut=true
                    break;
                 }
              }
            }
          }
          if(hasCut){
            break;
          }
      }
      return result
    },
    //判断正向还是反向
    flagDirection(start,q,z){
      var options = {units: 'miles'};
      var direction=null
      var from=turf.point(start);
      var toq=turf.point(q);
      var d1=turf.distance(from, toq, options);
      var toz=turf.point(z);
      var d2=turf.distance(from, toz, options);
      if(d1<d2){
        direction=true
      }else{
        direction=false
      }
      return direction
    },

   





    //注册proj4
    registerProj4(k,v){
      proj4.defs(
        k, v
      );
      register(proj4);
    },
    //重新设置view
    setNewView(epsgKey,epsgValue,epsgExtent){
      this.$store.commit("setEpsg",epsgKey)
      this.$store.commit("setExtent",epsgExtent)
      proj4.defs(
        epsgKey,
        epsgValue
      )
      register( proj4);
      
      var p=new Projection({
        code:epsgKey,
        extent:epsgExtent
      })
       var view=new View({
          projection: p,
          maxZoom:15,
          minZoom:1,
          zoom: 2,
      })
      this.view=view
      this.map.setView(view)

    },

    //添加一个要素
    addFeatureFromTask(){
      var coor1=  [[
          [128.76583077012347,44.031757723020874],
          [129.0164070453824,44.01527244175384],
          [129.02080312038694,43.83283532906531],
          [128.74275137634962,43.8295382728119],
          [128.76583077012347,44.031757723020874],
      ]]
      var coor2=  [[
        [128.76583077012347,44.031757723020874],
        [129.0164070453824,44.01527244175384],
        [129.06476387043236, 44.22738306072302],
        [128.80099937015981, 44.22408600446962],
        [128.76583077012347,44.031757723020874],
      ]]
      var coor3=  [[
        [128.6471367450008,44.000985197989074],
        [128.83287091394274,44.000985197989074],
        [128.8196826889291,43.82843925406077],
        [128.69879062630417,43.81525102904714],
        [128.6471367450008,44.000985197989074],
      ]]
      var coor4=  [[
        [128.32622326966919,44.16583801065943],
        [128.43392710728048,44.16473899190829],
        [128.43282808852933,44.06692632305722],
        [128.2987478008908,44.02626262926519],
        [128.32622326966919,44.16583801065943],
      ]]
      
      this.appendFeature("Polygon","editczfw1",coor1,false,this.afterAppend)
      // this.appendFeature("Polygon","editczfw2",coor2,false,this.afterAppend)
      // this.appendFeature("Polygon","editczfw3",coor3,false,this.afterAppend)
      // this.appendFeature("Polygon","editczfw4",coor4,false,this.afterAppend)

      // var  p1=turf.polygon(coor1)
      // var  p2=turf.polygon(coor2)
      // var  p3=turf.polygon(coor3)
      // var  p4=turf.polygon(coor4)
      // var u1 = turf.union(p1, p2);
      // console.log("共边")
      // console.log(u1)
      // var u2 = turf.union(p1, p3);
      // console.log("相交")
      // console.log(u2)
      // var u3 = turf.union(p2, p4);
      // console.log("不相交")
      // console.log(u3)

      // var u1c=u1.geometry.coordinates
      // var u2c=u2.geometry.coordinates
     // this.appendFeature("Polygon","editczfw1",u1c,false,this.afterAppend)
     // this.appendFeature("Polygon","editczfw2",u2c,false,this.afterAppend)
   
      this.moveTo([129.02080312038694,43.83283532906531])
    },
    mapTypeChange(e) {
      if (e == "矢量") {
        this.mapLayerA.setVisible(true);
        this.mapLayerB.setVisible(true);
        this.mapLayerC.setVisible(false);
        this.mapLayerD.setVisible(false);
      } else if (e == "影像") {
        this.mapLayerA.setVisible(false);
        this.mapLayerB.setVisible(false);
        this.mapLayerC.setVisible(true);
        this.mapLayerD.setVisible(true);
      }
    },
    getMapTarget() {
      var o = {
        map: this.map,
        view: this.view,
        source: this.mapSource,
        layer: this.mapLayer,
      };
      return o;
    },
    //添加图形
    appendPolygon() {},

    afterAppend(fea) {
      console.log("添加完成")
      console.log(fea)
      var type=fea.getGeometry().getType()
      var fid=fea.getId()
      fea.set("tbtype","task")
      //移除绘制时创建的删除按钮
      var  overlays=this.map.getOverlays().array_
      for(var i=0;i<overlays.length;i++){
          if(overlays[i].get("fid")== fid){ 
            this.map.removeOverlay(overlays[i])
          }
      }

    },

    //通过ID获取要素
    getFeaById(id){
      return this.$refs.oldraw.getFeaById(id)
    },
    //坐标转换
    coorTransform(coordinates,from,to){
      return  this.$refs.oldraw.coorTransform(coordinates, from ,to)
    },
    //字符串解析，附坐标转换
    getCoordinateByStr(str,from,to){
      return this.$refs.oldraw.getCoordinateByStr(str,from,to)
    },

    //绘制多边形 drawDatas=绘制时要素的自定义参数   callback 绘制完成回调 （返回要素对象）
    drawPolygon(drawDatas, callback) {
      this.$refs.oldraw.beginDraw("Polygon", false, drawDatas, callback);
    },
    //删除绘制  fid=要素ID  type=要素geometry 类型
    delDrawFeature(fid, type) {
      this.$refs.oldraw.doRrmoveDraw(fid, type);
    },
    //结束绘制
    endDraw() {
      this.$refs.oldraw.flagNowDraw();
    },
    //获取所有要素
    getAllDrawFeatures() {
      return this.$refs.oldraw.getAllDrawFeatures();
    },
    //手动添加要素  type=geometry 类型  id=要素ID  coors=坐标  showLabel=是否显示标注  callback=添加完成回调（返回要素对象）
    appendFeature(type, id, coors, showLabel, callback) {
      this.$refs.oldraw.appendFeature(type, id, coors, showLabel, callback);
    },
    //移动到某个位置 center=坐标
    moveTo(center,zoom) {
      //console.log("移动到："+center)
      this.view.setCenter(center)
      if(zoom && zoom!=undefined){
        this.view.setZoom(zoom)
      }
    },
    //修改要素样式 feature=要素  fill=多边形填充色 stroke=边线颜色  circle=圆填充色
    setFeaStyle(feature, fill, stroke, circle) {
      this.$refs.oldraw.setFeaStyle(feature, fill, stroke, circle);
    },
    //openlayer多边形转 turf 多边形（坐标系转换）
    getTurfPolygon(coordinates, epsg) {
      return this.$refs.oldraw.getTurfPolygon(coordinates, epsg);
    },
    //判断是否包含
    isInclude(f1, f2) {
      return this.$refs.oldraw.isInclude(f1, f2);
    },
    //判断是否相交 （在内部和外部 false，有交集true)
    isOverlay(f1, f2) {
      return this.$refs.oldraw.isOverlay(f1, f2);
    },
  },
  computed:{
    infoBH(){
      var bh="未选择图斑"
      if(this.checkTask){
        if(this.taskItemInfo.BH && this.taskItemInfo.BH!=""){
          bh=this.taskItemInfo.BH
        }else{
          bh="无数据"
        }
        
      }
      return bh
    },
    infoTYPE(){
      var type="未选择图斑"
      if(this.checkTask){
        if(this.taskItemInfo.TYPE && this.taskItemInfo.TYPE!=""){
          if(this.taskItemInfo.TYPE=="1"){
           type="城镇房屋"
          }else if(this.taskItemInfo.TYPE=="2"){
            type="农村住宅"
          }else if(this.taskItemInfo.TYPE=="3"){
            type="农村非住宅"
          }
        }else{
          type="无数据"
        }
      }
      return type
    },
    infoSTATUS(){
      var status="未选择图斑"
      if(this.checkTask){
         if(this.taskItemInfo.STATUS && this.taskItemInfo.STATUS!=""){
            if(this.taskItemInfo.STATUS=="0"){
              status="未下发"
            }else if(this.taskItemInfo.STATUS=="1"){
              status="已下发"
            }
         }else{
            status="无数据"
         }
      }
      return status
    },
    infoADDRESS(){
      var address="未选择图斑"
      if(this.checkTask){
        if(this.taskItemInfo.DISTRICT !="" && this.taskItemInfo.VILLAGE !=""){
          address=this.taskItemInfo.DISTRICT+"-"+this.taskItemInfo.VILLAGE
        }else{
          address ="无数据"
        }
      }
      return address
    }
  }
};
</script>

