import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import TaskIssued from '../views/TaskIssued.vue'
import JobAudit from '../views/JobAudit.vue'
import CaseAudit from '../views/CaseAudit.vue'
import DeviceManage from '../views/DeviceManage.vue'
import UserManage from '../views/UserManage.vue'
import EditTask from '../views/EditTask.vue'
import MobileLogin from '../views/MobileLogin.vue'
import MobileTaskList from '../views/MobileTaskList.vue'
import BoroughsList from '../views/BoroughsList.vue'
import VillageTaskCount from '../views/VillageTaskCount.vue'
import VillageTaskIssued from '../views/VillageTaskIssued.vue'
import TaskCount from '../views/TaskCount.vue'

import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/TaskIssued',
        name: 'TaskIssued',
        component: TaskIssued
      },
      {
        path: '/JobAudit',
        name: 'JobAudit',
        component: JobAudit
      },
      {
        path: '/CaseAudit',
        name: 'CaseAudit',
        component: CaseAudit
      },
      {
        path: '/DeviceManage',
        name: 'DeviceManage',
        component: DeviceManage
      },
      {
        path: '/UserManage',
        name: 'UserManage',
        component: UserManage
      },
      {
        path: '/BoroughsList',
        name: 'BoroughsList',
        component: BoroughsList
      },
      {
        path: '/VillageTaskCount',
        name: 'VillageTaskCount',
        component: VillageTaskCount
      },
      {
        path: '/VillageTaskIssued',
        name: 'VillageTaskIssued',
        component: VillageTaskIssued
      },{
        path: '/TaskCount',
        name: 'TaskCount',
        component: TaskCount
      },
    ]
  },
  {
    path: '/MobileLogin',
    name: 'MobileLogin',
    component: MobileLogin
  },
  {
    path: '/MobileTaskList',
    name: 'MobileTaskList',
    component: MobileTaskList
  },
  {
    path: '/EditTask',
    name: 'EditTask',
    component: EditTask
  },

  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  var hasInfo=false
  if(cookies.isKey("setToken") && cookies.isKey("setEndTime")){
    var be=new Date().getTime()
    var ee=new Date(cookies.get("setEndTime")).getTime()
    if(be<ee){
      hasInfo=true
      store.commit('setToken', cookies.get("setToken"));  
      store.commit('setEndTime', cookies.get("setEndTime"));
      store.commit('setCode', cookies.get("setCode"));  
      store.commit('setGuid', cookies.get("setGuid"));
      store.commit('setName', cookies.get("setName"));
      store.commit('setType', cookies.get("setType"));
      store.commit('setXzqCode', cookies.get("setXzqCode"));
      store.commit('setXzqGuid', cookies.get("setXzqGuid"));
    }
  }
  if(to.name!="Login"){
    //console.log("前往的不是登录页")
      if(hasInfo){
        if(to.name == "TaskCount"){
          next(true);
        }else{
          if(cookies.get("setType")=="1" && to.name!="UserManage" && to.name!="DeviceManage" && to.name!="BoroughsList" && to.name!="VillageTaskCount"){
            router.replace("/UserManage") 
          }else if(cookies.get("setType")=="2" && to.name!="TaskIssued" && to.name!="JobAudit" && to.name!="CaseAudit" && to.name!="VillageTaskIssued"){
            router.replace("/TaskIssued")
          }else if(cookies.get("setType")=="3"){
            router.replace("/")
          }else{
            next(true);
          }
        }
      }else{
        router.replace("/")
      }
  }else{
    if(hasInfo){
      if(cookies.get("setType") =="1"){
        router.replace("/UserManage")
      }else if(cookies.get("setType") =="2"){
        router.replace("/TaskIssued")
      }else{
        next(true);
      }
    }else{
      next(true);
    }
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
