<template>
  <div class="content">
        <div class="toolBox">
          <el-tooltip class="toolItemOut" effect="dark" content="坐标拾取" placement="left">
            <div class="toolItem" @click="getMapCenter">
               <img src="statics/img/map/point.png" alt="">
            </div>
          </el-tooltip>
          <el-tooltip class="toolItemOut" effect="dark" content="测距离" placement="left">
            <div class="toolItem" @click="drawLine">
                <img src="statics/img/map/long.png" alt="">
            </div>
          </el-tooltip>
          <el-tooltip class="toolItemOut" effect="dark" content="测面积" placement="left">
            <div class="toolItem" @click="drawPolygon">
                <img src="statics/img/map/area.png" alt="">
            </div>
          </el-tooltip>
          <el-tooltip class="toolItemOut" effect="dark" content="清空绘制" placement="left">
            <div class="toolItem" @click="clearDraw">
                <img src="statics/img/map/clear.png" alt="">
            </div>
          </el-tooltip>
        </div>
  </div>
</template>
<style scoped>
  .toolBox{width: 34px;border: 1px solid #ADADAD;border-radius: 3px;background-color: #FFFFFF;}
  .toolBox .toolItem{width: 34px;height: 34px;border-bottom: 1px solid #ADADAD;overflow: hidden;}
  .toolBox .toolItem:last-of-type{border-bottom: 0;}
  .toolBox .toolItem img{display: block;width: 20px;margin: 7px auto;}
</style>
<script>
import {GeoJSON} from 'ol/format';
import 'ol/ol.css';
import Draw from 'ol/interaction/Draw';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Fill, Stroke, Circle, Style} from 'ol/style';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import Projection from 'ol/proj/Projection';
import LineString from 'ol/geom/LineString';
import {boundingExtent,getCenter} from 'ol/extent';
import {transform} from 'ol/proj';
import {getArea,getLength} from 'ol/sphere';
import * as turf from '@turf/turf'
var drawLine = false
var prevCoord = null
var drawFid = ""
export default {
  name: 'OLDRAW',
  props: {
    map: {
      type:Object,
      default:null
    },
  },
  watch:{
    map(newVal,oldVal){
      this.initDraw()
    }
  },
  data () {
    let _this=this
    //删除绘制的图形
    let removeDrawFeature=function(e){
      var fid=e.target.getAttribute("fid")
      var type= e.target.getAttribute("type")
      _this.doRrmoveDraw(fid,type)
    }
    return {
      drawing:false,
      drawFormat : null,
      drawIndex:0,  //绘制索引
      drawStep:1,   //绘制步（线段）
      drawSource:null, //绘制源
      drawVector:null, //绘制层
      drawType:"",     //绘制类型
      nowDraw:null,    //当前绘制对象
      drawStyle:null,  //绘制样式
      showLabel:true, //显示标注（标点、距离、面积）
      drawDatas:null, //绘制图形的额外参数
      drawCallBack:null,
      // drawChange:{handleEvent: function(e) {
      //     console.log("绘制图形改变")
      //     var geom=e.target
      //       console.log(geom)
      //   }
      // },
      //绘制开始事件
      drawStart:{handleEvent: function(e) {
          _this.drawIndex ++
          drawFid ="fid_"+_this.drawIndex
          _this.drawing=true
          if(_this.drawType =="LineString"){
            _this.drawStep=1
            drawLine=true
            prevCoord=null
          }
        }
      },
      //绘制结束事件
      drawEnd:{handleEvent: function(e) {
        //console.log("结束")

        let ft=e.feature
        if(ft){
          ft.setId(drawFid)
          ft.set("type","draw")
          if(_this.drawDatas){
             for(var item in _this.drawDatas){
                ft.set(item,_this.drawDatas[item])
             }
          }
          let cd =ft.getGeometry().getCoordinates()
          //console.log(cd)
          if(_this.drawType =="Point"){
              let str=cd[0].toFixed(10)+","+cd[1].toFixed(10)
              let strw=24
              let div = document.createElement("div"); 
              div.className="pointOverlay"
              if(_this.showLabel){
                strw=((str.length *7.84)+58)/2
                div.innerHTML=str+"　"
              }
              let btn= document.createElement("span"); 
              btn.className="btn"
              btn.innerHTML = "删除";
              btn.setAttribute("fid",drawFid)
              btn.setAttribute("type","Point")
              btn.onclick = removeDrawFeature;
              div.appendChild(btn);
              let oy = new Overlay({
                element: div,
                offset: [-strw, -26],
                position:cd,
                positioning: "center-left"
              });
              oy.set("fid",drawFid)
              _this.map.addOverlay(oy);
          }else if(_this.drawType =="LineString"){
            drawLine=false
            prevCoord=null
            let str=_this.formatLength(ft.getGeometry())
            let strw=24
            let div = document.createElement("div"); 
            div.className="lineEnd"
            if(_this.showLabel){
                div.innerHTML="总长度："+str+"　"
                strw=(str.length *7.84)+126
            }
           
            let btn= document.createElement("span"); 
            btn.className="btn"
            btn.innerHTML = "删除";
            btn.setAttribute("fid",drawFid)
            btn.setAttribute("type","LineString")
            btn.onclick = removeDrawFeature;
            div.appendChild(btn);
            let oy = new Overlay({
              element: div,
              offset: [-strw, 30],
              position:cd[cd.length-1],
              positioning: "center-left"
            });
            oy.set("fid",drawFid)
            _this.map.addOverlay(oy);

          }else if(_this.drawType =="Polygon"){
              //console.log("多边形")
              //let pc=ft.getGeometry().getExtent().getCenter()
              let et=ft.getGeometry().getExtent()
              let coordinates=[]
              let minxy=[et[0],et[1]];
              let maxxy=[et[2],et[3]];
              coordinates.push(minxy);
              coordinates.push(maxxy)

              let Extent=new boundingExtent(coordinates)
              let center=getCenter(Extent)
              let str=_this.formatArea(ft.getGeometry())
              let strw=24
              let div = document.createElement("div"); 
              div.className="polygonEnd"
              if(_this.showLabel){
                div.innerHTML="面积："+str+"　"
                strw=(((str.length -11)*7.53)+100)/2
              }
              let btn= document.createElement("span"); 
              btn.className="btn"
              btn.innerHTML = "删除";
              btn.setAttribute("fid",drawFid)
              btn.setAttribute("type","Polygon")
              btn.onclick = removeDrawFeature;
              div.appendChild(btn);
              let oy = new Overlay({
                element: div,
                offset: [-strw, -15],
                position:center,
                positioning: "center-left"
              });
              oy.set("fid",drawFid)
              _this.map.addOverlay(oy);
          }
          if(_this.drawCallBack){
            _this.drawCallBack(ft)
          }
          
        }
      }},
    }
  },
  mounted() {
    this.loadItem=null
    this.drawFormat=new GeoJSON();
    var _this=this
    this.$nextTick(function () {
      document.addEventListener('keyup', function (e) {
        if (e.keyCode == 27) {
          if(_this.drawing){
            _this.doRrmoveDraw(drawFid,_this.drawType)
          }
          _this.flagNowDraw()
        }
      })
    })
    var cs1=[
        [12359181.590922818, 13416647.923091818],
        [12738309.251217293, 13470459.591004582],
        [12757877.130458297, 13128021.704286993],
        [12481480.8361791, 12966586.700548701],
        [12283356.058863923, 13132913.674097244],
        [12359181.590922818, 13416647.923091818],
    ]
    var cs2= [
        [12459466.97203297, 13353052.31555855],
        [12664929.704063524, 13365282.240084179],
        [12684497.583304528, 13181833.372199757],
        [12454575.002222719, 13093777.915615233],
        [12368965.53054332, 13198955.266535636],
        [12459466.97203297, 13353052.31555855],
    ]
    var cs3=[
      [12359181.590922818, 13778653.689050412],
      [12601334.096530257, 13768869.74942991],
      [12554860.383332869, 13617218.68531212],
      [12288248.028674174, 13597650.806071116],
      [12254004.240002416, 13690598.23246589],
      [12359181.590922818, 13778653.689050412],
    ]

    var p1=this.getTurfPolygon(cs1,"EPSG:3857")
    var p2=this.getTurfPolygon(cs2,"EPSG:3857")
    var p3=this.getTurfPolygon(cs3,"EPSG:3857")
    
  },
  methods:{
    initDraw(){
      //绘制层
      this.drawStyle=new Style({
          fill: new Fill({
              color: "rgba(237,63,20,0.2)"
          }),
          stroke: new Stroke({
              color: "#ed3f14",
              width: 2
          }),
          image: new Circle({
              radius: 5,
              fill: new Fill({
                  color: "rgba(237,63,20,1)"
              })
          })
      })
      this.drawSource = new VectorSource({wrapX: false});
      this.drawVector = new VectorLayer({
        source: this.drawSource,
        zIndex:100,
        style: this.drawStyle,
      });
      //地图实例
      this.map.addLayer(this.drawVector)
      
    
      //地图点击事件
      this.map.addEventListener('click', e=>{
        if(this.drawType =="LineString" && drawLine && this.showLabel){
            var c=e.coordinate
            var ft = new Feature({
              geometry: new Point(c),
            });
            ft.set("fid",drawFid)
            this.drawSource.addFeature(ft)
            if(this.drawStep==1){
              var sdiv = document.createElement("div"); 
              sdiv.className="lineStart"
              sdiv.innerHTML="起点"
              var soy = new Overlay({
                element: sdiv,
                offset: [-30, -28],
                position:c,
                positioning: "center-left"
              });
              soy.set("fid",drawFid)
              this.map.addOverlay(soy);
            }else{
                var geom=new LineString([prevCoord,c])
                var str =this.formatLength(geom)
                var div = document.createElement("div"); 
                div.className="lineLabel"
                div.innerHTML=str
                var oy = new Overlay({
                  element: div,
                  offset: [10, 0],
                  position:c,
                  positioning: "center-left"
                });
                oy.set("fid",drawFid)
                this.map.addOverlay(oy);
            }
            prevCoord=c
            this.drawStep++
         }
      });
    },

    //手动添加要素
    appendFeature(type,id,coors,showLabel,callback){
        var geom=null
        if(type=="Polygon"){
          geom= new Polygon(coors)
        }else if(type=="LineString"){
          geom= new LineString(coors)
        }else if(type=="Point"){
          geom= new Point(coors)
        }
        var ft = new Feature({
          geometry: geom,
        });
        ft.set("type","draw")
        ft.setId(id)
        this.drawSource.addFeature(ft) 
        var _this=this
        let removeDrawFeature=function(e){
          var fid=e.target.getAttribute("fid")
          var type= e.target.getAttribute("type")
          _this.doRrmoveDraw(fid,type)
        }
        if(type=="Polygon"){
            let et=ft.getGeometry().getExtent()
            let coordinates=[]
            let minxy=[et[0],et[1]];
            let maxxy=[et[2],et[3]];
            coordinates.push(minxy);
            coordinates.push(maxxy)

            let Extent=new boundingExtent(coordinates)
            let center=getCenter(Extent)
            let str=this.formatArea(ft.getGeometry())
            let strw=24
            let div = document.createElement("div"); 
            div.className="polygonEnd"
            if(showLabel){
              strw=(((str.length -11)*7.53)+100)/2
              div.innerHTML="面积："+str+"　"
            }
            let btn= document.createElement("span"); 
            btn.className="btn"
            btn.innerHTML = "删除";
            btn.setAttribute("fid",id)
            btn.setAttribute("type","Polygon")
            btn.onclick = removeDrawFeature;
            div.appendChild(btn);
            let oy = new Overlay({
              element: div,
              offset: [-strw, -15],
              position:center,
              positioning: "center-left"
            });
            oy.set("fid",id)
            this.map.addOverlay(oy);
        }else if(type=="LineString"){
          let str=this.formatLength(ft.getGeometry())
          let strw=24
          let div = document.createElement("div"); 
          div.className="lineEnd"
          if(showLabel){
            strw=(str.length *7.84)+126
            div.innerHTML="总长度："+str+"　"
          }
          let btn= document.createElement("span"); 
          btn.className="btn"
          btn.innerHTML = "删除";
          btn.setAttribute("fid",id)
          btn.setAttribute("type","LineString")
          btn.onclick = removeDrawFeature;
          div.appendChild(btn);
          let oy = new Overlay({
            element: div,
            offset: [-strw, 30],
            position:coors[coors.length-1],
            positioning: "center-left"
          });
          oy.set("fid",id)
          this.map.addOverlay(oy);
        }else if(type=='Point'){
            let str=coors[0].toFixed(10)+","+coors[1].toFixed(10)
            let  strw=24
            let div = document.createElement("div"); 
            div.className="pointOverlay"
            if(showLabel){
                strw=((str.length *7.84)+58)/2
                div.innerHTML=str+"　"
            }
            
            let btn= document.createElement("span"); 
            btn.className="btn"
            btn.innerHTML = "删除";
            btn.setAttribute("fid",id)
            btn.setAttribute("type","Point")
            btn.onclick = removeDrawFeature;
            div.appendChild(btn);
            let oy = new Overlay({
              element: div,
              offset: [-strw, -26],
              position:coors,
              positioning: "center-left"
            });
            oy.set("fid",id)
            this.map.addOverlay(oy);
        }  
        if(callback){
            callback(ft)
        }  

    },
    //修改要素样式
    setFeaStyle(feature,fill,stroke,circle){
      var style=new Style({
          fill: new Fill({
              color: fill
          }),
          stroke: new Stroke({
              color: stroke,
              width: 2
          }),
          image: new Circle({
              radius: 5,
              fill: new Fill({
                  color: circle
              })
          })
      })
      feature.setStyle(style)
    },
    //坐标拾取  
    getMapCenter(){
      this.beginDraw("Point",true,null,null)
    },
    //画线段
    drawLine(){
      this.beginDraw("LineString",true,null,null)
    },
    //画多边形
    drawPolygon(){
      this.beginDraw("Polygon",true,null,null)
    },
    //删除当前绘制实例
    flagNowDraw(){
      this.drawType=""
      this.drawStep=1
      if(this.nowDraw){
        this.map.removeInteraction(this.nowDraw);
        this.drawRemoveListener()
        this.nowDraw=null
        this.drawing=false
      }
    },
    //开始绘制
    beginDraw(type,label,datas,callback){
      this.showLabel=label
      this.drawDatas=datas
      this.drawCallBack=callback
      if(this.drawing){
          this.doRrmoveDraw(drawFid,this.drawType)
        }
      this.flagNowDraw()
      this.drawType=type
      this.nowDraw= new Draw({
        source: this.drawSource,
        type: type,
        style:this.drawStyle,
      });
      this.drawAddListener()
      this.map.addInteraction(this.nowDraw)
    },
    //添加监听
    drawAddListener(){
      this.nowDraw.on("drawstart",this.drawStart)
      this.nowDraw.on("drawend",this.drawEnd)
    },
    //移除监听
    drawRemoveListener(){
      this.nowDraw.un("drawstart",this.drawStart)
      this.nowDraw.un("drawend",this.drawEnd)
    },
    //清除绘制
    clearDraw(){
        this.drawSource.clear()
        var oys=this.map.getOverlays().array_
        var _this=this
        for(var i=0;i<oys.length;i++){
          let d=oys[i]
          setTimeout(function(){
            _this.map.removeOverlay(d)
          },50)
        }
        this.flagNowDraw()
    },

    //获取长度
    formatLength (line) {
       var p=new Projection({
          code:this.$store.state.epsg,
          extent:this.$store.state.extent,
        })
        var length =getLength(line,{
          projection:p,
          radius:6378137
        })
        // console.log(length)
        //length = Math.round(length * 100) / 100;
        var output;
        if (length > 1000) {
            output = Math.round((length / 1000) * 100) / 100  + "km"; //换算成KM单位
        } else {
            output = Math.round(length * 100) / 100 + "m"; //m为单位
        }
        return output;
    },
    formatArea(polygon) {
        var p=new Projection({
          code:this.$store.state.epsg,
          extent:this.$store.state.extent,
        })
        var area = getArea(polygon,{
          projection:p,
          radius:6378137
        })
        //console.log(area)
        var output;
        if (area > 10000) {
            output =
                Math.round((area / 1000000) * 100) / 100 + "km<sup>2</sup>";
        } else {
            output = Math.round(area * 100) / 100  + "m<sup>2</sup>";
        }
        return output;
    },
    //删除绘制
    doRrmoveDraw(fid,type){
      //移除绘制时的标注
      var _this=this
      var oys=this.map.getOverlays().array_
      for(var i=0;i<oys.length;i++){
        let d=oys[i]
        if(d.get("fid")==fid){
          setTimeout(function(){
              _this.map.removeOverlay(d)
          },50)
        }
      }
      //移除绘制的要素
      var ft=this.drawSource.getFeatureById(fid)
      if(ft){
        this.drawSource.removeFeature(ft)
      }
      
      //移除绘制线段增加的点
      if(type=="LineString"){
        var fts=this.drawSource.getFeatures()
        for(var j=0;j<fts.length;j++){
          if(fts[j].get("fid")==fid){
            this.drawSource.removeFeature(fts[j])
          }
        }
      }
    },
    //获取所有的绘制要素
    getAllDrawFeatures(){
        var  feas=this.drawSource.getFeatures()
        var drawFeas=[]
        for(var i=0;i<feas.length;i++){
           if(feas[i].get("type")=="draw"){
             drawFeas.push(feas[i])
           }
        }
        return drawFeas
    },
    getFeaById(id){
      var ft=this.drawSource.getFeatureById(id)
      return ft
    },

    //坐标转换
    coorTransform(coordinates,from,to){
      return transform(coordinates, from ,to)
    },
    getCoordinateByStr(str,from,to){
      var arr=[]
      if(str.indexOf("MULTIPOLYGON")!= -1){
        str=str.replace("MULTIPOLYGON(","")
        str= str.substring(0,str.length-1)
        while(str.indexOf("((")!= -1){
          str=str.replace("((","")
        }
        while(str.indexOf(")),")!= -1){
          str=str.replace(")),","|")
        }
        str= str.substring(0,str.length-2)
        var b=str.split("|")
        if(b.length>0){
          for(var j=0;j<b.length;j++){
            var p2=b[j].split(",")
            var dian=[]
            for(var k=0;k<p2.length;k++){
              var  o2=p2[k].split(" ")
              var zb1=[parseFloat(o2[0]),parseFloat(o2[1])]
              var nzb1=this.coorTransform(zb1,from,to)
              dian.push(nzb1)
            }
            arr.push(dian)
          }
        }
      }else if(str.indexOf("POLYGON")!= -1){
        str=str.replace("POLYGON((","")
        str= str.substring(0,str.length-2)
        var a=str.split(",")
        if(a.length>0){
          for(var i=0;i<a.length;i++){
            var p1=a[i].split(" ")
            var zb2=[parseFloat(p1[0]),parseFloat(p1[1])]
            var nzb2=this.coorTransform(zb2,from,to)
            arr.push(nzb2)
          }
        }
        arr=[arr]
      } 
      return arr
  },

    //openlayer线段 turf 线段（坐标系转换）
    getTurfLineString(coordinates,epsg){
        var cs = []
      for(var i=0;i<coordinates.length;i++){
        var nc= transform(coordinates[i], epsg ,'EPSG:4326');
        cs.push([nc[0], nc[1]])
      }
      return turf.lineString(cs)
    },
    //openlayer多边形转 turf 多边形（坐标系转换）
    getTurfPolygon(coordinates,epsg){
      var cs = []
      for(var i=0;i<coordinates.length;i++){
        var nc= transform(coordinates[i], epsg ,'EPSG:4326');
        cs.push([nc[0], nc[1]])
      }
      return turf.polygon([cs])
    },
    //判断是否包含
    isInclude(f1,f2){
      return turf.booleanContains(f1, f2);
    },
    //判断是否相交 （在内部和外部 false，有交集true)
    isOverlay(f1, f2) {
        return turf.booleanOverlap(f1, f2)
    }
  }
}
</script>

