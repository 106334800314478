<template>
  <div class="views-main views-full">
      <div class="views-main-title">
          <p class="txt">任务审核</p>         
      </div>

      <div class="tableBox">
          <div class="views-main-search">
              <el-select v-model="nowVillageCode" placeholder="切换区域" filterable  class="sel" style="width:150px">
                <el-option v-for="item in villageList" :key="item.VILLAGECODE" :label="item.VILLAGENAME" :value="item.VILLAGECODE" ></el-option>
              </el-select>
              <el-select v-model="lb" placeholder="选择类型" class="ml10"  style="width:150px">
                <el-option v-for="item in lbOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-select v-model="zt" placeholder="选择状态" class="ml10"  style="width:150px">
                <el-option v-for="item in ztOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-input v-model="fwbh" placeholder="房屋编号" style="width:150px;margin-left:10px;"></el-input>
              <el-select v-model="bydcyy" placeholder="选择是否不需调查" class="ml10"  style="width:150px">
                <el-option label="需或不需调查" value=""></el-option>
                <el-option label="需调查" value="1"></el-option>
                <el-option label="不需调查" value="0"></el-option>
              </el-select>
              <el-select v-model="ff" placeholder="选择是否包含辅房" class="ml10" v-if="lb=='RISK_CENSUS_COUNTRY_HOUSE'"  style="width:150px">
                <el-option label="包含或不包含辅房" value=""></el-option>
                <el-option label="包含辅房" value="1"></el-option>
                <el-option label="不包含辅房" value="0"></el-option>
              </el-select>
              <el-select v-model="ss" placeholder="选择是否有损伤照片" class="ml10"  style="width:150px">
                <el-option label="有或没有损伤照片" value=""></el-option>
                <el-option label="有损伤照片" value="1"></el-option>
                <el-option label="没有损伤照片" value="0"></el-option>
              </el-select>
              <el-button type="primary" @click="queryTaskList" class="ml10">查询</el-button>
          </div>
          <div class="views-main-body" v-show="lb=='RISK_CENSUS_CITY_HOUSE'">
            <el-table :data="taskList" stripe style="width: 100%">
                <!-- <el-table-column prop="BH" label="编号"></el-table-column> -->
                <el-table-column prop="MC" label="单位/小区名称"></el-table-column>
                <el-table-column prop="LU_QG" label="建筑地址_路（街、巷）"></el-table-column>
                <el-table-column prop="JZMC" label="建筑名称"></el-table-column>
                <el-table-column prop="FWLB" label="房屋类别" >
                  <template slot-scope="scope">
                    <span v-if="scope.row.FWLB=='0110'">城镇住宅房屋</span>
                    <span v-else-if="scope.row.FWLB=='0120'">城镇非住宅房屋</span>
                  </template>
                </el-table-column>
                <el-table-column prop="CZFWJGLX" label="结构类型" >
                  <template slot-scope="scope" v-if="scope.row.FWLB=='0110'">
                    <span v-if="scope.row.CZFWJGLX=='1'">砌体结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='4'">钢筋混凝土结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='5'" >钢结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='6'">木结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='99999'">{{scope.row.QTJGLX}}</span>
                  </template>
                  <template slot-scope="scope" v-if="scope.row.FWLB=='0120'">
                    <span v-if="scope.row.CZFWJGLX=='1001'">砌体结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='2003'">钢筋混凝土结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='4'" >钢结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='5'">木结构</span>
                    <span v-else-if="scope.row.CZFWJGLX=='99999'">{{scope.row.QTJGLX}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="SUB_JGLX" label="二级结构类型" >
                  <template slot-scope="scope" v-if="scope.row.CZFWJGLX=='1' || scope.row.CZFWJGLX=='1001'">
                    <span v-if="scope.row.SUB_JGLX=='3'">底部框架-抗震墙结构砌体结构</span>
                    <span v-if="scope.row.SUB_JGLX=='99999'">其他</span>
                  </template>
                  <template slot-scope="scope" v-if="scope.row.FWLB=='0110' && scope.row.CZFWJGLX=='4' || scope.row.CZFWJGLX=='2003'">
                    <span v-if="scope.row.SUB_JGLX=='1'">底部框架-抗震墙结构</span>
                    <span v-else-if="scope.row.SUB_JGLX=='2'">内框架结构</span>
                    <span v-if="scope.row.SUB_JGLX=='3'">其他</span>
                  </template>
                  <template slot-scope="scope" v-if="scope.row.FWLB=='0120' && scope.row.CZFWJGLX=='4' || scope.row.CZFWJGLX=='2003'">
                    <span v-if="scope.row.SUB_JGLX=='1'">单跨框架</span>
                    <span v-else-if="scope.row.SUB_JGLX=='2'">非单跨框架</span>
                  </template>
                </el-table-column>
                <el-table-column prop="DSCS" label="地上层数"></el-table-column>
                <el-table-column prop="DXCS" label="地下层数"></el-table-column>
                <el-table-column prop="DCMJ" label="调查面积"></el-table-column>
                <el-table-column prop="GD" label="建筑高度"></el-table-column>
                <el-table-column prop="DCR" label="调查人"></el-table-column>
                <el-table-column prop="DCSJ" label="调查时间"></el-table-column>
                <el-table-column prop="ORG_NAME" label="调查人组织"></el-table-column>

                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click="audit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="zt=='2'">审核</el-button>
                    <el-button @click="detail(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="zt!='2'">查看</el-button>
                    <el-button @click="isuse(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="zt=='4'">重新下发</el-button>
                  </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
          </div>
          <div class="views-main-body" v-show="lb=='RISK_CENSUS_COUNTRY_HOUSE'">
            <el-table :data="taskList" stripe style="width: 100%">
              <!-- <el-table-column prop="BH" label="编号"></el-table-column> -->
              <el-table-column prop="ZU_QG" label="地址_组"></el-table-column>
              <el-table-column prop="CS" label="层数"></el-table-column>
              <el-table-column prop="DCMJ" label="调查面积"></el-table-column>

              <el-table-column prop="FWLX" label="住宅类型">
                <template slot-scope="scope">
                  <span v-if="scope.row.FWLX=='1'">独立住宅</span>
                  <span v-else-if="scope.row.FWLX=='2'">集合住宅</span>
                  <span v-else-if="scope.row.FWLX=='4'">住宅辅助用房</span>
                </template>
              </el-table-column>
              <el-table-column prop="JGLX" label="结构类型">
                <template slot-scope="scope" v-if="scope.row.FWLX=='1'">
                  <span v-if="scope.row.JGLX=='1'">砖石结构</span>
                  <span v-else-if="scope.row.JGLX=='2'">土木结构</span>
                  <span v-else-if="scope.row.JGLX=='3'">混杂结构</span>
                  <span v-else-if="scope.row.JGLX=='4'">窑洞</span>
                  <span v-else-if="scope.row.JGLX=='5'">钢筋混凝土结构</span>
                  <span v-else-if="scope.row.JGLX=='6'">钢结构</span>
                  <span v-else-if="scope.row.JGLX=='99'">{{scope.row.QTJGLX}}</span>
                </template>
                <template slot-scope="scope" v-else-if="scope.row.FWLX=='2'">
                  <span v-if="scope.row.JGLX=='1'">砖石结构</span>
                  <span v-else-if="scope.row.JGLX=='5'">钢筋混凝土结构-抗震墙砌体结构</span>
                  <span v-else-if="scope.row.JGLX=='6'">钢结构</span>
                  <span v-else-if="scope.row.JGLX=='99'">{{scope.row.QTJGLX}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="TMJG_QG" label="土木结构二级类">
                <template slot-scope="scope">
                  <span v-if="scope.row.TMJG_QG=='1'">生木结构</span>
                  <span v-else-if="scope.row.TMJG_QG=='2'">木（竹）结构</span>
                </template>
              </el-table-column>
              <el-table-column prop="DCR" label="调查人"></el-table-column>
              <el-table-column prop="DCSJ" label="调查时间"></el-table-column>
              <el-table-column prop="ORG_NAME" label="调查人组织"></el-table-column>
              <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click="audit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="zt=='2'">审核</el-button>
                    <el-button @click="detail(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="zt!='2'">查看</el-button>
                    <el-button @click="isuse(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="zt=='4'">重新下发</el-button>
                  </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
          </div>
           <div class="views-main-body" v-show="lb=='RISK_CENSUS_COUNTRY_NON_HOUSE'">
              <el-table :data="taskList" stripe style="width: 100%">
              <!-- <el-table-column prop="BH" label="编号"></el-table-column> -->
              <el-table-column prop="ZU_QG" label="地址_组"></el-table-column>
              <el-table-column prop="CS" label="层数"></el-table-column>
              <el-table-column prop="DCMJ" label="调查面积"></el-table-column>

              <el-table-column prop="JGLX" label="结构类型">
                <template slot-scope="scope">
                  <span v-if="scope.row.JGLX=='1'">砖石结构</span>
                  <span v-else-if="scope.row.JGLX=='2'">土木结构</span>
                  <span v-else-if="scope.row.JGLX=='3'">混杂结构</span>
                  <span v-else-if="scope.row.JGLX=='4'">窑洞</span>
                  <span v-else-if="scope.row.JGLX=='5'">钢筋混凝土结构</span>
                  <span v-else-if="scope.row.JGLX=='6'">钢结构</span>
                  <span v-else-if="scope.row.JGLX=='99'">{{scope.row.QTJGLX}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="DCR" label="调查人"></el-table-column>
              <el-table-column prop="DCSJ" label="调查时间"></el-table-column>
              <el-table-column prop="ORG_NAME" label="调查人组织"></el-table-column>
              <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button @click="audit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="zt=='2'">审核</el-button>
                    <el-button @click="detail(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="zt!='2'">查看</el-button>
                    <el-button @click="isuse(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="zt=='4'">重新下发</el-button>
                  </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
          </div>
      </div>
      <div class="editBox" v-if="showEditBox">
        <CZFW v-if="editType=='1'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></CZFW>
        <NCZZ v-if="editType=='2'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></NCZZ>
        <NCFZZ v-if="editType=='3'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></NCFZZ>
      </div>
      <el-dialog title="选择人员" :visible.sync="chooseUserModal" width="400px"  :append-to-body="true">
        <el-form  status-icon  ref="cuForm" label-width="100px" class="demo-cpForm">
          <el-form-item label="姓名">
            <el-select v-model="chooseUserGUID" placeholder="请选择人员" multiple >
                    <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitChooseUserModal()">确定</el-button>
            <el-button @click="closeChooseUserModal()">取消</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>
<script>
import CZFW from "../components/ListForCZFW"
import NCZZ from "../components/ListForNCZZ"
import NCFZZ from "../components/ListForNCFZZ"
export default {
  name: "TaskIssued",
  components:{
    CZFW,
    NCZZ,
    NCFZZ,
  },
  data() {
    return {
      nowVillageCode:"",
      villageItems:{},
      villageList:[],

      lb:"RISK_CENSUS_CITY_HOUSE",
      lbOptions:[
         {
          value:"RISK_CENSUS_CITY_HOUSE",
          label:"城镇房屋",
        },
        {
          value:"RISK_CENSUS_COUNTRY_HOUSE",
          label:"农村住宅",
        },
        {
          value:"RISK_CENSUS_COUNTRY_NON_HOUSE",
          label:"农村非住宅",
        },
      ],
      zt:"2",
      ztOptions:[
        {
          value:"2",
          label:"待审核",
        },
        {
          value:"3",
          label:"已完成",
        },
        {
          value:"4",
          label:"重新调查",
        },
      ],
      fwbh:"",
      bydcyy:"",
      ff:"",  
      ss:"",

      taskList:[],
      PageIndex:1,
      PageSize:10,
      Total:0,

      showEditBox:false,

      editType:"",
      handleType:"1",
      handleBH:"",

      checkList:[],
      chooseUserModal:false,
      chooseUserGUID:[],
      userOptions: [],
    };
  },
  mounted() {
    this.loadItem=null
    this.getVillageList()
  },
  computed: {

  },
  watch: {
  },
  methods: {
    //获取村
    getVillageList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:1,
        PAGESIZE:10000,
        VILLAGECODE:"",
        USERGUID:this.$store.state.guid,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/AuditListByVillage", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.villageList=response.data.DATA
              if(this.villageList.length>0){
                this.villageItems={}
                for(var i=0;i<this.villageList.length;i++){
                  this.villageItems[this.villageList[i].VILLAGECODE]=this.villageList[i]
                }
                this.nowVillageCode=this.villageList[0].VILLAGECODE
                //this.getTaskList()
              } 
          }
        })
        .catch((error) => {
          this.villageList=[]
          this.hideLoading()
      });
    },
    //获取任务
    queryTaskList(){
      this.PageIndex=1
      this.getTaskList()
    },
    getTaskList(){
     this.showLoading("请稍后...")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        STATUS:this.zt,
        TYPE:this.lb,
        VILLAGECODE:this.nowVillageCode,
        FWBH:this.fwbh,
        FUFANG:this.ff,
        SUNSHANG:this.ss,
        BXDCYY:this.bydcyy,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetVillageAuditList", md5_data)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
              this.taskList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
          } else {
              this.taskList=[]
              this.Total=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.taskList=[]
          this.Total=0
          this.hideLoading()
      });
    },
    //切换页码
    PGChange(e){
      this.PageIndex=e
      this.getTaskList()
    },
    //审核
    audit(e){
      this.handleType="2"
      this.handleBH=e.BH
      if(this.lb=="RISK_CENSUS_CITY_HOUSE"){
        this.editType="1"
      }else if(this.lb=="RISK_CENSUS_COUNTRY_HOUSE"){
        this.editType="2"
      }else if(this.lb=="RISK_CENSUS_COUNTRY_NON_HOUSE"){
        this.editType="3"
      }
      this.getCaseInfoByBH(e.BH,e.DCR)
    },
    //查看
    detail(e){
      this.handleType="3"
      this.handleBH=e.BH
      if(this.lb=="RISK_CENSUS_CITY_HOUSE"){
        this.editType="1"
      }else if(this.lb=="RISK_CENSUS_COUNTRY_HOUSE"){
        this.editType="2"
      }else if(this.lb=="RISK_CENSUS_COUNTRY_NON_HOUSE"){
        this.editType="3"
      }
      this.getCaseInfoByBH(e.BH,e.DCR)

    },
    getCaseInfoByBH(bh,dcr){
      this.showLoading("请稍后...")
      var params={BH:bh,DCR:dcr}
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetCaseInfoByBH", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.$store.commit("setTaskStatus",this.zt)
              this.$store.commit("setTaskGuid",response.data.DATA.GUID)
              this.showEditBox=true
          }else{
             this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
           this.hideLoading()
          this.$message.error("请求出错，请稍后再试！")
      });
    },

    //关闭编辑
    closeEditTask(f){
      //console.log(f)
      if(f){
        var nl=[]
        for(var i=0;i<this.taskList.length;i++){
          var c=this.taskList[i]
          if(c.BH!= f.BH){
              nl.push(c)
          }
        }
        this.taskList=nl
      }
      this.showEditBox=false
    },


    isuse(e){
      var params={BH:e.BH,DCR:e.DCR}
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetCaseInfoByBH", md5_data)
        .then((response) => {
          if (response.data.SUCCESS) {
              this.checkList=[response.data.DATA.GUID]
              this.chooseUserGUID=[]
              this.chooseUserModal=true
          }else{
             this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错，请稍后再试！")
      });
        
    },
    submitChooseUserModal(){
      if(this.chooseUserGUID==[]){
        this.$message.error('请至少选择一个人员');
      }else{
        this.showLoading("请稍后...")
        var d={CREATORGUID:this.$store.state.guid,EXECUTORGUID:this.chooseUserGUID,TASKLIST:this.checkList}
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/TaskRelease", md5_data)
          .then((response) => {
            //console.log(response)
           this.hideLoading()
            if (response.data.SUCCESS) {
                this.chooseUserModal=false
                this.$message.success('已下发！');
                this.getTaskList()
            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          }); 
      }
    },
    closeChooseUserModal(){
      this.chooseUserModal=false
    },
  },
};
</script>
<style scoped>
  .switchBox{float:right;margin-top:6px;margin-left: 20px;}
  .editBox{position:absolute;left: 0;right: 0;bottom: 0;top: 0;z-index: +1;overflow: hidden;background-color: #FFFFFF;}
  .mapBox{position: absolute;left: 0;right: 0;top: 42px;bottom:0;z-index: +1;}
  .mapItem{width: 100%;height: 100%;}
  .table-expand{text-align: center;}
  .expandDown{font-size: 14px;margin-right: 20px;color: #48A2FF;}
  .selectV{margin-bottom: 10px;}
  .tableV{overflow: hidden;}
</style>
