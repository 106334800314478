<template>
  <div class="views-main">
     <OL class="map" ref="ol" :move="move" :draw="draw"></OL>
    
     <div class="editModel">

     </div>
  </div>
</template>
<script>
import OL from "../components/OpenLayers"
export default {
  name: "EditTask",
  components:{
    OL,
  },
  data() {
    return {
      move:true,
      draw:true,
    };
  },
  mounted() {
    this.loadItem=null
      //var mapTarget= this.$refs.ol.getMapTarget()
  },
  methods: {},
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
  .views-main{width: 100%;height: 100%;overflow: hidden;}
  .map{width: 100%;height: 100%;overflow: hidden;}
</style>
