import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    endTime:"",
    code:"",
    guid:"",
    name:"",
    type:"",
    loginName:"",
    xzqCode:"",
    xzqGuid:"",
    epsg:"EPSG:4490",
    extent:[80,20,134,50],
    taskStatus:"",
    taskGuid:"",
  },
  getters: {
    getToken: state => state.token,
    getCode: state => state.code,
    getEndTime: state => state.endTime,
    getGuid: state => state.guid,
    getName: state => state.name,
    getType: state => state.type,
    getLoginName: state => state.loginName,
    getXzqCode: state => state.xzqCode,
    getXzqGuid: state => state.xzqGuid,
    getEpsg: state => state.epsg,
    getExtent: state => state.extent,
    getTaskStatus: state => state.taskStatus,
    getTaskGuid: state => state.taskGuid,
  },
  mutations: {
    setToken(state, data) {
        state.token = data
    },
    setCode(state, data) {
      state.code = data
    },
    setEndTime(state, data) {
      state.endTime = data
    },
    setGuid(state, data) {
      state.guid = data
    },
    setName(state, data) {
      state.name = data
    },
    setType(state, data) {
      state.type = data
    },
    setLoginName(state, data) {
      state.loginName = data
    },
    setXzqCode(state, data) {
      state.xzqCode = data
    },
    setXzqGuid(state, data) {
      state.xzqGuid = data
    },
    setEpsg(state, data) {
      state.epsg = data
    },
    setExtent(state, data) {
      state.extent = data
    },
    setTaskStatus(state, data) {
      state.taskStatus = data
    },
    setTaskGuid(state, data) {
      state.taskGuid = data
    },
  },
  actions: {
    setToken({commit, state}, data) {
      commit('setToken', data)
    },
    setCode({commit, state}, data) {
      commit('setCode', data)
    },
    setEndTime({commit, state}, data) {
      commit('setEndTime', data)
    },
    setGuid({commit, state}, data) {
      commit('setGuid', data)
    },
    setName({commit, state}, data) {
      commit('setName', data)
    },
    setType({commit, state}, data) {
      commit('setType', data)
    },
    setLoginName({commit, state}, data) {
      commit('setLoginName', data)
    },
    setXzqCode({commit, state}, data) {
      commit('setXzqCode', data)
    },
    setXzqGuid({commit, state}, data) {
      commit('setXzqGuid', data)
    },
    setEpsg({commit, state}, data) {
      commit('setEpsg', data)
    },
    setExtent({commit, state}, data) {
      commit('setExtent', data)
    },
    setTaskStatus({commit, state}, data) {
      commit('setTaskStatus', data)
    },
    setTaskGuid({commit, state}, data) {
      commit('setTaskGuid', data)
    },
  },
  modules: {
  }
})
