<template>
  <div id="container">
    <div class="partBox">
      <div class="login_box">
        <div class="login_nav">
            <p class="login_title" :class="navType=='1'?'login_navOn':''" @click="changeNavType(1)">用户登陆</p>
            <p class="login_title" :class="navType=='2'?'login_navOn':''" @click="changeNavType(2)">Android应用</p>
        </div>
        <div v-if="navType=='1'" style="overflow:hidden;">
              <div class="login_row">
                <i class="fa fa-user"></i>
                <input
                  type="text"
                  id="LOGINNAME"
                  class="login_input"
                  placeholder="请输入用户名"
                  autocomplete="off"
                  v-model="name"
                />
              </div>
              <div class="login_row">
                <i class="fa fa-lock"></i>
                <input
                  type="password"
                  id="PASSWORD"
                  class="login_input"
                  placeholder="请输入密码"
                  autocomplete="off"
                  v-model="pwd"
                />
              </div>
              <p class="login_error">{{ errorMsg }}</p>
              <button
                id="loginBtn"
                class="login_btn"
                :class="canLogin ? '' : 'loginBtn_doing'"
                v-on:click="doLogin"
                v-loading.fullscreen.lock="fullscreenLoading"
              >
                登　　录
              </button>
              <el-button
                type="text"
                @click="register"
                style="color: #ffffff; float: right"
                >新用户注册</el-button
              >
        </div>
        <div v-else class="codeBox">
            <img src="statics/img/apkCode.png" alt="">
            <p>使用手机浏览器扫描下载安装</p>
        </div>
      </div>
    </div>
    <el-dialog title="新用户注册" :visible.sync="registerModal" width="500px">
      <el-form :model="userInfo" status-icon :rules="userrules"  ref="uForm" label-width="100px" class="demo-uForm">
        <el-form-item label="登录名" prop="LOGINNAME">
          <el-input v-model="userInfo.LOGINNAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="TRUENAME">
          <el-input v-model="userInfo.TRUENAME " autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="行政区" prop="XZQCODE">
             <el-select v-model="userInfo.XZQCODE" placeholder="选择行政区"  style="width:360px">
                <el-option v-for="item in dOptions" :key="item.CODE" :label="item.NAME" :value="item.CODE"></el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="所属单位" prop="DEPARTMENT ">
          <el-input v-model="userInfo.DEPARTMENT " autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="PASSWORD">
          <el-input
            type="password"
            v-model="userInfo.PASSWORD"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="PASSWORD2">
          <el-input
            type="password"
            v-model="userInfo.PASSWORD2"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="MOBILE">
          <el-input type="number" v-model="userInfo.MOBILE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="userInfo.SEX" label="1">男</el-radio>
          <el-radio v-model="userInfo.SEX" label="2">女</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitUserForm()">提交</el-button>
          <el-button @click="closeUserForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    var LOGINNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录名"));
      } else {
        callback();
      }
    };
    var TRUENAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入真实姓名"));
      } else {
        callback();
      }
    };
    var DEPARTMENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入所属单位"));
      } else {
        callback();
      }
    };
    var XZQCODE= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择一个行政区"));
      } else {
        callback();
      }
    };
    var PASSWORD = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.userInfo.PASSWORD2 !== "") {
          this.$refs.uForm.validateField("PASSWORD2");
        }
        callback();
      }
    };
    var PASSWORD2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.userInfo.PASSWORD) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var MOBILE = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    
    return {
      registerModal: false,
      fullscreenLoading: false,
      errorMsg: "",
      canLogin: true,
      name: "",
      pwd: "",
      userInfo: {
        LOGINNAME: "",
        TRUENAME: "",
        PASSWORD: "",
        PASSWORD2: "",
        MOBILE:"",
        SEX:"1",
        DEPARTMENT:"",
        XZQCODE:"",
      },
      userrules: {
        LOGINNAME: [{ validator: LOGINNAME, trigger: "blur" }],
        TRUENAME: [{ validator: TRUENAME, trigger: "blur" }],
        PASSWORD: [{ validator: PASSWORD, trigger: "blur" }],
        PASSWORD2: [{ validator: PASSWORD2, trigger: "blur" }],
        MOBILE: [{ validator: MOBILE, trigger: "blur" }],
        DEPARTMENT:[{ validator: DEPARTMENT, trigger: "blur" }],
        XZQCODE:[{ validator: XZQCODE, trigger: "blur" }],
      },
      dOptions:[],
      navType:1,
    };
  },
  mounted() {
    this.loadItem=null
    this.getDistrictList()
  },
  methods: {
    changeNavType(type){
      this.navType=type
    },
    doLogin() {
      if (this.canLogin) {
        if (this.name == "" || this.pwd == "") {
          this.errorMsg = "用户名或密码不能为空";
        } else {
          this.fullscreenLoading = true;
          this.errorMsg = "";
          this.canLogin = false;
          var params = {
            LOGINNAME: this.name,
            PASSWORD: this.pwd,
          };
          this.$http
            .post("Login/ManagerLogin", params)
            .then((response) => {
              //console.log(response);
              this.fullscreenLoading = false;
              if (response.data.SUCCESS) {
                var d=response.data.DATA
                var rote=d.ROLERESPONSE.GUID
               
                if (rote !== "1" && rote !== "2") {
                    this.$message.error("只有管理员或者内业人员才能登录");
                  } else {
                    var xzqc=""
                    if(d.XZQCODE && d.XZQCODE!=""){
                      xzqc = d.XZQCODE
                    }
                    var xzqg=""
                    if(d.XZQGUID && d.XZQGUID!=""){
                      xzqg = d.XZQGUID
                    }
                    this.$store.commit('setToken', d.TOKEN.TOKEN);  
                    this.$store.commit('setEndTime', d.TOKEN.ENDTIME);
                    this.$store.commit('setGuid', d.GUID);  
                    this.$store.commit('setName', d.TRUENAME);
                    this.$store.commit('setType', rote);  
                    this.$store.commit('setLoginName', d.LOGINNAME);
                    this.$store.commit('setXzqCode', xzqc);
                    this.$store.commit('setXzqGuid', xzqg);

                    this.$cookies.set("setToken",d.TOKEN.TOKEN)
                    this.$cookies.set("setEndTime",d.TOKEN.ENDTIME)
                    this.$cookies.set("setGuid",d.GUID)
                    this.$cookies.set("setName",d.TRUENAME)
                    this.$cookies.set("setType",rote)
                    this.$cookies.set("setLoginName",d.LOGINNAME)
                    this.$cookies.set("setXzqCode",xzqc)
                    this.$cookies.set("setXzqGuid",xzqg)
                    if (rote == "1") {
                      this.$router.replace("/UserManage");
                    } else {
                      this.$router.replace("/TaskIssued");
                    }

                    if(!this.doingDownFileFlag){
                      this.doDownFileFlag()
                    }
                }
              } else {
                this.canLogin = true;
                this.$message.error(response.data.MSG);
              }
            })
            .catch((error) => {
              this.canLogin = true;
              this.fullscreenLoading = false;
              this.$message.error("登录失败，请稍后再试");
            });
        }
      }
    },
    getDistrictList(){
        this.$http.post("Login/GetDistrictList", {})
        .then((response) => {
          //console.log(response);
          if (response.data.SUCCESS) {
             var d=response.data.DATA
             if(d.length>0){
               this.dOptions=d
               this.userInfo.XZQCODE=d[0].CODE
             }
          } 
        })
    },
    register() {
      this.registerModal = true;
    },
    submitUserForm() {
      if (this.canLogin) {
          this.$refs.uForm.validate((valid) => {
          if (valid) {
            this.sendRegister()

          } else {
            return false;
          }
        });
      }

    },
    closeUserForm() {
      this.$message.info("已取消");
      this.registerModal = false;
    },
    sendRegister(){
          this.fullscreenLoading = true;
          this.canLogin = false;
          
          this.$http
            .post("Login/ManageRegister", this.userInfo)
            .then((response) => {
              //console.log(response);
              this.fullscreenLoading = false;
              if (response.data.SUCCESS) {
                  this.registerModal = false;
                  this.canLogin = true;
                  this.$alert("注册成功，请等待管理员审核！", "操作提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                        this.canLogin = true;
                    },
                  });
              } else {
                this.canLogin = true;
                this.$message.error(response.data.MSG);
              }
            })
            .catch((error) => {
              this.canLogin = true;
              this.fullscreenLoading = false;
              this.$message.error("注册失败，请稍后再试");
            });
    }
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
input::-webkit-input-placeholder {
  color: #fff;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  min-height: 600px;
  position: relative;
}
#container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../public/statics/img/ubg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 666;
}
.partBox {
  width: 330px;
  overflow: hidden;
  border: 0;
  margin: 18% auto;
}
.codeBox{
  width: 260px;
  margin: 0px auto;
}
.codeBox img{
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
}
.codeBox p{
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 10px;
  text-align: center;
}
.options-login,
.options-register {
  width: 320px;
  padding: 0 5px;
  font-size: 14px;
  position: absolute;
  display: none;
  z-index: 999;
}
.options-login .register,
.options-login .forget {
  float: right;
  margin-left: 20px;
  cursor: pointer;
  color: #2dd1b1;
}

.options-register .login {
  color: #fff;
  float: right;
  margin-left: 20px;
}
.options-register .login a {
  cursor: pointer;
  color: #2dd1b1;
}

.login_box {
  width: 320px;
  overflow: hidden;
  padding: 0 5px;
}
.login_row {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
}
.login_row i {
  font-size: 16px;
  color: #fff;
  float: left;
  margin-top: 16px;
  margin-left: 16px;
}
.login_row input {
  width: 256px;
  height: 30px;
  line-height: 31px;
  font-size: 15px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
  background-color: transparent;
  border: 0;
  color: #fff;
  outline: none;
}
.active {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.login_row_last {
  margin-bottom: 0 !important;
}
.login_error {
  height: 32px;
  font-size: 14px;
  color: #ff4e44;
  line-height: 35px;
}
.login_btn {
  background-color: rgba(44, 209, 177, 0.8);
  color: #fff;
  font-size: 16px;
  width: 310px;
  height: 46px;
  border-radius: 20px;
  cursor: pointer;
  line-height: 47px;
  border: 0;
}
.login_nav{
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}
.login_navOn{
  color: rgb(44, 209, 177)!important;
  border-bottom:2px solid rgb(44, 209, 177)!important;
  font-weight: bold;
}
.login_title {
  font-size: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 50%;
  float:left;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}
.login_row select {
  width: 320px;
  display: inline-block;
  margin-right: 20px;
  height: 45px;
  border-color: #aaa;
  border-radius: 4px;
  outline: none;
}
.login_doing {
  color: #33cc99 !important;
}
.loginBtn_doing {
  cursor: wait, auto !important;
  background-color: rgba(187, 190, 196, 0.8);
}
</style>
