<template>
  <div class="content">
       <div class="formBox">
          <div class="views-main-title">
            <p class="txt">{{txt}}</p>
          </div>
          <div class="formCon">
              <el-form :model="fromData" status-icon  :rules="fromrules" ref="form" label-width="100px" class="formMain" label-position="top" v-if="isLost=='0'">
                
                <el-form-item label="照片"  class="formItem formItem1">
                    <div class="imgBox"  v-if="photo.length>0">
                        <div class="imgBoxItem" v-for="(item,i) in photo"  v-bind:key="i">
                            <el-image   :src="item.FILE_PATH"  :preview-src-list="photos" class="pp" ></el-image>
                            <i class="el-icon-delete pd" @click="delPhoto(item.ID,'1')" v-if="taskType=='2'"></i>
                            <p class="pt">
                              方位角：
                              <span>{{item.DIRECTION}}({{item.str}})</span>
                            </p>
                            <p class="pt">经度：{{item.LON}}</p>
                            <p class="pt">纬度：{{item.LAT}}</p>
                            <p class="pt">拍摄时间：{{item.PHOTOTIME}}</p>
                            <p class="pt">ID：{{item.ID}}</p>
                        </div>
                    </div>
                    <div v-else>无</div>
                </el-form-item>    
                <el-form-item label="地址_组"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.ZU_QG" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="地址_  路（街、巷）"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.LU_QG" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="地址_号"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.HAO_QG" :readonly="readonly"></el-input>
                </el-form-item>
                
                <el-form-item label="建筑层数"  prop="CS" class="formItem formItem2">
                  <el-input  type="number" autocomplete="off" v-model="fromData.CS" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="建筑面积" prop="DCMJ" class="formItem formItem2">
                  <el-input  type="number"   autocomplete="off" v-model="fromData.DCMJ" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="房屋编号" prop="FWBH" class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.FWBH" :readonly="readonly"></el-input>
                </el-form-item>
                <!--独立住宅-->
                <el-form-item label="结构类型"  class="formItem formItem2" > 
                  <el-select  placeholder="请选择结构类型" v-model="fromData.JGLX_QG" :disabled="readonly">
                    <el-option label="砖石结构" value="1"></el-option>
                    <el-option label="土木结构" value="2"></el-option>
                    <el-option label="混杂结构" value="3"></el-option>
                    <el-option label="窑洞" value="4"></el-option>
                    <el-option label="钢筋混凝土结构" value="5"></el-option>
                    <el-option label="钢结构" value="6"></el-option>
                    <el-option label="其他" value="99"></el-option>
                  </el-select>
                </el-form-item>

                <!--结构类型选择其他时必填-->
                <el-form-item label="其他结构类型"  prop="QTJGLX_QG" class="formItem formItem2" v-if="QTJGLX_QG" >
                  <el-input   autocomplete="off" v-model="fromData.QTJGLX_QG" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="土木结构二级类"  class="formItem formItem2">
                  <el-select  placeholder="请选择土木结构二级类" v-model="fromData.TMJG_QG" :disabled="readonly">
                    <el-option label="生木结构" value="1"></el-option>
                    <el-option label="木（竹）结构" value="2"></el-option>
                  </el-select>
                </el-form-item>

                

                 <el-form-item label="是否采取抗震构造措施"  class="formItem formItem2">
                  <el-select   placeholder="请选择是否采取抗震构造措施" v-model="fromData.SFKZ_QG" :disabled="readonly">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="抗震构造措施"  class="formItem formItem2" v-if="fromData.SFKZ_QG=='1'">
                  <el-select   placeholder="请选择抗震构造措施" v-model="kzgzcss"  multiple :disabled="readonly">
                    <el-option label="圈梁" value="1"></el-option>
                    <el-option label="构造柱" value="2"></el-option>
                    <el-option label="基础地圈梁" value="3"></el-option>
                    <el-option label="现浇钢筋混凝土楼、屋盖" value="4"></el-option>
                    <el-option label="木楼、屋盖房屋横墙间距不大于三开间" value="5"></el-option>
                    <el-option label="门窗间墙宽度不小于900mm" value="6"></el-option>
                    <el-option label="木屋盖设有剪刀撑" value="7"></el-option>
                    <el-option label="木屋盖与墙体有拉结措施" value="8"></el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="抗震构造措施照片"  class="formItem formItem1" >
                    <div class="imgBox" v-if="kzPhoto.length>0">
                        <div class="imgBoxItem" v-for="(item,i) in kzPhoto"  v-bind:key="i">
                            <el-image   :src="item.FILE_PATH"  :preview-src-list="kzPhotos" class="pp" ></el-image>
                            <i class="el-icon-delete pd" @click="delPhoto(item.ID,'3')" v-if="taskType=='2'"></i>
                            <p class="pt">
                                  方位角：
                                  <span>{{item.DIRECTION}}({{item.str}})</span>
                                </p>
                            <p class="pt">经度：{{item.LON}}</p>
                            <p class="pt">纬度：{{item.LAT}}</p>
                            <p class="pt">拍摄时间：{{item.PHOTOTIME}}</p>
                            <p class="pt">ID：{{item.ID}}</p>
                        </div>
                    </div>
                    <div v-else>无</div>
                </el-form-item>
                <el-form-item label="有无肉眼可见明显裂缝、变形、倾斜"  class="formItem formItem1">
                  <el-select  placeholder="请选择有无肉眼可见明显裂缝、变形、倾斜" v-model="fromData.YWLFBXQX" :disabled="readonly">
                    <el-option label="无" value="0"></el-option>
                    <el-option label="有" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <!--如果有则显示-->
                <el-form-item label="裂缝、变形、倾斜照片"  class="formItem formItem1" >
                        <div class="imgBox" v-if="lfPhoto.length>0">
                            <div class="imgBoxItem" v-for="(item,i) in lfPhoto"  v-bind:key="i">
                                <el-image   :src="item.FILE_PATH"  :preview-src-list="lfPhotos" class="pp" ></el-image>
                                <i class="el-icon-delete pd" @click="delPhoto(item.ID,'4')" v-if="taskType=='2'"></i>
                                <p class="pt">
                                  方位角：
                                  <span>{{item.DIRECTION}}({{item.str}})</span>
                                </p>
                                <p class="pt">经度：{{item.LON}}</p>
                                <p class="pt">纬度：{{item.LAT}}</p>
                                <p class="pt">拍摄时间：{{item.PHOTOTIME}}</p>
                                <p class="pt">ID：{{item.ID}}</p>
                            </div>
                        </div>
                        <div v-else>无</div>
                    </el-form-item>

                <el-form-item label="行政区选择"  class="formItem formItem1" v-if="showXZQSelect">
                  <el-cascader v-model="XZBM"  :props="xzqprops" style='width:100%'></el-cascader>
                </el-form-item>

                <el-form-item label="省行政编码"  prop="PROVINCE" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.PROVINCE"></el-input>
                </el-form-item>
                <el-form-item label="市行政编码"  prop="CITY" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.CITY"></el-input>
                </el-form-item>
                <el-form-item label="区县行政编码"  prop="DISTRICT"  class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.DISTRICT"></el-input>
                </el-form-item>
                <el-form-item label="镇街行政编码" prop="TOWN" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.TOWN"></el-input>
                </el-form-item>
                <el-form-item label="村居行政编码"  prop="VILLAGE" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.VILLAGE"></el-input>
                </el-form-item>
                <el-form-item label="　"  class="formItem formItem2"  v-if="taskType=='1'">
                  <el-button type="primary" @click="changeXZQ" class="fl ml10" style="width:100px;float:right">修改</el-button>
                </el-form-item>


                
                <el-form-item label=""  class="formItem formItem2" style="visibility: hidden;"></el-form-item>
                

                <!-- 固定为1030 -->
                <el-form-item label="房屋类别"  class="formItem formItem2">
                  <el-select   placeholder="请选择房屋类别" v-model="fromData.FWLB" :disabled="readonly">
                    <el-option label="农村住宅" value="0130"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 固定为1 -->
                <el-form-item label="房屋类型"  class="formItem formItem2">
                  <el-input   v-model="fromData.HOUSE_TYPE" autocomplete="off" :readonly="true"></el-input>
                </el-form-item>
                <el-form-item label="房屋名称"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.JZMC" :readonly="readonly"></el-input>
                </el-form-item>

                <el-form-item label="姓名或单位名称"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.HZXM" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="户主类型"  class="formItem formItem2">
                  <el-select   placeholder="请选择住宅类型"  v-model="fromData.HZLX_QG" :disabled="readonly">
                    <el-option label="产权人" value="1"></el-option>
                    <el-option label="使用人" value="2"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="现场调查情况"  class="formItem formItem2">
                   <el-input   autocomplete="off" v-model="fromData.XCDCQK" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="不需调查原因"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.BXDCYY" :readonly="readonly"></el-input>
                </el-form-item>


                <el-form-item label="建筑用途"  class="formItem formItem2">
                  <el-select   placeholder="请选择建筑用途"   v-model="jzyts"  multiple  :readonly="readonly">
                    <el-option label="教育设施" value="1"></el-option>
                    <el-option label="医疗卫生" value="2"></el-option>
                    <el-option label="行政办公" value="3"></el-option>
                    <el-option label="文化设施" value="4"></el-option>
                    <el-option label="养老服务" value="5"></el-option>
                    <el-option label="批发零售" value="6"></el-option>
                    <el-option label="餐饮服务" value="7"></el-option>
                    <el-option label="住宿宾馆" value="8"></el-option>
                    <el-option label="休闲娱乐" value="9"></el-option>
                    <el-option label="宗教场所" value="10"></el-option>
                    <el-option label="农贸市场" value="11"></el-option>
                    <el-option label="生产加工" value="12"></el-option>
                    <el-option label="仓储物流" value="13"></el-option>
                    <el-option label="其他" value="99"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="其他建筑用途" prop="QTJZYT_QG"  class="formItem formItem2" v-if="qtjzyt">
                  <el-input   autocomplete="off" v-model="fromData.QTJZYT_QG" :readonly="readonly"></el-input>
                </el-form-item> 

                <el-form-item label="建筑用途二级选项-教育设施-是否为“中小学幼儿园教学用房及学生宿舍、食堂“"  prop="JYSS_QG" class="formItem formItem1" v-if="jzyta">
                  <el-select   placeholder="请选择建筑用途二级选项-教育设施-是否为“中小学幼儿园教学用房及学生宿舍、食堂“"  v-model="fromData.JYSS_QG" :disabled="readonly">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="建筑用途二级选项-医疗设施-是否为“具有外科手术室或急诊科的乡镇卫生院医疗用房”(全国版新增字段"  prop="YLSS_QG" class="formItem formItem1" v-if="jzytb">
                  <el-select   placeholder="请选择建筑用途二级选项-医疗设施-是否为“具有外科手术室或急诊科的乡镇卫生院医疗用房”(全国版新增字段"  v-model="fromData.YLSS_QG" :disabled="readonly">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="建造年代"  class="formItem formItem2">
                  <el-select   placeholder="请选择住宅类型"  v-model="fromData.JZND_QG" :disabled="readonly">
                    <el-option label="1980年及以前" value="1"></el-option>
                    <el-option label="1981-1990年" value="2"></el-option>
                    <el-option label="1991-2000年" value="3"></el-option>
                    <el-option label="2001-2010年" value="4"></el-option>
                    <el-option label="2011-2015年" value="5"></el-option>
                    <el-option label="2016年及以后" value="6"></el-option>
                  </el-select>
                </el-form-item>

 

        

                <el-form-item label="承重墙体"   class="formItem formItem2" >
                  <el-select  placeholder="请选择承重墙体" v-model="fromData.CZQT_QG" :disabled="readonly">
                    <el-option label="砖" value="1"></el-option>
                    <el-option label="砌块" value="2"></el-option>
                    <el-option label="石" value="3"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="楼屋盖"   class="formItem formItem2" >
                  <el-select  placeholder="请选择楼屋盖"  v-model="lwgs"  multiple :disabled="readonly">
                    <el-option label="预制板" value="1"></el-option>
                    <el-option label="现浇板" value="2"></el-option>
                    <el-option label="木或轻钢楼屋盖石" value="3"></el-option>
                    <el-option label="石板或石条" value="4"></el-option>
                  </el-select>
    
                </el-form-item>

                 <el-form-item label="是否底部框架砌体结构"  class="formItem formItem2">
                  <el-select  placeholder="请选择是否底部框架砌体结构" v-model="fromData.DBKJQT_QG" :disabled="readonly">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>


                <el-form-item label="建造方式"  class="formItem formItem2">
                  <el-select  placeholder="请选择建造方式" v-model="fromData.JZFS_QG" :disabled="readonly">
                    <el-option label="自行建造" value="1"></el-option>
                    <el-option label="建筑工匠建造" value="2"></el-option>
                    <el-option label="有资质的施工队伍建造" value="3"></el-option>
                    <el-option label="其他" value="99"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="其他建造方式" prop="QTJZFS_QG" class="formItem formItem2" v-if="fromData.JZFS_QG=='99'">
                  <el-input  type="number"   autocomplete="off" v-model="fromData.QTJZFS_QG" :readonly="readonly"></el-input>
                </el-form-item>



                <el-form-item label="是否进行过抗震加固"  class="formItem formItem2">
                  <el-select   placeholder="请选择是否进行过抗震加固" v-model="fromData.SFKZJG" :disabled="readonly">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </el-form-item>
                 <!--如果加固必填-->
                <el-form-item label="抗震加固时间" prop="JGSJ" class="formItem formItem2" v-if="fromData.SFKZJG=='1'">
                  <el-input   autocomplete="off" placeholder="请输入年份(格式:YYYY)多次加固用逗号分隔" v-model="fromData.JGSJ" :readonly="readonly"></el-input>
                </el-form-item>



                <el-form-item label="是否进行专业设计"  class="formItem formItem2">
                  <el-select placeholder="请选择是否进行专业设计" v-model="fromData.FWSJFS" :disabled="readonly">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </el-form-item>

                
                <el-form-item label="是否经过安全鉴定"  class="formItem formItem2">
                  <el-select  placeholder="请选择是否经过安全鉴定" v-model="fromData.SFJGAQJD" :disabled="readonly">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="鉴定时间"  prop="AQJDNF" class="formItem formItem2" v-if="fromData.SFJGAQJD=='1'">
                  <el-input   autocomplete="off" placeholder="请输入年份(格式:YYYY)多次改造用逗号分隔" v-model="fromData.AQJDNF" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="鉴定或评定结论" prop="AQJDJL"  class="formItem formItem2" v-if="fromData.SFJGAQJD=='1'">
                  <el-select  placeholder="请选择鉴定结论等级" v-model="fromData.AQJDJL" :disabled="readonly">
                    <el-option label="A级" value="1"></el-option>
                    <el-option label="B级" value="2"></el-option>
                    <el-option label="C级" value="3"></el-option>
                    <el-option label="D级" value="4"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="安全性鉴定（是否安全"  class="formItem formItem2">
                  <el-select  placeholder="请选择安全性鉴定（是否安全" v-model="fromData.JDSFAQ" :disabled="readonly">
                    <el-option label="不安全" value="0"></el-option>
                    <el-option label="安全" value="1"></el-option>
                  </el-select>
                </el-form-item>


    
  
                <el-form-item label="调查人"  class="formItem formItem2">
                  <el-input   autocomplete="off" placeholder="请输入调查人" v-model="fromData.DCR" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="联系电话"  class="formItem formItem2">
                  <el-input type="number"  autocomplete="off" placeholder="请输入联系电话" v-model="fromData.LXDH" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="调查人组织"  class="formItem formItem2">
                  <el-input   autocomplete="off" placeholder="请输入调查人组织" v-model="fromData.ORG_NAME" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="调查时间"  class="formItem formItem1">
                   <el-date-picker  align="right" type="date" placeholder="选择日期" style="width:200px;" v-model="DCSJA" :readonly="readonly"></el-date-picker>
                   <el-time-picker placeholder="选择时间" style="width:200px;margin-left:12px;" v-model="DCSJB" :readonly="readonly"></el-time-picker>
                </el-form-item>
                <el-form-item label="调查状态"  class="formItem formItem2">
                  <el-select  placeholder="请选择调查状态" v-model="fromData.STATUS" :disabled="true">
                    <el-option label="调查中" value="1"></el-option>
                    <el-option label="已调查" value="2"></el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="备注"  class="formItem formItem1">
                  <el-input  type="textarea" autocomplete="off" placeholder="请输入备注" :rows="4" v-model="fromData.BZ" :readonly="readonly"></el-input>
                </el-form-item>
              </el-form>
              <div v-else class="isLostBox">
                  <p class="isLostTit">
                      调查对象数据灭失，只显示部分属性
                  </p>
                  <el-form :model="fromData" status-icon  :rules="fromrules" ref="form" label-width="100px" class="formMain" label-position="top">
                      <el-form-item label="调查人"  class="formItem formItem2">
                        <el-input   autocomplete="off"  v-model="fromData.DCR" readonly></el-input>
                      </el-form-item>
                      <el-form-item label="联系电话"  class="formItem formItem2">
                        <el-input type="number"  autocomplete="off"  v-model="fromData.LXDH" readonly></el-input>
                      </el-form-item>
                      <el-form-item label="调查人组织"  class="formItem formItem2">
                        <el-input   autocomplete="off" v-model="fromData.ORG_NAME" readonly></el-input>
                      </el-form-item>
                      <el-form-item label="调查时间"  class="formItem formItem2">
                        <el-date-picker  align="right" type="date"  style="width:200px;" v-model="DCSJA" readonly></el-date-picker>
                        <el-time-picker  style="width:200px;float:right;" v-model="DCSJB" readonly></el-time-picker>
                      </el-form-item>
                      <el-form-item label="备注"  class="formItem formItem2">
                        <el-input  type="textarea" autocomplete="off"  :rows="4" v-model="fromData.BZ" readonly></el-input>
                      </el-form-item>
                  </el-form>
              </div>
          </div>
          <div class="btnCon">
                <div class="btns">  
                  <el-button type="success" @click="toAudit" class="fl ml10"  v-if="taskType=='2'" size="mini">点击审核</el-button>
                  <div class="fl" style="overflow:hidden" v-if="taskType=='3'">
                        <p v-if="passStatus=='3'" style="color:#67C23A;font-size:15px;" class="fl ml10">通过</p>
                        <p v-if="passStatus=='4'" style="color:#F56C6C;font-size:15px;" class="fl ml10">重新调查</p>
                        <el-button type="primary" @click="auditModal=true" class="fl ml10"  size="mini">审核明细</el-button>
                        <el-button type="danger" @click="isuse" class="fl ml10" v-if="passStatus=='4'" size="mini">重新下发</el-button>
                  </div>  
              </div>
          </div>
       </div>
       <el-dialog title="任务审核" :visible.sync="auditModal" :modal-append-to-body='false'>
          <el-form :model="auditForm"  ref="auditForm" label-width="100px" class="demo-auditForm">
            <el-form-item label="照片审核" style="margin-bottom:10px!important">
              <el-select v-model="auditForm.IMAGERESULT" placeholder="选择照片审核" :disabled="taskType=='3'">
                <el-option label="优" value="1"></el-option>
                <el-option label="良" value="2"></el-option>
                <el-option label="差" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认定审核" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.RDRESULT" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.RDRESULT" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item>
            <el-form-item label="信息审核" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.INFORESULT" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.INFORESULT" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item>
            <!-- <el-form-item label="是否通过" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.RESULT" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.RESULT" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item> -->
            <el-form-item label="是否通过" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.STATUS" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.STATUS" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item>
            <el-form-item label="问题类型" style="margin-bottom:10px!important">
              <el-select v-model="auditForm.ERRTYPE" placeholder="选择问题类型" multiple clearable :disabled="taskType=='3'">
                <el-option label="照片质量" value="1"></el-option>
                <el-option label="房屋类型认定错误" value="2"></el-option>
                <el-option label="房屋结构认定错误" value="3"></el-option>
                <el-option label="房屋层数认定错误" value="4"></el-option>
                <el-option label="信息填写不全" value="5"></el-option>
                <el-option label="其他" value="99"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" style="margin-bottom:10px!important">
              <el-input  v-model="auditForm.OPINION" autocomplete="off" :readonly="taskType=='3'"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitAudit()" v-if="this.taskType=='2'">提交</el-button>
              <el-button @click="auditModal=false">关闭</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
  </div>
</template>
<style scoped>
  .content{width:100%;height: 100%;overflow: hidden;position: absolute;left: 0;right: 0;bottom: 0;top: 0;z-index: +1;}
  /* .mapBox{width: 40%;position: absolute;left: 0;bottom: 0;top: 0;z-index: +1;} */
  .formBox{width: 100%;position: absolute;right: 0;bottom: 0;top: 0;z-index: +1;}
  .txt{height: 40px;margin-left:16px;overflow: hidden;line-height: 40px;}
  .formCon{position: absolute;left: 16px;right: 16px;top: 56px; bottom:50px;overflow: auto;}
  .btnCon{position: absolute;left: 16px;right: 16px;bottom: 0px;overflow: hidden;height: 50px;background-color: #ECECEC;}
  .formConSH{bottom:106px!important;}
  .btnConSH{height:90px!important;}
  .btnCon .btns{overflow: hidden;padding: 0 16px;margin-top: 10px;}
  .auditTextOut{padding: 0 16px;overflow: hidden;float:right}
  .auditText{margin-top: 5px;margin-bottom: 5px;}
  .el-form-item{margin-bottom: 0px!important;}
</style>
<script>
export default {
  name: 'NCFZZ',
  props: {
    taskType: {
      type:String,
      default:null
    },
    taskBH:{
       type:String,
      default:null
    },
  },
  data () {
      var FWBH=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入房屋编号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('房屋编号只能是数字'));
        }else if(value.length>15){
             callback(new Error('房屋编号不能大于15位'));
        }
        else {
          callback();
        }
      };
      var QTJZYT_QG = (rule, value, callback) => {
        var f=false
        if(this.fromData.JZYT_QG.indexOf(",")!= -1){
          var arr=this.fromData.JZYT_QG.split(",")
          for(var i=0;i<arr.length;i++){
            if(arr[i]=='99'){
                f=true
                break;
            }
          }
        }else{
          if(this.fromData.JZYT_QG=='99'){
            f=true
          }
        }
        if (value == '' && f) {
          callback(new Error('请输入其他建筑用途'));
        } else {
          callback();
        }
      };
      var JYSS_QG = (rule, value, callback) => {
        var f=false
        if(this.fromData.JZYT_QG.indexOf(",")!= -1){
          var arr=this.fromData.JZYT_QG.split(",")
          for(var i=0;i<arr.length;i++){
            if(arr[i]=='1'){
                f=true
                break;
            }
          }
        }else{
          if(this.fromData.JZYT_QG=='1'){
            f=true
          }
        }
        if (value == '' && f) {
          callback(new Error('请选择是否'));
        } else {
          callback();
        }
      };
      var YLSS_QG = (rule, value, callback) => {
        var f=false
        if(this.fromData.JZYT_QG.indexOf(",")!= -1){
          var arr=this.fromData.JZYT_QG.split(",")
          for(var i=0;i<arr.length;i++){
            if(arr[i]=='2'){
                f=true
                break;
            }
          }
        }else{
          if(this.fromData.JZYT_QG=='2'){
            f=true
          }
        }
        if (value == '' && f) {
          callback(new Error('请选择是否'));
        } else {
          callback();
        }
      };
      var QTJZFS_QG = (rule, value, callback) => {
        if (value == '' && this.fromData.JZFS_QG=="99") {
          callback(new Error('请输入其他建造方式'));
        } else {
          callback();
        }
      };
      var DCMJ = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
          callback(new Error('建筑面积必须为数字'));
        } else {
          callback();
        }
      };

      var CS = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
          callback(new Error('层数必须为数字'));
        } else {
          callback();
        }
      };

      var QTJGLX_QG = (rule, value, callback) => {
        if (value === '' && this.fromData.JGLX_QG=="99") {
          callback(new Error('请输入其他结构类型'));
        } else {
          callback();
        }
      };
      var JGSJ = (rule, value, callback) => {
        if (this.fromData.SFKZJG=='1' && value === '') {
          callback(new Error('请输入加固时间'));
        } else {
          callback();
        }
      };
      var AQJDNF = (rule, value, callback) => {
        if (this.fromData.SFJGAQJD=='1' && value === '') {
          callback(new Error('请输入鉴定时间'));
        } else {
          callback();
        }
      };
      var AQJDJL = (rule, value, callback) => {
        if (this.fromData.SFJGAQJD=='1' && value === '') {
          callback(new Error('请选择鉴定结论等级'));
        } else {
          callback();
        }
      };
      var PROVINCE = (rule, value, callback) => {
        if (value == '') {
          callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var CITY = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
           callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var DISTRICT = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
            callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var TOWN = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
           callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var VILLAGE = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
           callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      return {
        lwgs:[],
        jzyts:[],
        kzgzcss:[],
        showXZQSelect:false,
        txt:"",
        readonly:false,
        byAudit:false,
        xzqprops: {
          lazy: true,
          lazyLoad :(node, resolve)=> {
            var guid=""
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
        },
        XZBM:[],
        DCSJA:"",
        DCSJB:"",
        fromData:{
          BH:"",
          PROVINCE:"",
          CITY:"",
          DISTRICT:"",
          TOWN:"",
          VILLAGE:"",
          FWBH:"",
          FWLB:"1030",
          HOUSE_TYPE:"1",
          XCDCQK:"",
          BXDCYY:"",
          JZMC:"",
          JZND_QG:"",
          DBKJQT_QG:"",
          JZFS_QG:"",
          HZXM:"",
          HZLX_QG:"",
          QTJZFS_QG:"",
          CS:"",
          ZU_QG:"",
          LU_QG:"",
          HAO_QG:"",
          CZQT_QG:"",
          JZYT_QG:"",
          QTJZYT_QG:"",
          JYSS_QG:"",
          YLSS_QG:"",
          TMJG_QG:"",
          LWG_QG:"",
          DCMJ:"",
          JGLX_QG:"",
          QTJGLX_QG:"",
          SFKZJG:"",
          JGSJ:"",
          KZGZCS_QG:"",
          KZCSZP_QG:"",
          SFKZ_QG:"", 
          FWSJFS:"",
          YWLFBXQX:"",
          LFBXQXZP:"",
          SFJGAQJD:"",
          AQJDNF:"",
          AQJDJL:"",
          JDSFAQ:"",
          PHOTO:"",
          DCR:"",
          LXDH:"",
          BZ:"",
          DCSJ:"",
          ORG_NAME:"",
          STATUS:"1",
        },
        fromrules:{
          PROVINCE: [
            { validator: PROVINCE, trigger: 'blur' }
          ],
          CITY: [
            { validator: CITY, trigger: 'blur' }
          ],
          DISTRICT: [
            { validator: DISTRICT, trigger: 'blur' }
          ],
          DISTOWNTRICT: [
            { validator: TOWN, trigger: 'blur' }
          ],
          VILLAGE: [
            { validator: VILLAGE, trigger: 'blur' }
          ],
          FWBH: [
            { validator: FWBH, trigger: 'blur' }
          ],
          QTJZYT_QG:[
            { validator: QTJZYT_QG, trigger: 'blur' }
          ],
          JYSS_QG:[
            { validator: JYSS_QG, trigger: 'blur' }
          ],
          YLSS_QG:[
            { validator: YLSS_QG, trigger: 'blur' }
          ],
          QTJZFS_QG:[
            { validator: QTJZFS_QG, trigger: 'blur' }
          ],
          DCMJ:[
            { validator: DCMJ, trigger: 'blur' }
          ],
          CS:[
            { validator: CS, trigger: 'blur' }
          ],
          QTJGLX_QG:[
            { validator: QTJGLX_QG, trigger: 'blur' }
          ],
          JGSJ:[
            { validator: JGSJ, trigger: 'blur' }
          ],
          AQJDNF:[
            { validator: AQJDNF, trigger: 'blur' }
          ],
          AQJDJL:[
            { validator: AQJDJL, trigger: 'blur' }
          ],
        },

        lfPhoto:[],
        lfPhotos:[],
        kzPhoto:[],
        kzPhotos:[],
        photo:[],
        photos:[],
        passStatus:"",

        isLost:"0",
        auditForm:{
          CASEGUID :this.$store.state.taskGuid,
          USERGUID:this.$store.state.guid,
          IMAGERESULT:"1",
          RDRESULT:"1",
          INFORESULT:"1",
          RESULT:"1",
          STATUS:"1",
          ERRTYPE:[],
          OPINION:"",
        },
        auditModal:false,
      }
  },
  mounted() {
    this.refDatas()
  },
  computed: {
    //用途
     qtjzyt(){
        var f=false
        if(this.fromData.JZYT_QG.indexOf(",")!= -1){
          var arr=this.fromData.JZYT_QG.split(",")
          for(var i=0;i<arr.length;i++){
            if(arr[i]=='99'){
                f=true
                break;
            }
          }
        }else{
          if(this.fromData.JZYT_QG=='99'){
            f=true
          }
        }
        return f
     } ,
     jzyta(){
        var f=false
        if(this.fromData.JZYT_QG.indexOf(",")!= -1){
          var arr=this.fromData.JZYT_QG.split(",")
          for(var i=0;i<arr.length;i++){
            if(arr[i]=='1'){
                f=true
                break;
            }
          }
        }else{
          if(this.fromData.JZYT_QG=='1'){
            f=true
          }
        }
        return f
     } ,
     jzytb(){
        var f=false
        if(this.fromData.JZYT_QG.indexOf(",")!= -1){
          var arr=this.fromData.JZYT_QG.split(",")
          for(var i=0;i<arr.length;i++){
            if(arr[i]=='2'){
                f=true
                break;
            }
          }
        }else{
          if(this.fromData.JZYT_QG=='2'){
            f=true
          }
        }
        return f
     } ,

     
    //其他结构类型
    QTJGLX_QG(){
      var f=false
      if(this.fromData.JGLX_QG=="99" ){
        f=true
      }
      return f
    },
  },
  watch: {
    XZBM(newVal,oldVal){
      if(this.showXZQSelect){
         this.fromData.PROVINCE=newVal[0]
         this.fromData.CITY=newVal[1]
         this.fromData.DISTRICT=newVal[2]
         this.fromData.TOWN=newVal[3]
         this.fromData.VILLAGE=newVal[4]
         this.getXZQEPSG()
      }
    },
    lwgs(newVal,oldVal){
      var str=""
      if(newVal.length>0){
        for(var i=0;i<newVal.length;i++){
          if(i==0){
            str=newVal[i]
          }else{
            str=str+","+newVal[i]
          }
        }
      }
      this.fromData.LWG_QG=str
    },
    jzyts(newVal,oldVal){
      var str=""
      if(newVal.length>0){
        for(var i=0;i<newVal.length;i++){
          if(i==0){
            str=newVal[i]
          }else{
            str=str+","+newVal[i]
          }
        }
      }
      this.fromData.JZYT_QG=str      
    },
    kzgzcss(newVal,oldVal){
      var str=""
      if(newVal.length>0){
        for(var i=0;i<newVal.length;i++){
          if(i==0){
            str=newVal[i]
          }else{
            str=str+","+newVal[i]
          }
        }
      }
      this.fromData.KZGZCS_QG=str 
    },
  },
  methods:{
    refDatas(){
        console.log("初始化查询")
        this.lwgs=[]
        this.jzyts=[]
        this.kzgzcss=[]
        this.passStatus=this.$store.state.taskStatus
        this.loadItem=null
        //console.log(this.taskType)
        if(this.taskType=="1"){
          this.txt="编辑任务"
        }else if(this.taskType=="2"){
          this.txt="任务审核"
          this.byAudit=true
          this.readonly=true
        }
        else if(this.taskType=="3"){
          this.txt="查看审核"
          this.readonly=true
          this.byAudit=true
        }
        else if(this.taskType=="4"){
          this.txt="新建任务"
          this.showXZQSelect=true
        }
        else if(this.taskType=="5"){
          this.txt="查看任务"
          this.readonly=true
        }

        if(this.taskType!="4"){
          this.getTaskData()
        }
    },
    //显示编辑行政区
    changeXZQ(){
      this.showXZQSelect=true
    },
    //获取属性
    getTaskData(){
      this.showLoading("请稍后...")
      var d={TYPE:"3",BH:this.taskBH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/GetObjectInfo", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
             if(response.data.DATA){
                this.isLost=response.data.DATA.ISDELETE
                this.fromData =response.data.DATA
                if(this.fromData.LWG_QG && this.fromData.LWG_QG!=""){
                    if(this.fromData.LWG_QG.indexOf(",")!= -1){
                        this.lwgs=this.fromData.LWG_QG.split(",")
                    }else{
                      this.lwgs.push(this.fromData.LWG_QG)
                    }
                }
                if(this.fromData.JZYT_QG && this.fromData.JZYT_QG!=""){
                    if(this.fromData.JZYT_QG.indexOf(",")!= -1){
                        this.jzyts=this.fromData.JZYT_QG.split(",")
                    }else{
                      this.jzyts.push(this.fromData.JZYT_QG)
                    }
                }
                if(this.fromData.KZGZCS_QG && this.fromData.KZGZCS_QG!=""){
                    if(this.fromData.KZGZCS_QG.indexOf(",")!= -1){
                        this.kzgzcss=this.fromData.KZGZCS_QG.split(",")
                    }else{
                      this.kzgzcss.push(this.fromData.KZGZCS_QG)
                    }
                }
                if(this.fromData.DCSJ && this.fromData.DCSJ!=""){
                   var  sj=this.fromData.DCSJ.split(" ")
                   this.DCSJA=sj[0]
                   this.DCSJB=new Date(this.fromData.DCSJ)
                }
                this.XZBM=[this.fromData.PROVINCE,this.fromData.CITY,this.fromData.DISTRICT,this.fromData.TOWN,this.fromData.VILLAGE,]
             }else{
                this.errorRequest("22","调查对象查询失败",true)
             }
             
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
      });
      this.getTaskPolygon()
      if(this.$store.state.taskGuid!=""){
          if(this.$store.state.taskStatus=="3" || this.$store.state.taskStatus=="4"){
             this.getAuditInfo()
          }
        }
    },
    //获取图形
    getTaskPolygon(){
      var d={BH:this.taskBH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/ShowPhotoAndZB", md5_data)
        .then((response) => {
          //console.log("调查对象图形")
          var d=response.data.DATA
          this.transformMapDatas(d)
        })
    },
    transformMapDatas(data){
        this.lfPhoto=[]
        this.lfPhotos=[]
        this.kzPhoto=[]
        this.kzPhotos=[]
        this.photo=[]
        this.photos=[]
        if(data.PHOTO && data.PHOTO.length>0){
          for(var p=0;p<data.PHOTO.length;p++){
             var dp=data.PHOTO[p]
             dp.str=this.comjs.getAzimuthStr(dp.DIRECTION)
             this.photo.push(dp)

          }
          for(var i=0;i<data.PHOTO.length;i++){
              this.photos.push(data.PHOTO[i].FILE_PATH)
          }
        }
        if(data.LFPHOTOLIST && data.LFPHOTOLIST.length>0){
          for(var l=0;l<data.LFPHOTOLIST.length;l++){
             var lp=data.LFPHOTOLIST[l]
             lp.str=this.comjs.getAzimuthStr(lp.DIRECTION)
             this.lfPhoto.push(lp)

          }
          for(var h=0;h<data.LFPHOTOLIST.length;h++){
              this.lfPhotos.push(data.LFPHOTOLIST[h].FILE_PATH)
          }
        }
        if(data.KZPHOTOLIST && data.KZPHOTOLIST.length>0){
          for(var z=0;z<data.KZPHOTOLIST.length;z++){
             var kp=data.KZPHOTOLIST[z]
             kp.str=this.comjs.getAzimuthStr(kp.DIRECTION)
             this.kzPhoto.push(kp)
          }
          for(var k=0;k<data.KZPHOTOLIST.length;k++){
              this.kzPhotos.push(data.KZPHOTOLIST[k].FILE_PATH)
          }
        }
    },
    //获取审核信息
    getAuditInfo(){
      //console.log("获取审核意见")
      var d={CASEGUID:this.$store.state.taskGuid}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/GetOpinion", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
            var d=response.data.DATA
            var er=[]
            if(d.ERRTYPE!=""){
              if(d.ERRTYPE.indexOf(",") != -1){             
                er =d.ERRTYPE.split(",")
              }else{
                 er=[d.ERRTYPE]
              }
            }
            d.ERRTYPE = er
            if(this.passStatus=="3"){
              d.STATUS="1"
            }else if(this.passStatus=="4"){
              d.STATUS="0"
            }
            this.auditForm=d
          }
        })
    },
    isuse(){
       this.$emit('isuse',this.$store.state.taskGuid);
    },

     toAudit(){
      this.auditForm={
          CASEGUID :this.$store.state.taskGuid,
          USERGUID:this.$store.state.guid,
          IMAGERESULT:"1",
          RDRESULT:"1",
          INFORESULT:"1",
          RESULT:"1",
          STATUS:"1",
          ERRTYPE:[],
          OPINION:"",
      },
      this.auditModal=true
    },
    submitAudit(){
      var estr=""
      if(this.auditForm.ERRTYPE.length>0){
        for(var i=0;i<this.auditForm.ERRTYPE.length;i++){
          if(i==0){
            estr=this.auditForm.ERRTYPE[i]
          }else {
            estr=estr+","+this.auditForm.ERRTYPE[i]
          }
        }
      }
      this.auditForm.ERRTYPE = estr

      if(this.auditForm.IMAGERESULT=="3" || this.auditForm.RDRESULT=="0" || this.auditForm.INFORESULT=="0" || this.auditForm.RESULT=="0"|| this.auditForm.STATUS=="0"|| this.auditForm.ERRTYPE!=""){
          this.$confirm('审核内容有不通过项，是否继续提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
              this.sendAudit()
            })
      }else{
        this.sendAudit()
      }
    },
    sendAudit(){
      var md5_data =this.comjs.requestDataEncrypt(this.auditForm, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.auditForm)).toUpperCase())
      this.$http.post("Manager/AuditCase", md5_data)
        .then((response) => {
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.auditModal=false
              this.$message.success("审核完成！")
              var t="3"
              if(this.auditForm.STATUS=="0"){
                t="4"
              }
               this.closeForm(this.auditForm)
              
          } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("请求失败，请稍后再试！");
      });
    },
    //关闭
    closeForm(d){
       this.$emit('closeForm',d);
    },
        //删照片
    delPhoto(id,type){
      this.$confirm('确定删除照片吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.sendDelPhoto(id,type)
        }).catch(() => {
         this.$message.info('已取消');    
      });
    },
    sendDelPhoto(id,type){
      this.showLoading("请稍后...")
      var params={
        ID :id,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/DeletePhoto", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.$message.success("已删除！")
            this.delPagePhoto(id,type)
          } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("请求失败，请稍后再试！");
      });
    },
    delPagePhoto(id,type){
      var np=[]
      var nps=[]
      var fp=""
      if(type=="1"){
        for(var a=0;a<this.photo.length;a++){
            if(this.photo[a].ID==id){
                fp=this.photo[a].FILE_PATH
            }else{
              np.push(this.photo[a])
            }
        }
        for(var b=0;b<this.photos.length;b++){
           if(this.photos[b]!=fp){
             nps.push(this.photos[b])
           }
        }
        this.photo=np
        this.photos=nps
        this.$emit('delMapPhoto',id);
      }else if(type=="2"){
        for(var c=0;c<this.fuPhoto.length;c++){
            if(this.fuPhoto[c].ID==id){
                fp=this.fuPhoto[c].FILE_PATH
            }else{
              np.push(this.fuPhoto[c])
            }
        }
        for(var d=0;d<this.fuPhotos.length;d++){
           if(this.fuPhotos[d]!=fp){
             nps.push(this.fuPhotos[d])
           }
        }
        this.fuPhoto=np
        this.fuPhotos=nps
        this.$emit('delMapPhoto',id);
      }else if(type=="3"){
        for(var e=0;e<this.kzPhoto.length;e++){
            if(this.kzPhoto[e].ID==id){
                fp=this.kzPhoto[e].FILE_PATH
            }else{
              np.push(this.kzPhoto[e])
            }
        }
        for(var f=0;f<this.kzPhotos.length;f++){
           if(this.kzPhotos[f]!=fp){
             nps.push(this.kzPhotos[f])
           }
        }
        this.kzPhoto=np
        this.kzPhotos=nps        
      }else if(type=="4"){
        for(var g=0;g<this.lfPhoto.length;g++){
            if(this.lfPhoto[g].ID==id){
                fp=this.lfPhoto[g].FILE_PATH
            }else{
              np.push(this.lfPhoto[g])
            }
        }
        for(var h=0;h<this.lfPhotos.length;h++){
           if(this.lfPhotos[h]!=fp){
             nps.push(this.lfPhotos[h])
           }
        }
        this.lfPhoto=np
        this.lfPhotos=nps          
      }
    },
  }
}
</script>

