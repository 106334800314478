var  comjs = {
  // baseUrl:"http://localhost:27207/Api/",
  // baseUrl:"http://localhost:8077/Api/",
  baseUrl:"https://investigation.17fbfb.com/Api/",
  sortAsc :function(o) {
    var n = [];
    for (var k in o) n.push(k);
    n.sort();
    for (var i = 0, str = ''; i < n.length; i++) {
      var v = o[n[i]];
      if (typeof v == 'string') {
        if (n[i].indexOf("TIME") != -1) {
          v=v.replace("T", "")
              }
        v = v.replace(/\s+/g, "")
      }
        
      if (v) {
        if ({}.toString.call(v) == '[object Object]') {
          //v = this.sortAsc(v);
          //v = JSON.stringify(v).replace(/:/g,'=');
          v = JSON.stringify(v)
        }
        else if ({}.toString.call(v) == '[object Array]') {
          //v = JSON.stringify(v).replace(/:/g,'=');
          v = JSON.stringify(v)
        }

      }
      str += n[i] + v;
    }
    ////console.log(str)
    return str;
  },
  requestDataEncrypt:function (data, token,sign) {
    var newData = {
      TIMESTAMP: new Date().getTime(),
      TOKEN: token,
      DATA: data,
      DATASIGN: sign,
    }
    return newData
  },
  GetQueryString:function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  PH : document.body.clientHeight,
  PW : document.body.clientWidth,
  getCoordinateByStr(str){
      var arr=[]
      if(str.indexOf("MULTIPOLYGON")!= -1){
        str=str.replace("MULTIPOLYGON(","")
        str= str.substring(0,str.length-1)
        while(str.indexOf("((")!= -1){
          str=str.replace("((","")
        }
        while(str.indexOf(")),")!= -1){
          str=str.replace(")),","|")
        }
        str= str.substring(0,str.length-2)
        var b=str.split("|")
        if(b.length>0){
          for(var j=0;j<b.length;j++){
            var p2=b[j].split(",")
            var dian=[]
            for(var k=0;k<p2.length;k++){
              var  o2=p2[k].split(" ")
              dian.push([parseFloat(o2[0]),parseFloat(o2[1])])
            }
            arr.push(dian)
          }
        }
      }else if(str.indexOf("POLYGON")!= -1){
        str=str.replace("POLYGON((","")
        str= str.substring(0,str.length-2)
        var a=str.split(",")
        if(a.length>0){
          for(var i=0;i<a.length;i++){
            var p1=a[i].split(" ")
            arr.push([parseFloat(p1[0]),parseFloat(p1[1])])
          }
        }
        arr=[arr]
      } 
      return arr
  },
  getAzimuthStr(angle){
    var str=""
    if(angle>0 && angle>360){
      angle=angle-360
    }
    if(angle<0 && angle<-360){
      angle=angle+360
    }
    if(angle==0 || angle==360 || angle==-360){
      str="����"
    }else if(angle>0 && angle<90){
      str="��ƫ��"
    }else if(angle==90 || angle==-270){
      str="����"
    }else if(angle>90 && angle<180){
      str="��ƫ��"
    }else if(angle==180 || angle==-180){
      str="����"
    }else if(angle>180 && angle<270){
      str="��ƫ��"
    }else if(angle==270 || angle==-90){
      str="����"
    }else if(angle>270 && angle<360){
      str="��ƫ��"
    }else if(angle<0 && angle> -90){
      str="��ƫ��"
    }else if(angle<-90 && angle> -180){
      str="��ƫ��"
    }else if(angle<-180 && angle> -270){
      str="��ƫ��"
    }else if(angle<-270 && angle> -360){
      str="��ƫ��"
    }
    return str
  }


}
export default comjs