<template>
  <div class="views-main views-full">
      <div class="views-main-title">
          <p class="txt">作业审核</p>
          <el-button type="primary" icon="el-icon-download" class="fr ml10" @click="exportExcel" size="small">导出任务Excel</el-button>
          <el-button type="danger"  class="fr ml10" @click="notImageUnPass" size="small">无图片批量不通过</el-button>
      </div>
      <div class="tableBox">
          <div class="views-main-search">
              <el-cascader v-model="xzq"  :props="xzqprops" placeholder="搜索或选择地区" clearable filterable></el-cascader>
              <el-select v-model="lb" placeholder="选择类别" class="ml10" style="width:180px">
                <el-option v-for="item in lbOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-select v-model="zt" placeholder="选择状态" class="ml10" style="width:180px">
                <el-option v-for="item in ztOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-select v-model="ywzp" placeholder="选择是否有房屋正面照" class="ml10" style="width:180px">
                <el-option label="有房屋正面照" value="1"></el-option>
                <el-option label="没有房屋正面照" value="0"></el-option>
              </el-select>
               <el-select v-model="bxdcyy" placeholder="选择不需调查原因是否为空" class="ml10" style="width:180px">
                <el-option label="不需调查原因不为空" value="1"></el-option>
                <el-option label="不需调查原因为空" value="0"></el-option>
              </el-select>
               <el-input v-model="fwbh" placeholder="输入房屋编号" style="width:180px;margin-top:6px;" class="fl mr10"></el-input>
              <el-input v-model="xm" placeholder="输入外业人员姓名" style="width:180px;margin-top:6px;" class="fl mr10"></el-input>
              <el-button type="primary" @click="getCaseListA" class="ml10">查询</el-button>
          </div>
          <div class="views-main-body">
            <el-table :data="caseList" stripe style="width: 100%" @selection-change="handleSelectionChange">
               <el-table-column type="expand">
                  <template slot-scope="props" >
                    <el-form label-position="right" inline class="table-expand" style="text-align:right!important" v-if="props.row.STATUS=='3' || props.row.STATUS=='4'">
                      <span class="expandDown">审核意见：{{ props.row.OPINION.OPINION }}</span>
                     
                    </el-form>
                    <el-form label-position="right" inline class="table-expand" v-else>
                        <span class="expandDown">暂未审核</span>
                    </el-form>
                  </template>
                </el-table-column>   
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="TITLE" label="标题"></el-table-column>
              <el-table-column prop="VILLAGE" label="行政村"></el-table-column>
              <el-table-column prop="TYPE" label="房屋类别">
                <template slot-scope="scope">
                  <span v-if="scope.row.TYPE==1">城镇房屋</span>
                  <span v-else-if="scope.row.TYPE==2">农村住宅</span>
                  <span v-else-if="scope.row.TYPE==3">农村非住宅</span>
                </template>
              </el-table-column>
              <el-table-column prop="CREATETIME" label="下发时间"></el-table-column>
              <el-table-column prop="EXECUTDATETIME" label="提交时间"></el-table-column>
              <el-table-column prop="EXECUTORNAME" label="提交人"></el-table-column>
              <el-table-column prop="AUDITDATETIME" label="审核时间">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS==2">/</span>
                  <span v-else>{{scope.row.AUDITDATETIME}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="AUDITORNAME" label="审核人" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS==2">/</span>
                  <span v-else>{{scope.row.AUDITORNAME}}</span>
                </template>
              </el-table-column>
             
              <el-table-column prop="STATUS" label="状态" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS=='2'" style="color:#409EFF">待审核</span>
                  <span v-else-if="scope.row.STATUS=='3'" style="color:#67C23A">已完成</span>
                  <span v-else-if="scope.row.STATUS=='4'" style="color:#F56C6C">重新调查</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button @click="audit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS=='2'">审核</el-button>
                  <el-button @click="isuse(scope.row)" type="text" size="small" style="color:#E6A23C" v-else-if="scope.row.STATUS=='4'">重新下发</el-button>
                  <el-button @click="detail(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS!='2'">查看</el-button>
                   
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
          </div>
      </div>
      <div class="auditBox" v-if="showAuditBox">
        <CZFW v-if="auditType=='1'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></CZFW>
        <NCZZ v-if="auditType=='2'"  :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></NCZZ>
        <NCFZZ v-if="auditType=='3'" :taskType="handleType" :taskBH="handleBH" @closeEditTask="closeEditTask"></NCFZZ>
      </div>
      <el-dialog title="选择人员" :visible.sync="chooseUserModal" width="400px"  :append-to-body="true">
        <el-form  status-icon  ref="cuForm" label-width="100px" class="demo-cpForm">
          <el-form-item label="姓名">
            <el-select v-model="chooseUserGUID" placeholder="请选择人员" multiple >
                    <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitChooseUserModal()">确定</el-button>
            <el-button @click="closeChooseUserModal()">取消</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog title="导出已提交任务" :visible.sync="exportModal" width="800px" top="200px" :append-to-body="true">
        <el-cascader v-model="xzqE"  :props="xzqpropsE" placeholder="搜索或选择地区" clearable filterable class="mr10" style="width:600px;"></el-cascader> 
        <el-button type="primary" @click="submitExportExcel()">确定</el-button>

      </el-dialog>
  </div>
</template>
<script>
import CZFW from "../components/ListForCZFW"
import NCZZ from "../components/ListForNCZZ"
import NCFZZ from "../components/ListForNCFZZ"
export default {
  name: "TaskIssued",
  components:{
    CZFW,
    NCZZ,
    NCFZZ,
  },
  data() {
    return {
      bxdcyy:"0",
      fwbh:"",
      ywzp:"1",
      xm:"",
      xzq:"",
      xzqCode:"",
      xzqprops: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      lb:"RISK_CENSUS_CITY_HOUSE",
      lbOptions:[
        {
          value:"RISK_CENSUS_CITY_HOUSE",
          label:"城镇房屋",
        },
        {
          value:"RISK_CENSUS_COUNTRY_HOUSE",
          label:"农村住宅",
        },
        {
          value:"RISK_CENSUS_COUNTRY_NON_HOUSE",
          label:"农村非住宅",
        },
      ],
      zt:"2",
      ztOptions:[
        {
          value:"2",
          label:"待审核",
        },
        {
          value:"3",
          label:"已完成",
        },
        {
          value:"4",
          label:"重新调查",
        },
      ],
      caseList:[],
      checkList:[],
      PageIndex:1,
      PageSize:10,
      Total:0,
      checkIdList:[],

      showAuditBox:false,
      auditType:"",
      handleType:"",
      handleBH:"",

      chooseUserModal:false,
      chooseUserGUID:[],
      userOptions: [],

      xzqE:"",
      xzqCodeE:"",
      xzqpropsE: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      exportModal:false,
    };
  },
  mounted() {
    this.loadItem=null
    //this.getCaseList()
    this.getUserList()
  },
  methods: {
  
    //获取人员
    getUserList(){
      var params={
        PAGEINDEX:1,
        PAGESIZE:2000,
        ROLEGUID:"3"
      }
      var uos=[]
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetUserList", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
              var d=response.data.DATA
              for(var i=0;i<d.length;i++){
                uos.push({label:d[i].TRUENAME+"("+d[i].LOGINNAME+")",value:d[i].GUID})
              }
              this.userOptions=uos
          } 
        })
    },
    getCaseListA(){
      this.PageIndex=1
      this.getCaseList()
    },
    //获取任务
    getCaseList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        TYPE:this.lb,
        STATUS:this.zt,
        COUNTRYCODE:this.xzqCode,
        EXECUTORNAME:this.xm,
        USERGUID:this.$store.state.guid,
        FWBH:this.fwbh,
        PHOTO:this.ywzp,
        BXDCYY:this.bxdcyy,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetCaseList", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.caseList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
          } else {
              this.caseList=[]
              this.Total=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.caseList=[]
          this.Total=0
          this.hideLoading()
      });
    },
    //切换页码
    PGChange(e){
      this.PageIndex=e
      this.getCaseList()
    },
    //审核任务
    audit(e){
      this.auditType=e.TYPE
      this.handleType="2"
      this.handleBH=e.BH
      this.$store.commit("setTaskStatus",e.STATUS)
      this.$store.commit("setTaskGuid",e.GUID)
      this.showAuditBox=true
    },
    //查看任务
    detail(e){
      this.auditType=e.TYPE
      this.handleType="3"
      this.handleBH=e.BH
      this.$store.commit("setTaskStatus",e.STATUS)
      this.$store.commit("setTaskGuid",e.GUID)
      this.showAuditBox=true
    },
    //关闭编辑
    closeEditTask(f){
      this.showAuditBox=false
      if(f){
        var nl=[]
        for(var i=0;i<this.caseList.length;i++){
          var c=this.caseList[i]
          if(c.CASEGUID== f.CASEGUID){
              if(f.STATUS=='1'){
                c.STATUS = "3"
              }else if(f.STATUS=='0'){
                c.STATUS = "4"
              }
              nl.push(c)
          }else{
            nl.push(c)
          }
        }
        this.caseList=nl
      }
    },

    isuse(e){
        var tn=""
        if(e.TYPE=="1"){
          tn="RISK_CENSUS_CITY_HOUSE"
        }else if(e.TYPE=="2"){
           tn="RISK_CENSUS_COUNTRY_HOUS"
        }else if(e.TYPE=="3"){
            tn="RISK_CENSUS_COUNTRY_NON_HOUSE"
        }
        var fwbh=e.TITLE.replace("调查任务","")
        var caseInfo={
          BH:e.BH,
          TABLENAME:tn,
          VILLAGECODE:e. VILLAGECODE,
          FWBH :fwbh
        }
        this.checkList=[caseInfo]
        this.chooseUserGUID=[]
        this.chooseUserModal=true
    },
    submitChooseUserModal(){
      console.log(this.chooseUserGUID)
      if(this.chooseUserGUID.length==0){
        this.$message.error('请至少选择一个人员');
      }else{
        this.showLoading("请稍后...")
        var d={CREATORGUID:this.$store.state.guid,EXECUTORGUID:this.chooseUserGUID,TASKLIST:this.checkList}
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/TaskRelease", md5_data)
          .then((response) => {
            //console.log(response)
           this.hideLoading()
            if (response.data.SUCCESS) {
                this.chooseUserModal=false
                this.$message.success('已下发！');
                this.getCaseList()
            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          }); 
      }
    },
    closeChooseUserModal(){
      this.chooseUserModal=false
    },
    //导出excel
    exportExcel(){
      this.exportModal=true
    },
    submitExportExcel(){
      var code=""
      if(this.xzqCodeE == ""){
        code = this.$store.state.xzqCode
      }else{
        code =this.xzqCodeE
      }
      this.showLoading("请稍后...")
      var d={
          CODE:code,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/ExportAuditList", md5_data)
          .then((response) => {
            this.hideLoading()
            if(response.data.SUCCESS){
              console.loog(response.data.DATA)
               window.open(response.data.DATA);
            }else{
               this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("请求出错，请稍后再试！")
          }); 
    },
    //选择任务
    handleSelectionChange(e){
      var nc=[]
      for(var i=0;i<e.length;i++){
          nc.push(e[i].CASEGUID)
      }
      this.checkIdList=nc
      
    },
    notImageUnPass(){
        console.log(this.checkIdList)
        if(this.checkIdList.length>0){
          this.$confirm('确定将选中的任务审核不通过吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.sendNotImageUnPass()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });          
            });
        }else{
          this.$message.error("请至少选择一个任务")
        }
    },
    //无图片批量不通过
    sendNotImageUnPass(){
      this.showLoading("请稍后...")
      var params={
        CASEGUIDLIST:this.checkIdList,
        USERGUID:this.$store.state.guid
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/AuditNoPhoto", md5_data)
          .then((response) => {
            //console.log(response)
           this.hideLoading()
            if (response.data.SUCCESS) {
                this.chooseUserModal=false
                this.$message.success('处理完成！');
                var cl=this.caseList
                var ncl=[]
                for(var x=0;x<cl.length;x++){
                    var has=false
                    for(var y=0;y<this.checkIdList.length;y++){
                      if(this.checkIdList[y] == cl[x].CASEGUID){
                        has=true
                        break;
                      }
                    }
                    var cli=cl[x]
                    if(!has){
                      ncl.push(cli)
                    }
                    this.caseList=ncl
                }

            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          }); 
    }
  },
  watch: {
    xzq(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCode=""
      }else{
        this.xzqCode=newVal[newVal.length-1]
      }
    },
    xzqE(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCodeE=""
      }else{
        this.xzqCodeE=newVal[newVal.length-1]
      }
    },
  },
  computed: {
    
  },
};
</script>
<style scoped>
  .switchBox{float:right;margin-top:2px;}
  .auditBox{position:absolute;left: 0;right: 0;bottom: 0;top: 0;z-index: +1;overflow: hidden;background-color: #FFFFFF;}
    .table-expand{text-align: center;}
  .expandDown{font-size: 14px;margin-right: 20px;color: #48A2FF;}
</style>
