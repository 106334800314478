<template>
  <div class="views-main views-full">
      <!-- <div class="views-main-title">
          <p class="txt" style="margin-top:12px;margin-right:20px;">任务统计</p>
         
      </div> -->
      <div class="views-main-body" ref="vmb" style="background-color:transparent;padding:0;top:0px;margin-top:0;overflow:hidden">
          <div class="VillageTable countModelItem"  :style="{width:vw+ 'px',height:ph+ 'px'}">
              <div class="countTit">
                 <p class="countTitTxt">按地区统计</p>
              </div>
              <div class="countMainBox">
                <el-table :data="xzqCount" stripe style="width: 100%" :height="ph-52">
                  <el-table-column prop="VILLAGENAME" label="名称"  fixed="left"></el-table-column>  
                  <el-table-column prop="WXFCOUNT" label="未下发"></el-table-column>  
                  <el-table-column prop="YXFCOUNT" label="已下发"></el-table-column>  
                  <el-table-column prop="DSHCOUNT" label="待审核"></el-table-column>  
                  <el-table-column prop="YWCCOUNT" label="已完成"></el-table-column>    
                  <el-table-column prop="CXDCCOUNT" label="重新调查"></el-table-column>    
                </el-table>
              </div>
          </div> 

          <div class="allCount countModelItem" :style="{width:pw-vw-10+ 'px'}">
              <div class="xzqBox">
                   <el-cascader v-model="xzq"  :props="xzqprops" placeholder="搜索或选择地区" clearable filterable class="xzqSel"></el-cascader >
                   <p class="xzqTxt">当前选择：{{xzqName}}</p>
                   <i class="el-icon-refresh xzqRef" @click="getInfoByCode"></i>
              </div>
              <div class="numberBox" :style="{width:pw-vw-210+ 'px'}">
                  <div class="numberItem">
                    <p class="n" style="color:#909399;">{{wxf}}</p>
                    <p class="t">未下发</p>
                  </div>
                  <div class="numberItem">
                    <p class="n" style="color:#409EFF;">{{yxf}}</p>
                    <p class="t">已下发</p>
                  </div>
                  <div class="numberItem">
                    <p class="n" style="color:#E6A23C;">{{dsh}}</p>
                    <p class="t">待审核</p>
                  </div>
                  <div class="numberItem">
                    <p class="n" style="color:#67C23A;">{{ywc}}</p>
                    <p class="t">已完成</p>
                  </div>
                  <div class="numberItem">
                    <p class="n" style="color:#F56C6C;">{{cxdc}}</p>
                    <p class="t">重新调查</p>
                  </div>
                  <div class="numberItem">
                    <p class="n" style="color:#409EFF;">{{wyry}}</p>
                    <p class="t">外业人员</p>
                  </div>
                  <div class="numberItem">
                    <p class="n" style="color:#909399;">{{nyry}}</p>
                    <p class="t">内业人员</p>
                  </div>
              </div>
          </div> 

          <div class="userTable countModelItem" :style="{width:hw+ 'px',height:ph-90+ 'px'}">
              <div class="countTit">
                 <p class="countTitTxt">人员任务完成情况</p>
                  <el-button type="primary" @click="getWYUserNumbers" class="ml10 fr" style="margin-top:3px;">查询</el-button>
                  <el-input v-model="xm" placeholder="人员姓名" style="width:30%;margin-top:3px;" class="fr" ></el-input>

              </div>
              <div class="countMainBox">
                 <el-table :data="userCount" stripe style="width: 100%" :height="ph-142">
                  <el-table-column prop="USERNAME" label="姓名"  fixed="left"></el-table-column>  
                  <el-table-column prop="YJSCOUNT" label="已下发"></el-table-column>  
                  <el-table-column prop="YTJCOUNT" label="已提交"></el-table-column>  
                  <el-table-column prop="YWCCOUNT" label="已完成"></el-table-column>    
                  <el-table-column prop="CSDCCOUNT" label="重新调查"></el-table-column>    
                </el-table>
              </div>
          </div> 

          <div class="typeChart countModelItem"  :style="{width:hw+ 'px',height:eh+ 'px'}">
            <div class="countTit">
                 <p class="countTitTxt">按房屋统计</p>
              </div>
              <div class="countMainBox">
                 <div style="width:100%;height:100%" id="typeChart"></div>
              </div>
          </div>

          <div class="timeChart countModelItem" :style="{width:hw+ 'px',height:eh+ 'px'}">
            <div class="countTit">
                 <p class="countTitTxt">近期审核情况</p>
                  <el-button type="primary" @click="getTimeNumbers" class="ml10 fr" style="margin-top:3px;">查询</el-button>
                   <el-date-picker
                      v-model="timeRange"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :clearable="false"
                      style="margin-top:3px;width:240px;" class="fr">
                    </el-date-picker>
              </div>
              <div class="countMainBox">
                 <div style="width:100%;height:100%" id="timeChart"></div>
              </div>
          </div>
      </div>
  </div>
</template>
<style scoped>
  .countTit{height: 46px;padding: 0 10px;overflow: hidden;}
  .countTit .countTitTxt{height: 46px;font-size: 16px;color:#444;float:left;line-height: 46px;}
  .countModelItem{background-color: #FFFFFF;position: relative;}
  .countMainBox{position: absolute;left: 10px;top: 56px;bottom: 10px;right: 10px;overflow: hidden;}

  .VillageTable{overflow: hidden;float:left;margin-right: 10px;}
  .allCount{height: 80px;float: left;}
  .userTable{float: right;margin-top: 10px;overflow: hidden;}

  .typeChart,.timeChart{float: left;margin-top: 10px;overflow: hidden;}

  .xzqBox{width: 180px;float:left;padding: 0 10px;}
  .xzqBox .xzqTxt{color: #555;font-size: 14px;margin-top: 7px; max-width: 140px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;float:left;}
  .xzqBox .xzqRef{font-size: 16px;color: #409EFF; cursor: pointer;float:left;margin-left: 3px;margin-top: 10px;}
  .numberBox{float:left;margin-left: 10px;overflow: hidden;}
  .numberItem{width: 14.28%;float:left}
  .numberItem .n{font-size: 28px;text-align: center;margin-top: 10px;}
  .numberItem .t{font-size: 14px;text-align: center;color: #333;margin-top: 3px;}
</style>
<script>
import * as echarts from 'echarts';
export default {
  name: "TaskCount",
  data() {
    return {
      xzq:[],
      xzqCode:this.$store.state.xzqCode,
      xzqprops: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      xzqName:"",

      

      pw:0,
      ph:0,
      vw:0,
      eh:0,
      hw:0,

      wxf:0,  
      yxf:0,
      dsh:0,
      ywc:0,
      cxdc:0,
      wyry:0,
      nyry:0,
      timeChart:null,
      typeChart:null,
      xzqCount:[],
      userCount:[],
      xm:"",
      timeRange:[],
    };
  },
  mounted() {
    var ed= new Date()
    var sd= new Date() - 1000 * 60 * 60 * 24 * 7
    this.timeRange=[new Date(sd),ed]
    this.intiSize()
    window.onresize = () => {
      return (() => {
        this.intiSize()
      })();
    };

    this.getInfoByCode()

  },
  computed: {

  },
  watch: {
    xzq(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCode=""
      }else{
        this.xzqCode=newVal[newVal.length-1]
      }
      this.getInfoByCode()
    },
  },
  methods: {
    intiSize(){
      //console.log("改变尺寸")
      this.pw = this.$refs.vmb.offsetWidth
      this.ph = this.$refs.vmb.offsetHeight
      this.vw = this.pw*0.3
      this.eh = (this.ph -100)/2
      this.hw = (this.pw - this.vw -20 )/2

      if(this.typeChart){
        setTimeout(()=>{
          this.typeChart.resize()
        },200)
        
      }
      if(this.timeChart){
        setTimeout(()=>{
          this.timeChart.resize()
        },200)
      }
    },
    //获取数据
    getInfoByCode(){
      if(this.xzqCode== null){
        this.xzqCode== ""
      }
      if(this.xzqCode ==""){
        this.xzqName="全部地区"
      }else{
        this.getXzqByCode()
      }

      this.getStatusNumbers()
      this.getUsersNumbers()
      this.getXzqNumbers()
      this.getWYUserNumbers()
      this.getTimeNumbers()
    },

    //获取行政区信息
    getXzqByCode(){
      var md5_data =this.comjs.requestDataEncrypt({CODE:this.xzqCode}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CODE:this.xzqCode})).toUpperCase())
      this.$http.post("Manager/GetXzqByCode",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
              if(response.data.DATA){
                this.xzqName=response.data.DATA.NAME
              }else{
                this.xzqName="全部地区"
              }
              
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,false)
          }
        })
    },

    //获取各状态数量
    getStatusNumbers(){
      var md5_data =this.comjs.requestDataEncrypt({VILLAGE:this.xzqCode}, this.$store.state.token,this.$md5(this.comjs.sortAsc({VILLAGE:this.xzqCode})).toUpperCase())
      this.$http.post("Statistics/GetHouseStatistics",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
              //this.xzqName=response.data.DATA.NAME
              var d=response.data.DATA
              this.wxf=d.RISK_CENSUS_CITY_HOUSE.WXF + d.RISK_CENSUS_COUNTRY_HOUSE.WXF + d.RISK_CENSUS_COUNTRY_NON_HOUSE.WXF
              this.yxf=d.RISK_CENSUS_CITY_HOUSE.YXF + d.RISK_CENSUS_COUNTRY_HOUSE.YXF + d.RISK_CENSUS_COUNTRY_NON_HOUSE.YXF
              this.dsh=d.RISK_CENSUS_CITY_HOUSE.DSH + d.RISK_CENSUS_COUNTRY_HOUSE.DSH + d.RISK_CENSUS_COUNTRY_NON_HOUSE.DSH
              this.ywc=d.RISK_CENSUS_CITY_HOUSE.YWC + d.RISK_CENSUS_COUNTRY_HOUSE.YWC + d.RISK_CENSUS_COUNTRY_NON_HOUSE.YWC
              this.cxdc=d.RISK_CENSUS_CITY_HOUSE.CXDC + d.RISK_CENSUS_COUNTRY_HOUSE.CXDC + d.RISK_CENSUS_COUNTRY_NON_HOUSE.CXDC
              if (this.typeChart) {
                    this.typeChart.dispose()
                    this.typeChart = null
                    document.getElementById("typeChart").innerHTML=""
                }
              this.typeChart = echarts.init(document.getElementById('typeChart'));
              var option = {
                  color:["#909399","#409EFF","#E6A23C","#67C23A","#F56C6C"],
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow'
                    }
                  },
                  grid: {
                        top: '10%',
                        left: '8%',
                        bottom: '8%',
                        right: '3%',
                    },
                  legend: {
                    data: ['未下发', '已下发', '待审核', '已完成','重新调查']
                  },
                  xAxis: [
                    {
                      type: 'category',
                      axisTick: { show: false },
                      data: ['城镇房屋', '农村住宅', '农村非住宅']
                    }
                  ],
                  yAxis: [
                    {
                      type: 'value'
                    }
                  ],
                  series: [
                    {
                      name: '未下发',
                      type: 'bar',
                      //barGap: 5,
                      label: {
                            show: true,		
                            formatter: '{c}',
                            position: 'top',	
                            textStyle: {	    
                                color: '#333333',
                                fontSize: 12
                            }
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [d.RISK_CENSUS_CITY_HOUSE.WXF, d.RISK_CENSUS_COUNTRY_HOUSE.WXF, d.RISK_CENSUS_COUNTRY_NON_HOUSE.WXF],
                      barWidth: 30,
                    },
                    {
                      name: '已下发',
                      type: 'bar',
                      //barGap: 5,
                      label: {
                            show: true,		
                            formatter: '{c}',
                            position: 'top',	
                            textStyle: {	    
                                color: '#333333',
                                fontSize: 12
                            }
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [d.RISK_CENSUS_CITY_HOUSE.YXF, d.RISK_CENSUS_COUNTRY_HOUSE.YXF, d.RISK_CENSUS_COUNTRY_NON_HOUSE.YXF],
                      barWidth: 30,
                    },
                    {
                      name: '待审核',
                      type: 'bar',
                      //barGap: 5,
                      label: {
                            show: true,		
                            formatter: '{c}',
                            position: 'top',	
                            textStyle: {	    
                                color: '#333333',
                                fontSize: 12
                            }
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [d.RISK_CENSUS_CITY_HOUSE.DSH, d.RISK_CENSUS_COUNTRY_HOUSE.DSH, d.RISK_CENSUS_COUNTRY_NON_HOUSE.DSH],
                      barWidth: 30,
                    },
                    {
                      name: '已完成',
                      type: 'bar',
                      //barGap: 5,
                      label: {
                            show: true,		
                            formatter: '{c}',
                            position: 'top',	
                            textStyle: {	    
                                color: '#333333',
                                fontSize: 12
                            }
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [d.RISK_CENSUS_CITY_HOUSE.YWC, d.RISK_CENSUS_COUNTRY_HOUSE.YWC, d.RISK_CENSUS_COUNTRY_NON_HOUSE.YWC],
                      barWidth: 30,
                    },
                    {
                      name: '重新调查',
                      type: 'bar',
                      //barGap: 5,
                      label: {
                            show: true,		
                            formatter: '{c}',
                            position: 'top',	
                            textStyle: {	    
                                color: '#333333',
                                fontSize: 12
                            }
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [d.RISK_CENSUS_CITY_HOUSE.CXDC, d.RISK_CENSUS_COUNTRY_HOUSE.CXDC, d.RISK_CENSUS_COUNTRY_NON_HOUSE.CXDC],
                      barWidth: 30,
                    },
                  ]
                };
              this.typeChart.setOption(option);

          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,false)
          }
        })
    },
    //获取人员数量
    getUsersNumbers(){
      var md5_data =this.comjs.requestDataEncrypt({VILLAGE:this.xzqCode}, this.$store.state.token,this.$md5(this.comjs.sortAsc({VILLAGE:this.xzqCode})).toUpperCase())
      this.$http.post("Statistics/GetUserStatistics",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
              var d=response.data.DATA
              this.wyry=d.WYCOUNT
              this.nyry=d.NYCOUNT
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,false)
          }
        })
    },
    getXzqNumbers(){
      var md5_data =this.comjs.requestDataEncrypt({VILLAGE:this.xzqCode}, this.$store.state.token,this.$md5(this.comjs.sortAsc({VILLAGE:this.xzqCode})).toUpperCase())
      this.$http.post("Statistics/GetVillageStatisticsV2",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
            var d=response.data.DATA
            this.xzqCount=d
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,false)
          }
        })
    },
    getWYUserNumbers(){
      var md5_data =this.comjs.requestDataEncrypt({VILLAGE:this.xzqCode,USERNAME:this.xm}, this.$store.state.token,this.$md5(this.comjs.sortAsc({VILLAGE:this.xzqCode,USERNAME:this.xm})).toUpperCase())
      this.$http.post("Statistics/GetWyUserStatistics",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
            var d=response.data.DATA
            this.userCount=d
          }else{
            this.errorRequest("22",response.data.MSG,false)
          }
        })
    },
    getTimeNumbers(){
      var sd=this.getDateStrByDate(this.timeRange[0])
      var ed=this.getDateStrByDate(this.timeRange[1])
      var primary={
        VILLAGE:this.xzqCode,
        STARTTIME:sd,
        ENDTIME:ed,
      }
      var md5_data =this.comjs.requestDataEncrypt(primary, this.$store.state.token,this.$md5(this.comjs.sortAsc(primary)).toUpperCase())
      this.$http.post("Statistics/GetRecentlyStatistics",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
            var d=response.data.DATA
            var dates=[]
            var d1=[]
            var d2=[]
            var d3=[]
            for(var i=0;i<d.length;i++){
              var  dstr= d[i].CREATETIME.split(" ")[0].substring(5,10)
              dates.push(dstr)
              d1.push(d[i].YXFCOUNT)
              d2.push(d[i].YWCCOUNT)
              d3.push(d[i].CXDCCOUNT)
            }
              if (this.timeChart) {
                    this.timeChart.dispose()
                    this.timeChart = null
                    document.getElementById("timeChart").innerHTML=""
                }
              this.timeChart = echarts.init(document.getElementById('timeChart'));
              var option = {
                 color:["#409EFF","#67C23A","#F56C6C"],
                  tooltip: {
                    trigger: 'axis'
                  },
                  legend: {
                    data: ['已下发', '已完成', '重新调查']
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: dates
                  },
                  yAxis: {
                    type: 'value'
                  },
                  series: [
                    {
                      name: '已下发',
                      type: 'line',
                     // stack: 'Total',
                      data: d1
                    },
                    {
                      name: '已完成',
                      type: 'line',
                      //stack: 'Total',
                      data: d2
                    },
                    {
                      name: '重新调查',
                      type: 'line',
                      //stack: 'Total',
                      data: d3
                    },
                  ]
              };
              this.timeChart.setOption(option);
          }else{
            this.errorRequest("22",response.data.MSG,false)
          }
        })

    },

    getDateStrByDate(date) {
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      var d = date.getDate()
      if (m < 10) m = '0' + m
      if (d < 10) d = '0' + d
      return y + '-' + m + '-' + d
    }
  },



};
</script>

