<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">村级调查对象</p>
    </div>
    <div class="tableBox" style="top:50px;left:16px;right:16px">
      <div class="views-main-search">
        <el-cascader v-model="xzq"  :props="xzqprops" placeholder="搜索或选择地区" clearable filterable></el-cascader>
        <el-button type="primary" @click="sendQuery" style="margin-left: 10px">查询</el-button>
        <el-button type="warning" icon="el-icon-download" class="ml10" @click="exportObject('2')">房屋图层导出</el-button>
        <el-button type="warning" icon="el-icon-download" class="ml10" @click="exportObject('1')">任务导出</el-button>
        <el-button type="success" icon="el-icon-download" class="ml10" @click="exportAuditResult()">审核结果导出</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="dlist" stripe style="width: 100%">
          <el-table-column prop="VILLAGENAME" label="行政村名称"></el-table-column>
          <el-table-column prop="VILLAGECODE" label="行政村编号"></el-table-column>     
          <el-table-column prop="COUNT" label="数量"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="del(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="exportName" :visible.sync="exportModal" width="800px" top="50px" :append-to-body="true">
        <div style="margin-bottom:30px;">
           <el-cascader v-model="xzqE"  :props="xzqpropsE" placeholder="搜索或选择地区" clearable filterable class="mr10" v-show="exportType=='2'" style="width:500px"></el-cascader> 
           <el-cascader v-model="xzqEA"  :props="xzqpropsEA" placeholder="搜索或选择地区" clearable filterable class="mr10" v-show="exportType=='1'" style="width:500px"></el-cascader> 
           <el-button type="primary" @click="submitExport()">确定</el-button>
        </div>
        <div style="overflow:hidden">
          <el-table :data="fileList" stripe style="width: 100%">
            <el-table-column prop="CODE" label="区县名称"></el-table-column>
            <el-table-column prop="DOWNLOADTIME" label="开始导出时间"></el-table-column>
            <el-table-column prop="COMPLATETIME" label="导出完成时间"></el-table-column>
            <el-table-column prop="STATUS" label="状态" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS!='0'" style="color:#67C23A">可下载</span>
                  <span v-else style="color:#E6A23C">导出中</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="downloadFile(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS!='0'">下载</el-button>
                <span v-else>/</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndexF" :page-size="PageSizeF" :total="TotalF" class="pg" @current-change="PGChangeF"></el-pagination>
        </div>
      </el-dialog>
      <el-dialog :title="exportName" :visible.sync="auditResultModal" width="800px" top="200px" :append-to-body="true">
        <el-cascader v-model="xzqR"  :props="xzqpropsR" placeholder="搜索或选择地区" clearable filterable class="mr10" style="width:600px;"></el-cascader> 
        <el-button type="primary" @click="submitExportAuditResult()">确定</el-button>

      </el-dialog>
  </div>
</template>
<script>
export default {
  name: "DeviceManage",
  data() {
    
    return {
      xzq:[],
      xzqCode:"",
      xzqprops: {
          lazy: true,
          lazyLoad :(node, resolve)=> {
            var guid=""
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      dlist:[],
      PageIndex:1,
      PageSize:10,
      Total:0,


      exportName:"",
      exportType:"",
      exportModal:false,
      
      xzqE:[],
      xzqCodeE:"",
      xzqpropsE: {
          lazy: true,
          //checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].TYPE=="3"
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      
      xzqEA:[],
      xzqCodeEA:"",
      xzqpropsEA: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    var disabled=false
                    if(l[i].TYPE=="1" || l[i].TYPE=="2"){
                      disabled=true
                    }
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf:l[i].CHILDCOUNT==0,
                      disabled:disabled,
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },


      fileList:[],
      PageIndexF:1,
      PageSizeF:10,
      TotalF:0,

      auditResultModal:false,
      xzqR:[],
      xzqCodeR:"",
      xzqpropsR: {
          lazy: true,
          lazyLoad :(node, resolve)=> {
            var guid=this.$store.state.xzqGuid
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf:l[i].TYPE=="4",
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
    };
  },
  mounted() {
    this.loadItem=null
    this.getList()
  },
  watch: {
    xzq(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCode=""
      }else{
        this.xzqCode=newVal[newVal.length-1]
      }
    },
    xzqE(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCodeE=""
      }else{
        this.xzqCodeE=newVal[newVal.length-1]
      }
    },
    xzqEA(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCodeEA=""
      }else{
        this.xzqCodeEA=newVal[newVal.length-1]
      }
    },
    xzqR(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCodeR=""
      }else{
        this.xzqCodeR=newVal[newVal.length-1]
      }
    },
  },
  methods: {
    sendQuery(){
      this.PageIndex=1
      this.getList()
    },
    getList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        VILLAGECODE:this.xzqCode,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetVillageAllQuoteList", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              var d=response.data.DATA
              this.dlist=d
              this.Total=response.data.TOTALCOUNT
          } else {
              this.dlist=[]
              this.Total=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
         this.hideLoading()
          this.dlist=[]
          this.Total=0
      });
    },
    PGChange(e){
      this.PageIndex=e
      this.getList()
    },
    del(e){
      var vn=""
      if(e.VILLAGENAME){
        vn=e.VILLAGENAME
      }else{
        vn=e.VILLAGECODE
      }
      var str="确定要删除 "+vn+" 里的所有调查对象？"
      this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(e)
        }).catch(() => {
          this.$message.info("已取消")
        });
    },
    sendDel(e){
      this.showLoading("请稍后")
      var params={
        VILLAGECODE:e.VILLAGECODE
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/DeleteVillageAllObj", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
             this.getList()
             this.$message.success("已删除！")
          } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("请求出错，请稍后再试！")
        });
    },
    exportObject(type){
      this.exportType=type
      if(type=="1"){
        this.exportName="任务导出"
      }else{
        this.exportName="房屋图形导出"
      }
      this.PageIndexF=1
      this.getFileList()
      this.exportModal=true
    },
    closeExport(){
      this.exportModal=false
    },
    submitExport(){
      if(this.exportType=="2" && this.xzqCodeE==""){
        this.$message.error("请选择一个区县")
      }else if(this.exportType=="1" && this.xzqCodeEA==""){
        this.$message.error("请选择一个区县")
      }else{
        var  exporturl=""
        var  exportCode=""
        if(this.exportType=="1"){
          exporturl="Manager/ExportObject"
          exportCode=this.xzqCodeEA
        }else{
          exporturl="Manager/ExportShp"
           exportCode=this.xzqCodeE
        }
        var d={
          CODE:exportCode,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post(exporturl, md5_data)
          .then((response) => {
            if(!response.data.SUCCESS){
              this.$message.warning("正在导出中，请稍等！")
            }
          })
          .catch((error) => {
            this.$message.error("请求出错，请稍后再试！")
          }); 
          setTimeout(()=>{
            this.getFileList()
          },1000)
      }
      
    },
    getFileList(type){
     var params={
        PAGEINDEX:this.PageIndexF,
        PAGESIZE:this.PageSizeF,
        TYPE:this.exportType,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())

      this.$http.post("Manager/ExportList", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
              this.fileList=response.data.DATA
              this.TotalF=response.data.TOTALCOUNT
          } else {
              this.fileList=[]
              this.TotalF=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.fileList=[]
          this.TotalF=0
      });
    },
    PGChangeF(e){
      this.PageIndexF=e
      this.getFileList()
    },
    downloadFile(e){
      console.log(this.hasDownLoads)
      var sd=this.hasDownLoads
      var hs=false
      for(var i=0;i<sd.length;i++){
        if(sd[i] == e.GUID){
          hs=true
          break;
        }
      }
      if(!hs){
        //this.hasDownLoads.push(e.GUID)
        this.afterDownLoad(e.GUID)
      }
      window.open(e.DOWNLOADURL);
    },
    

    exportAuditResult(){
      this.auditResultModal=true
    },
    submitExportAuditResult(){
      this.showLoading("请稍后...")
      var d={
          CODE:this.xzqCodeR,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/ExportAuditExcel", md5_data)
          .then((response) => {
            this.hideLoading()
            if(response.data.SUCCESS){
              console.loog(response.data.DATA)
               window.open(response.data.DATA);
            }else{
               this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("请求出错，请稍后再试！")
          }); 
    },
  },
  computed: {
  },

};
</script>
<style scoped>

</style>
