<template>
    <div class="main">
          hahah
    </div>
</template>
<style src="../../public/statics/css/mobile.css"></style>
<style src="../../public/statics/css/mobileTaskList.css"></style>
<script>
export default {
  name: "Login",
  data() {
    return {
      

    };
  },
  mounted() { 
    this.loadItem=null
    console.log(this.$store.state.code)
  },
  methods: {
    
  },
  computed: {
  },
  watch: {
  },
};
</script>
