<template>
  <div class="views-main views-full">
      <div class="views-main-title">
          <p class="txt">作业审核</p>
      </div>
      <div class="auditTable">
          <div class="views-main-search">
              <el-select v-model="lb" placeholder="选择类别" class="ml10" style="width:15%;">
                <el-option v-for="item in lbOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>

              <el-select v-model="zt" placeholder="选择状态" class="ml10" style="width:15%">
                <el-option v-for="item in ztOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-input v-model="xm" placeholder="外业人员姓名" style="width:15%;margin-top:6px;margin-left:12px;" class="fl" ></el-input>
              <el-input v-model="fwbh" placeholder="房屋编号" style="width:15%;margin-top:6px;margin-left:12px;" class="fl" ></el-input>
              <el-button type="primary" @click="getCaseListA" class="ml10">查询</el-button>
          </div>
          <div class="views-main-body" style="margin-top:0;">
            <el-table :data="caseList" stripe style="width: 100%">
               <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-form label-position="right" inline class="table-expand">
                      <span class="expandDown">标题:{{ props.row.TITLE}}</span>
                    </el-form>
                     <el-form label-position="right" inline class="table-expand">
                      <span class="expandDown">下发时间：{{ props.row.CREATETIME}}</span>
                    </el-form>
                    <el-form label-position="right" inline class="table-expand">
                      <span class="expandDown">提交时间：{{ props.row.EXECUTDATETIME}}</span>
                    </el-form>
                    <el-form label-position="right" inline class="table-expand"  v-if="props.row.STATUS=='3' || props.row.STATUS=='4'">
                      <span class="expandDown">审核时间：{{ props.row.AUDITDATETIME}}</span>
                    </el-form>
                    <el-form label-position="right" inline class="table-expand"  v-if="props.row.STATUS=='3' || props.row.STATUS=='4'">
                      <span class="expandDown">审核意见：{{ props.row.OPINION.OPINION }}</span>
                    </el-form>
                  </template>
                </el-table-column>   

              <!-- <el-table-column prop="TITLE" label="标题"></el-table-column>
              <el-table-column prop="VILLAGE" label="行政村"></el-table-column> -->
              <el-table-column prop="TYPE" label="房屋类别">
                <template slot-scope="scope">
                  <span v-if="scope.row.TYPE==1">城镇房屋</span>
                  <span v-else-if="scope.row.TYPE==2">农村住宅</span>
                  <span v-else-if="scope.row.TYPE==3">农村非住宅</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="CREATETIME" label="下发时间"></el-table-column>
              <el-table-column prop="EXECUTDATETIME" label="提交时间"></el-table-column> -->
              <el-table-column prop="EXECUTORNAME" label="提交人"></el-table-column>
              <!-- <el-table-column prop="AUDITDATETIME" label="审核时间">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS==2">/</span>
                  <span v-else>{{scope.row.AUDITDATETIME}}</span>
                </template>
              </el-table-column> -->
              <el-table-column prop="AUDITORNAME" label="审核人" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS==2">/</span>
                  <span v-else>{{scope.row.AUDITORNAME}}</span>
                </template>
              </el-table-column>
             
              <el-table-column prop="STATUS" label="状态" width="80" fixed="right">
                <template slot-scope="scope">
                  <span v-if="scope.row.STATUS=='2'" style="color:#409EFF">待审核</span>
                  <span v-else-if="scope.row.STATUS=='3'" style="color:#67C23A">已完成</span>
                  <span v-else-if="scope.row.STATUS=='4'" style="color:#F56C6C">重新调查</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80" fixed="right">
                <template slot-scope="scope">
                  <el-button @click="audit(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS=='2'">审核</el-button>
                  <el-button @click="detail(scope.row)" type="text" size="small" style="color:#409EFF" v-else>查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="total,prev,pager,next"  :current-page="PageIndexC" :page-size="PageSizeC" :total="TotalC" class="pg" @current-change="PGChangeC"></el-pagination>
          </div>
      </div>
      <div class="auditMap">
          <MAP ref="map" class="mapBox" :move="false" :draw="true"></MAP>
          <div class="villageSelectBox">
            <el-select v-model="nowVillageCode" placeholder="切换区域" filterable  class="sel">
                <el-option v-for="item in villageList" :key="item.VILLAGECODE" :label="item.VILLAGENAME" :value="item.VILLAGECODE" ></el-option>
            </el-select>
            <div class="leg">
               <span style="color:#409EFF">待审核：{{nowVillage.COUNT}}</span>
               <span style="color:#67C23A">已完成：{{nowVillage.FINISHCOUNT}}</span>
               <span style="color:#F56C6C">重新调查：{{nowVillage.NOPASSCOUNT}}</span>
            </div>
          </div>

      </div>
      <div class="auditDetail">
            <CZFW v-if="auditType=='1'" :taskType="handleType" :taskBH="handleBH" @isuse="isuse" @closeForm="closeForm" @delMapPhoto="delMapPhoto" ref="czfw"></CZFW>
            <NCZZ v-else-if="auditType=='2'"  :taskType="handleType" :taskBH="handleBH" @isuse="isuse" @closeForm="closeForm" @delMapPhoto="delMapPhoto" @showFFPolgon="showFFPolgon" @hideFFPolgon="hideFFPolgon" ref="nczz"></NCZZ>
            <NCFZZ v-else-if="auditType=='3'" :taskType="handleType" :taskBH="handleBH" @isuse="isuse" @closeForm="closeForm"  @delMapPhoto="delMapPhoto" ref="ncfzz"></NCFZZ>
      </div>
      <el-dialog title="选择人员" :visible.sync="chooseUserModal" width="400px"  :append-to-body="true">
        <el-form  status-icon  ref="cuForm" label-width="100px" class="demo-cpForm">
          <el-form-item label="姓名">
            <el-select v-model="chooseUserGUID" placeholder="请选择人员" multiple >
                <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitChooseUserModal()">确定</el-button>
            <el-button @click="closeChooseUserModal()">取消</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>
<script>
import MAP from "../components/OpenLayers"
import CZFW from "../components/TaskForCZFW"
import NCZZ from "../components/TaskForNCZZ"
import NCFZZ from "../components/TaskForNCFZZ"
import 'ol/ol.css';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Fill, Stroke, Circle, Style} from 'ol/style';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import * as turf from '@turf/turf'
export default {
  name: "TaskIssued",
  components:{
    CZFW,
    NCZZ,
    NCFZZ,
    MAP,
  },
  data() {
    return {
      map:null,
      mapInit:false,
      source:null,
      layer:null,
      dshStyle:null,  
      ywcStyle:null,
      cxdcStyle:null,
      xzStyle:null,

      fwbh:"",
      xm:"",
      xzq:"",
      xzqCode:"",
      xzqprops: {
          lazy: true,
          //checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid=""
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
      lb:"RISK_CENSUS_COUNTRY_HOUSE",
      lbOptions:[
        {
          value:"RISK_CENSUS_CITY_HOUSE",
          label:"城镇房屋",
        },
        {
          value:"RISK_CENSUS_COUNTRY_HOUSE",
          label:"农村住宅",
        },
        {
          value:"RISK_CENSUS_COUNTRY_NON_HOUSE",
          label:"农村非住宅",
        },
      ],
      zt:"2",
      ztOptions:[
        {
          value:"2",
          label:"待审核",
        },
        {
          value:"3",
          label:"已完成",
        },
        {
          value:"4",
          label:"重新调查",
        },
      ],

      caseList:[],
      PageIndexC:1,
      PageSizeC:10,
      TotalC:0,


      villageList:[],
      villageItems:{},
      nowVillage:{},
      nowVillageCode:"",


      tbList:{},

      checkFea:null,

      auditType:"",
      handleType:"",
      handleBH:"",

      chooseUserModal:false,
      chooseUserGUID:[],
      userOptions: [],
      taskInfo:{},

    };
  },
  watch: {
    xzq(newVal,oldVal){
      //console.log(newVal)
      if(newVal.length==0){
         this.xzqCode=""
      }else{
        this.xzqCode=newVal[newVal.length-1]
      }
    },
    map(newVal,oldVal){
      if(!this.mapInit){
         this.initMap()
        this.getVillageList()
      }
    },
    nowVillageCode(newVal,oldVal){
      this.nowVillage=this.villageItems[newVal]
      this.showVillageInfo()
    },
  },
  computed: {
    
  },
  mounted() {
    this.loadItem=null
    this.map=this.$refs.map.map
    this.getUserList()
    //this.getCaseList()
  },
  methods: {
    //获取人员
    getUserList(){
      var params={
        PAGEINDEX:1,
        PAGESIZE:2000,
        ROLEGUID:"3"
      }
      var uos=[]
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetUserList", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
              var d=response.data.DATA
              for(var i=0;i<d.length;i++){
                uos.push({label:d[i].TRUENAME+"("+d[i].LOGINNAME+")",value:d[i].GUID})
              }
              this.userOptions=uos
          } 
        })
    },
    initMap(){
        this.dshStyle=new Style({
          fill: new Fill({
              color: "rgba(64,158,255,0.2)"
          }),
          stroke: new Stroke({
              color: "#409EFF",
              width: 1
          }),
          image: new Circle({
              radius: 5,
              fill: new Fill({
                  color: "rgba(64,158,255,1)"
              })
          })
        })
        this.ywcStyle=new Style({
            fill: new Fill({
                color: "rgba(103,194,58,0.2)"
            }),
            stroke: new Stroke({
                color: "#67C23A",
                width: 1
            }),
            image: new Circle({
                radius: 5,
                fill: new Fill({
                    color: "rgba(103,194,58,1)"
                })
            })
        })
        this.cxdcStyle=new Style({
            fill: new Fill({
                color: "rgba(245,108,108,0.2)"
            }),
            stroke: new Stroke({
                color: "#F56C6C",
                width: 1
            }),
            image: new Circle({
                radius: 5,
                fill: new Fill({
                    color: "rgba(245,108,108,1)"
                })
            })
        })
        this.xzStyle=new Style({
            fill: new Fill({
                color: "rgba(230,162,60,0.9)"
            }),
            stroke: new Stroke({
                color: "#E6A23C",
                width: 2
            }),
            image: new Circle({
                radius: 5,
                fill: new Fill({
                    color: "rgba(230,162,60,1)"
                })
            })
        })
        this.source = new VectorSource({wrapX: false});
        this.layer = new VectorLayer({
          source: this.source,
          zIndex:88,
        });
        //地图实例
        this.map.addLayer(this.layer)
        this.mapInit=true
        this.map.on('singleclick', e => {
          //当前选中的图形
          let frist=false
          this.map.forEachFeatureAtPixel(
            e.pixel,
            (fea) => {
                if(!frist){
                    if(this.checkFea){
                        if(fea.getId() != this.checkFea.getId()){
                          var stutas=this.checkFea.get("stutas")
                          this.setFeatureStyle(this.checkFea,stutas)
                        } 
                    }
                    this.checkFea=fea
                    this.setFeatureStyle(this.checkFea,"99")
                    this.ShowDetailByCheck()
                    frist=true
                }
                
            },
            {
              hitTolerance: 20
            }
          );
      });
    },
    //获取村
    getVillageList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:1,
        PAGESIZE:10000,
        VILLAGECODE:"",
        USERGUID:this.$store.state.guid,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/AuditListByVillage", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.villageList=response.data.DATA
              if(this.villageList.length>0){
                this.villageItems={}
                for(var i=0;i<this.villageList.length;i++){
                  this.villageItems[this.villageList[i].VILLAGECODE]=this.villageList[i]
                }
                this.nowVillageCode=this.villageList[0].VILLAGECODE
                
                
              }
              
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.villageList=[]
          this.hideLoading()
      });
    },
    //查看村图斑
    showVillageInfo(){
          var e=this.nowVillage
          var epsgK="EPSG:4490"
          var epsgV="+proj=longlat +ellps=GRS80 +no_defs"
          var epsgE=[73.62,20,134,50]
          if(e.EPSG && e.EPSG!=""){
              epsgK=e.EPSG
          }
          if(e.EPSGVALUE && e.EPSGVALUE!=""){
            epsgV=e.EPSGVALUE
          }
          if(e.EPSGEXTENT && e.EPSGEXTENT!=""){
            epsgE=e.EPSGEXTENT.split(",")
          }

          if(epsgK != this.$store.state.epsg){
              this.$refs.map.setNewView(epsgK,epsgV,epsgE)
          }
          this.getVillagePolygons()
          this.PageIndexC=1
          this.lb=""
          this.xm=""
          this.fwbh=""
          //this.getCaseList()
    },
    getVillagePolygons(){
      this.tbList={}
      this.source.clear()
      this.checkFea=null
      this.showLoading("请稍后")
      var params={
        VILLAGECODE:this.nowVillageCode,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetVillageZBAndDetails", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
             var  ds=response.data.DATA
             var  toC=false
             if(ds.length>0){
                for(var i=0;i<ds.length;i++){
                    var d=ds[i]
                    //console.log(d)
                    if(d.ZB){
                       //console.log(d.ZB)
                          if(d.STUTAS=="2" || d.STUTAS =="3" || d.STUTAS =="4"){
                          this.tbList[d.CASEGUID]=d
                          var coors=this.comjs.getCoordinateByStr(d.ZB)
                          var geom= new Polygon(coors)
                          var ft = new Feature({
                            geometry: geom,
                          });
                          var tp=""
                          if(d.TABLENAME=="RISK_CENSUS_CITY_HOUSE"){
                              tp="1"
                            }else if(d.TABLENAME=="RISK_CENSUS_COUNTRY_HOUSE"){
                              tp="2"
                            }else if(d.TABLENAME=="RISK_CENSUS_COUNTRY_NON_HOUSE"){
                              tp="3"
                          }  
                          ft.set("type",tp)
                          ft.set("stutas",d.STUTAS)
                          ft.set("guid",d.CASEGUID)
                          ft.set("bh",d.BH)
                          ft.setId(d.CASEGUID)
                          this.setFeatureStyle(ft,d.STUTAS)
                          this.source.addFeature(ft) 
                      }
                      if(!toC){
                        var center=this.comjs.getCoordinateByStr(d.ZB)[0][0]
                        this.$refs.map.moveTo(center,14)
                        toC=true
                      }
                    }
      
                }
             }
             
          }
        })
        .catch((error) => {
          console.log(error)
          this.hideLoading()
          this.$message.error("请求失败，请稍后再试！");
        });
    },
    //修改要素样式
    setFeatureStyle(fea,stutas){
        if(stutas=="2"){
          fea.setStyle(this.dshStyle)
        }else if(stutas=="3"){
          fea.setStyle(this.ywcStyle)  
        }else if(stutas=="4"){
          fea.setStyle(this.cxdcStyle) 
        }else if(stutas=="99"){
          fea.setStyle(this.xzStyle) 
        }
    },
    //根据点击的要素执行不同的操作
    ShowDetailByCheck(){
        var stutas=this.checkFea.get("stutas")
        var type=this.checkFea.get("type")
        var guid=this.checkFea.get("guid")
        var bh=this.checkFea.get("bh")
        //console.log(bh)
        if(stutas=="2"){
           this.handleType="2"
           this.handleBH=bh
           this.$store.commit("setTaskStatus",stutas)
           this.$store.commit("setTaskGuid",guid)
           
        }else if(stutas=="3"){
           this.handleType="3"
           this.handleBH=bh
           this.$store.commit("setTaskStatus",stutas)
           this.$store.commit("setTaskGuid",guid)
        }else if(stutas=="4"){
           this.handleType="3"
           this.handleBH=bh
           this.$store.commit("setTaskStatus",stutas)
           this.$store.commit("setTaskGuid",guid)
        }
        else if(stutas=="1"){
           this.handleType="3"
           this.handleBH=bh
           this.$store.commit("setTaskStatus",stutas)
           this.$store.commit("setTaskGuid",guid)
        }
        if(type ==  this.auditType){
            setTimeout(()=>{
              if(type=="1"){
                this.$refs.czfw.refDatas()
              }else if(type=="2"){
                this.$refs.nczz.refDatas()
              }else if(type=="3"){
                this.$refs.ncdzz.refDatas()
              }  
            },500)
        }else{
           this.auditType = type
        }
        var md5_data =this.comjs.requestDataEncrypt({BH:bh}, this.$store.state.token,this.$md5(this.comjs.sortAsc({BH:bh})).toUpperCase())
        this.$http.post("Manager/ShowPhotoAndZB", md5_data)
          .then((response) => {
            var dps=response.data.DATA.PHOTO
            var fps=response.data.DATA.FUPHOTOLIST
            for(var i=0;i<fps.length;i++){
              dps.push(fps[i])
            }
            this.setMapPhoto(dps)
        })
    },
  
    getCaseListA(){
      this.PageIndexC=1
      this.getCaseList()
    },
    //获取任务
    getCaseList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.PageIndexC,
        PAGESIZE:this.PageSizeC,
        TYPE:this.lb,
        STATUS:this.zt,
        COUNTRYCODE:this.nowVillageCode,
        EXECUTORNAME:this.xm,
        USERGUID:this.$store.state.guid,
        FWBH:this.fwbh,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetCaseList", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.caseList=response.data.DATA
              this.TotalC=response.data.TOTALCOUNT
              if(this.caseList.length>0){
                this.defCheckItem(this.caseList[0])
              }
              
          } else {
              this.caseList=[]
              this.TotalC=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.caseList=[]
          this.TotalC=0
          this.hideLoading()
      });
    },
    //切换页码
    PGChangeC(e){
      this.PageIndex=e
      this.getCaseList()
    },

    defCheckItem(e){
      if(e.STATUS=="2"){
        this.audit(e)
      }else{
        this.detail(e)
      }
    },
    //审核任务
    audit(e){
      //console.log("审核")
      this.handleBH=e.BH
      this.$store.commit("setTaskStatus",e.STATUS)
      this.$store.commit("setTaskGuid",e.GUID)
      this.handleType="2"
      if(e.TYPE ==  this.auditType){
          setTimeout(()=>{
            if(e.TYPE=="1"){
              this.$refs.czfw.refDatas()
            }else if(e.TYPE=="2"){
              this.$refs.nczz.refDatas()
            }else if(e.TYPE=="3"){
              this.$refs.ncdzz.refDatas()
            }  
          },500)
          
      }else{
          this.auditType = e.TYPE
      }
      this.showZBImgByCase(e)
    },
    //查看任务
    detail(e){
      //console.log("查看")
      this.handleBH=e.BH
      this.$store.commit("setTaskStatus",e.STATUS)
      this.$store.commit("setTaskGuid",e.GUID)
      this.showAuditBox=true
      this.handleType="3"
       if(e.TYPE ==  this.auditType){
          if(e.TYPE=="1"){
            this.$refs.czfw.refDatas()
          }else if(e.TYPE=="2"){
            this.$refs.nczz.refDatas()
          }else if(e.TYPE=="3"){
            this.$refs.ncdzz.refDatas()
          }  
      }else{
          this.auditType = e.TYPE
      } 
      this.showZBImgByCase(e)
    },

    showZBImgByCase(e){
      var d={BH:e.BH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/ShowPhotoAndZB", md5_data)
        .then((response) => {
          // console.log("调查对象图形")
          // console.log(response)
          var d=response.data.DATA
          this.transformMapDatas(d,e)
        })
    },
    transformMapDatas(d,e){
      var  caseFea=this.source.getFeatureById(e.GUID)
      if(caseFea){
        if(this.checkFea){
          if(e.GUID!= this.checkFea.getId()){
            var stutas=this.checkFea.get("stutas")
            this.setFeatureStyle(this.checkFea,stutas)
          } 
        }
        this.checkFea=caseFea
        this.setFeatureStyle(this.checkFea,"99")
        //this.ShowDetailByCheck()
      }else{
          var coors=this.comjs.getCoordinateByStr(d.ZB)
          var  geom= new Polygon(coors)
          var ft = new Feature({
            geometry: geom,
          });
          ft.set("type",e.TYPE)
          ft.set("stutas",e.STUTAS)
          ft.set("guid",e.GUID)
          ft.set("bh",e.BH)
          ft.setId(e.GUID)
          this.setFeatureStyle(ft,"99")
          this.source.addFeature(ft) 
          this.checkFea=ft
      }
      var  center=this.checkFea.getGeometry().getCoordinates()[0][0]
      this.$refs.map.moveTo(center,14)
      var dps=d.PHOTO
      var fps=d.FUPHOTOLIST
      for(var i=0;i<fps.length;i++){
        dps.push(fps[i])
      }
      this.setMapPhoto(dps)
    },

    setMapPhoto(d){
      var oys=this.map.getOverlays().array_
      var _this=this
      for(var t=0;t<oys.length;t++){
        let d=oys[t]
        setTimeout(function(){
          _this.map.removeOverlay(d)
        },50)
      }
      //加载地图上的照片
      for(var i=0;i<d.length;i++){
            var id=d[i].ID
            let div = document.createElement("div"); 
            div.className="photoOverlay"

            let arrow=document.createElement("img"); 
            arrow.className="arrow"
            arrow.setAttribute("src","statics/img/map/arrow.png")
            div.appendChild(arrow);
            arrow.style.transform="rotate("+d[i].DIRECTION+"deg)"
            let p=document.createElement("p"); 
            p.innerHTML = d[i].DIRECTION+"("+this.comjs.getAzimuthStr(d[i].DIRECTION)+")"
            p.className="ptxt"
            div.appendChild(p);
            let img= document.createElement("img"); 
            img.className="pimg"
            img.setAttribute("pid",id)
            img.setAttribute("src",d[i].FILE_PATH)
            div.appendChild(img);
            let oy = new Overlay({
              id:id,
              element: div,
              offset: [-15, -15],
              position:[d[i].LON,d[i].LAT],
            });
            oy.set("pid",id)
            this.map.addOverlay(oy);
      }
    },
    delMapPhoto(id){
      var oys=this.map.getOverlayById(id)
      this.map.removeOverlay(oys)
    },

    closeForm(d){
       var fea=this.source.getFeatureById(d.CASEGUID)
       
       var type="3"
       if(d.STATUS=="0"){
         type="4"
       }
       fea.set("stutas",type)
       if(fea){
         this.setFeatureStyle(fea,type)
       }
       if(this.checkFea.getId()==d.CASEGUID){
         this.setFeatureStyle(this.checkFea,"99")
       }

       if(type=="3"){
         this.nowVillage.FINISHCOUNT++
       }else if(type=="4"){
         this.nowVillage.NOPASSCOUNT++
       }
       this.nowVillage.COUNT--
       var nl=[]
       for(var i=0;i<this.caseList.length;i++){
         if(this.caseList[i].CASEGUID != d.CASEGUID){
            nl.push(this.caseList[i])
         }
       }
       this.caseList = nl
       if(this.caseList.length==0){
          this.handleType="3"
          if(this.auditType=="1"){
            this.$refs.czfw.refDatas()
          }else if(this.auditType=="2"){
            this.$refs.nczz.refDatas()
          }else if(this.auditType=="3"){
            this.$refs.ncdzz.refDatas()
          } 
       }else{
         this.defCheckItem(this.caseList[0])
       }
    },
    isuse(e){
        this.chooseUserGUID=[]
        this.chooseUserModal=true
        var cl=this.caseList
        var tinfo={}
        for(var i=0;i<cl.length;i++){
          if(cl[i].CASEGUID==e){
            var d=cl[i]
            var tn=""
            if(d.TYPE=="1"){
              tn="RISK_CENSUS_CITY_HOUSE"
            }else if(d.TYPE=="2"){
              tn="RISK_CENSUS_COUNTRY_HOUS"
            }else if(d.TYPE=="3"){
                tn="RISK_CENSUS_COUNTRY_NON_HOUSE"
            }
            var fwbh=d.TITLE.replace("调查任务","")
            tinfo={
              BH:d.BH,
              TABLENAME:tn,
              VILLAGECODE:d. VILLAGECODE,
              FWBH :fwbh
            }
            break;
          }
        }
        this.taskInfo=tinfo
        console.log(tinfo)
    },
    submitChooseUserModal(){
      if(this.chooseUserGUID.length==0){
        this.$message.error('请至少选择一个人员');
      }else{
        this.showLoading("请稍后...")
        var d={CREATORGUID:this.$store.state.guid,EXECUTORGUID:this.chooseUserGUID,TASKLIST:[this.taskInfo]}
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/TaskRelease", md5_data)
          .then((response) => {
            //console.log(response)
           this.hideLoading()
            if (response.data.SUCCESS) {
                this.chooseUserModal=false
                this.$message.success('已下发！');
                this.getVillagePolygons()
            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          }); 
      }
    },
    closeChooseUserModal(){
      this.chooseUserModal=false
    },

    showFFPolgon(o){
      if(o.zb && o.zb!=undefined && o.zb !=""){
        var coors=this.comjs.getCoordinateByStr(o.zb)
        var geom= new Polygon(coors)
        var ft = new Feature({
          geometry: geom,
        });
        ft.setId(o.id)
        this.setFeatureStyle(ft,"99")
        this.source.addFeature(ft)
      }
    },
    hideFFPolgon(id){
      var  fea=this.source.getFeatureById(id)
      if(fea){
        this.source.removeFeature(fea)
      }
    },
  },

};
</script>
<style scoped>
  .switchBox{float:right;margin-top:2px;}
  .expandDown{font-size: 14px;margin-right: 20px;color: #48A2FF;}
</style>
