import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import qs from 'qs';
import VueCookies from 'vue-cookies'
import md5 from 'js-md5';
import comjs from '../public/statics/js/common.js'

Vue.config.productionTip = false

//下载 ajax
const upload = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
  headers: {
    'Content-Type': 'multipart/form-data' ,
  },
})

Vue.prototype.$upload = upload;

//普通ajax
const $axios = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
});

Vue.prototype.$http = $axios;


//组件
Vue.prototype.mint = MintUI;
Vue.prototype.$qs = qs;
Vue.prototype.$md5 = md5;
Vue.prototype.comjs = comjs;
Vue.use(ElementUI);
Vue.use(VueCookies)
Vue.use(MintUI)
VueCookies.config('30d')

//注销
var pcLogin= ()=>{
  store.commit('setToken', "");  
  store.commit('setEndTime', "");
  store.commit('setCode', "");  
  store.commit('setGuid', "");
  store.commit('setName', "");
  store.commit('setType', "");
  store.commit('setXzqCode', "");
  store.commit('setXzqGuid', "");

  VueCookies.remove("setToken")
  VueCookies.remove("setEndTime")
  VueCookies.remove("setCode")
  VueCookies.remove("setGuid")
  VueCookies.remove("setName")
  VueCookies.remove("setType")
  VueCookies.remove("setXzqCode")
  VueCookies.remove("setXzqGuid")
  router.replace("/")
  clearDownFileTimer()
}
Vue.prototype.logout = pcLogin

//接口异常处理
var errorRequest=(code,msg,show)=>{
    code=code.toString()
    var  c=code.substring(0, 1);
    if(c=="1"){
      ElementUI.Message.error(msg+",即将回到登录界面")
      setTimeout(function(){
        pcLogin()
      },2000)
    }else{
      if(show){
        ElementUI.Message.error(msg)
      }
    }
}
Vue.prototype.errorRequest = errorRequest

//等待框
var loadItem=null
var showLoading=function(txt){
  loadItem = this.$loading({
    lock: true,
    text: txt,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
}
var hideLoading=function(){
  loadItem.close()
}

Vue.prototype.loadItem = loadItem
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading


//自动下载
var hasDownLoads=[]
Vue.prototype.hasDownLoads=hasDownLoads

var downFileTimerA=null
var getDownFileListA=function(data){
  downFileTimerA = self.setInterval(()=>{
    $axios.post("Manager/ExportList", data)
    .then((response) => {
      if (response.data.SUCCESS) {
         var d=response.data.DATA
         for(var i=0;i<d.length;i++){
           if(d[i].STATUS =="1" && d[i].DOWNLOADURL!=""){
            var f=flagFileIsDown(d[i].GUID)
            if(!f){
             
              afterDownLoad(d[i].GUID)
              window.open(d[i].DOWNLOADURL);
            }
           }
         }
      }
    })
  },600000)
 }
 var downFileTimerB=null
 var getDownFileListB=function(data){
  downFileTimerB = self.setInterval(()=>{
     $axios.post("Manager/ExportList", data)
     .then((response) => {
       if (response.data.SUCCESS) {
          var d=response.data.DATA
          for(var i=0;i<d.length;i++){
            if(d[i].STATUS =="1" && d[i].DOWNLOADURL!=""){
              var f=flagFileIsDown(d[i].GUID)
              if(!f){
                afterDownLoad(d[i].GUID)
                window.open(d[i].DOWNLOADURL);
              }
            }
          }
       }
     })
   },600000)
  }

  var flagFileIsDown= function(id){
     var f=false
     for(var i=0;i<hasDownLoads.length;i++){
       if(hasDownLoads[i] == id){
          f=true
          break;
       }
     }
     return f
  }

  var  clearDownFileTimer=function(){
     if(downFileTimerA){
       clearInterval(downFileTimerA)
     }
     if(downFileTimerB){
      clearInterval(downFileTimerB)
    }
  }
  
  var doingDownFileFlag =false
  Vue.prototype.doingDownFileFlag=doingDownFileFlag
  var doDownFileFlag=function (){
    doingDownFileFlag=true
      if(VueCookies.isKey("setToken") && VueCookies.isKey("setEndTime") && VueCookies.isKey("setType") && VueCookies.get("setType")=="1" ){
        var be=new Date().getTime()
        var ee=new Date(VueCookies.get("setEndTime")).getTime()
        if(be<ee){
           var token=VueCookies.get("setToken")
           var p1={PAGEINDEX:1,PAGESIZE:50,TYPE:"1"}
           var p2={PAGEINDEX:1,PAGESIZE:50,TYPE:"2"}
           var d1 =comjs.requestDataEncrypt(p1, token,md5(comjs.sortAsc(p1)).toUpperCase())
           var d2 =comjs.requestDataEncrypt(p2, token,md5(comjs.sortAsc(p2)).toUpperCase())
           getDownFileListA(d1)
           getDownFileListB(d2)
        }else{
          clearDownFileTimer()
        }
      }else{
        clearDownFileTimer()
      }
  }
  Vue.prototype.doDownFileFlag=doDownFileFlag

  if(!doingDownFileFlag){
    doDownFileFlag()
  }
  

  var  afterDownLoad=function (id){
    var token=VueCookies.get("setToken")
    hasDownLoads.push(id)
    var p={GUID:id}
    var d=comjs.requestDataEncrypt(p, token,md5(comjs.sortAsc(p)).toUpperCase())
    $axios.post("Manager/UpdateDownloadStatus", d)
    .then((response) => {
      //
    })
    console.log(hasDownLoads)
  }
  Vue.prototype.afterDownLoad=afterDownLoad
  




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
