<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">人员审核</p>
    </div>
    
    <div class="tableBox" style="top:50px;left:16px;right:16px">
      <div class="views-main-search">
        <el-select v-model="type" placeholder="选择状态" class="iw">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="dlm" placeholder="请输入登录名" class="iw" v-if="type=='2'" style="width:180px;"></el-input>
        <el-input v-model="name" placeholder="请输入姓名" class="iw" v-if="type=='2'" style="width:180px;"></el-input>
        <el-input v-model="tel" placeholder="请输入手机号" class="iw" v-if="type=='2'" type="number" style="width:180px;"></el-input>
        <el-select v-model="role" placeholder="选择人员类型" class="iw" v-if="type=='2'">
          <el-option v-for="item in roles" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="getUserList" v-if="type=='2'">查询</el-button>
        <el-button type="primary" @click="getDSHListA" v-if="type=='1'">查询</el-button>
      </div>
      <div class="views-main-body" v-show="type=='1'">
        <el-table :data="dsh" stripe style="width: 100%">
          <el-table-column prop="TITLE" label="标题"></el-table-column>
          <el-table-column prop="USERRESPONSE.LOGINNAME" label="登录名" width="120"></el-table-column>
          <el-table-column prop="USERRESPONSE.TRUENAME" label="姓名" width="120"></el-table-column>
          <el-table-column prop="USERRESPONSE.DEPARTMENT" label="所属单位">
            <template slot-scope="scope">
              <span v-if="scope.row.USERRESPONSE.DEPARTMENT!=null">{{scope.row.USERRESPONSE.DEPARTMENT}}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="USERRESPONSE.SEX" label="性别" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.USERRESPONSE.SEX=='1'">男</span>
              <span v-else-if="scope.row.USERRESPONSE.SEX=='2'">女</span>
              <span v-else-if="scope.row.USERRESPONSE.SEX=='3'">保密</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column prop="USERRESPONSE.MOBILEPHONE" label="电话" width="200"></el-table-column>
          <el-table-column prop="USERRESPONSE.XZQCODE" label="行政区代码" width="120"></el-table-column>
          <el-table-column prop="DEVICERESPONSE.DEVICENO" label="设备编号" width="120"></el-table-column>
          <el-table-column prop="DEVICERESPONSE.TYPE" label="设备类型" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.DEVICERESPONSE.TYPE=='1'">手机</span>
              <span v-else-if="scope.row.DEVICERESPONSE.TYPE=='2'">平板</span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column prop="CREATETIME" label="申请时间" width="200"></el-table-column>
          
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="userPass(scope.row)" type="text" size="small" style="color:#67C23A">通过</el-button>
              <el-button @click="userUNPass(scope.row)"  type="text" size="small" style="color:#F56C6C">不通过</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next"  :current-page="dshPageIndex" :page-size="dshPageSize" :total="dshTotal" class="pg" @current-change="dshPGChange"></el-pagination>
      </div>
      <div class="views-main-body" v-show="type=='2'">
        <el-table :data="ysh" stripe style="width: 100%">
          <el-table-column prop="TRUENAME" label="姓名" width="120"></el-table-column>
          <el-table-column prop="ROLELIST" label="角色">
            <template slot-scope="scope">
              <span  v-for="item in scope.row.ROLELIST" :key="item.NAME">{{item.NAME}}　</span>
            </template>
          </el-table-column>
          <el-table-column prop="LOGINNAME" label="登录名" width="120"></el-table-column>
          <el-table-column prop="DEPARTMENT" label="所属单位">
            <template slot-scope="scope">
              <span v-if="scope.row.DEPARTMENT!=null">{{scope.row.DEPARTMENT}}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="SEX" label="性别" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.SEX=='1'">男</span>
              <span v-else-if="scope.row.SEX=='2'">女</span>
              <span v-else-if="scope.row.SEX=='3'">保密</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column prop="MOBILEPHONE" label="电话" width="200">
              <template slot-scope="scope">
                  <span v-if="scope.row.MOBILEPHONE==null">无</span>
                  <span v-else>{{scope.row.MOBILEPHONE}}</span>
              </template>
          </el-table-column>
          <el-table-column prop="XZQCODE" label="行政区代码" width="200"></el-table-column>
          <el-table-column prop="EMAIL" label="邮箱" width="200">
              <template slot-scope="scope">
                  <span v-if="scope.row.EMAIL==null">无</span>
                  <span v-else>{{scope.row.EMAIL}}</span>
              </template>
          </el-table-column>
          <el-table-column prop="CREATETIME" label="创建时间" width="200"></el-table-column>
          <el-table-column prop="BZ" label="备注">
            <template slot-scope="scope">
                  <span v-if="scope.row.BZ==null">无</span>
                  <span v-else>{{scope.row.BZ}}</span>
              </template>
          </el-table-column>
           <el-table-column prop="ISENABLE" label="是否可用" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.ISENABLE=='1'">可用</span>
              <span v-else-if="scope.row.ISENABLE=='0'" style="color:#F56C6C">不可用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total,prev,pager,next"  :current-page="yshPageIndex" :page-size="yshPageSize" :total="yshTotal" class="pg" @current-change="yshPGChange"></el-pagination>
      </div>
    </div>
    <el-dialog title="修改资料" :visible.sync="changeUserModal">
      <el-form :model="userItem" status-icon :rules="userrules" ref="userForm" label-width="100px" class="demo-userForm">
        <el-form-item label="姓名" prop="TRUENAME">
          <el-input  v-model="userItem.TRUENAME" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item label="所属单位" prop="DEPARTMENT">
          <el-input v-model="userItem.DEPARTMENT " autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item label="行政区" prop="XZQCODE">
             <el-select v-model="userItem.XZQCODE" placeholder="选择行政区"  style="width:360px">
                <el-option v-for="item in dOptions" :key="item.CODE" :label="item.NAME" :value="item.CODE"></el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="userItem.SEX" label="1">男</el-radio>
          <el-radio v-model="userItem.SEX" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="电话" prop="MOBILEPHONE">
          <el-input type="number" v-model="userItem.MOBILEPHONE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="EMAIL">
          <el-input  v-model="userItem.EMAIL" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item label="是否可用">
          <el-radio v-model="userItem.ISENABLE" label="1">可用</el-radio>
          <el-radio v-model="userItem.ISENABLE" label="0">不可用</el-radio>
        </el-form-item>
        <el-form-item label="备注" prop="BZ">
          <el-input  v-model="userItem.BZ" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitUserForm()">提交</el-button>
          <el-button @click="changeUserModal=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="人员审核" :visible.sync="passUserModal">
      <el-form :model="passItem" status-icon  ref="passForm" label-width="100px" class="demo-passForm">
        <!-- <el-form-item label="赋予角色">
            <el-select v-model="passItem.ROLEGUID" placeholder="请选择角色">
              <el-option label="管理员" value="1"></el-option>
              <el-option label="内业人员" value="2"></el-option>
              <el-option label="外业调查人员" value="3"></el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="审核意见" >
          <el-input  v-model="passItem.OPINION" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="passAfter()">提交</el-button>
          <el-button @click=" passUserModal=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "TaskIssued",
  data() {
    var uname = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入姓名'));
      } else {
        callback();
      }
    };
    var utel = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var uemail = (rule, value, callback) => {
      if (value === "" || value == null) {
         callback();
      } else {
        if(!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)){
          callback(new Error('请输入正确的邮箱'));
        }else {
          callback();
        }
      }
    };
    var XZQCODE= (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择一个行政区"));
      } else {
        callback();
      }
    };
    var bumen = (rule, value, callback) => {
       if (value === '' || value == null) {
        callback(new Error('请输入所属单位'));
      } else {
        callback();
      }
    };
    return {
      passUserModal:false,
      changeUserModal:false,
      type: "1",
      options: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "已审核",
        },
      ],
      dshArr:[],
      dsh: [],
      dshPageIndex:1,
      dshPageSize:10,
      dshTotal:0,
      passItem:{
        guid:"",
        opinion:"",
        roleguid:"1",
        pass:""
      },

      dlm:"",
      name: "",
      tel:"",
      role:"",
      roles: [
        {
          value: "",
          label: "全部角色",
        },
        {
          value: "1",
          label: "管理员",
        },
        {
          value: "2",
          label: "内业人员",
        },
        {
          value: "3",
          label: "外业调查人员",
        },
      ],
      yshArr:[],
      ysh:[],
      yshPageIndex:1,
      yshPageSize:10,
      yshTotal:0,
      userItem:{
        GUID:"",
        TRUENAME:"",
        SEX:"1",
        MOBILEPHONE:"",
        EMAIL:"",
        BZ:"",
        XZQCODE:"",
        DEPARTMENT:"",
        ISENABLE:""
      },
      userrules: {
        TRUENAME: [
          { validator: uname, trigger: 'blur' }
        ],
        MOBILEPHONE: [
          { validator: utel, trigger: 'blur' }
        ],
        EMAIL: [
          { validator: uemail, trigger: 'blur' }
        ],
        DEPARTMENT:[
           { validator: bumen, trigger: 'blur' }
        ],
        XZQCODE:[
          { validator: XZQCODE, trigger: "blur" }
        ],
      },
      dOptions:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.getDistrictList()
    this.getDSHList()
    this.ysh=this.yshArr
    this.getYSHList()
  },
  methods: {
      getDistrictList(){
        this.$http.post("Login/GetDistrictList", {})
        .then((response) => {
          //console.log(response);
          if (response.data.SUCCESS) {
             var d=response.data.DATA
             if(d.length>0){
               this.dOptions=d
             }
          } 
      })
    },
    getDSHListA(){
      this.dshPageIndex=1
      this.getDSHList()
    },
    //待审核
    getDSHList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.dshPageIndex,
        PAGESIZE:this.dshPageSize,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetWFList", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              var list=response.data.DATA
              var nl=[]
              for(var i=0;i<list.length;i++){
                var li=list[i]
                if(li.DEVICERESPONSE == null){
                  li.DEVICERESPONSE={DEVICENO:"/",TYPE:"/"}
                }
                 nl.push(li)
              }
              this.dsh=nl
              this.dshTotal=response.data.TOTALCOUNT
          } else {
              this.dsh=[]
              this.dshTotal=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.dsh=[]
          this.dshTotal=0
      });
    },
    dshPGChange(e){
      this.dshPageIndex=e
      this.getDSHList()
    },
    userPass(e){
      this.passItem={
          GUID:e.GUID,
          STATUS:"2",
          EXECUTORGUID: this.$store.state.guid,
          OPINION:"",
      } 
      this.passUserModal=true
    },
    userUNPass(e){
        this.passItem={
            GUID:e.GUID,
            STATUS:"3",
            EXECUTORGUID: this.$store.state.guid,
            OPINION:"",
        }
        this.$confirm('确定不通过审核?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          this.passAfter()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    passAfter(){
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.passItem, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.passItem)).toUpperCase())
      this.$http.post("Manager/AuditUser", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if(response.data.SUCCESS){   
              var d=[]
              for(var i=0;i<this.dsh.length;i++){
                if(this.dsh[i].GUID != this.passItem.GUID){
                  d.push(this.dsh[i])
                }
              }
              this.dsh=d
              this.$message.success("已通过")
              this.passUserModal=false
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.passUserModal=false
      });      
    },

    //已审核
    getUserList(){
      this.yshPageIndex=1
      this.yshTotal=0
      this.getYSHList()
      
    },
    getYSHList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.yshPageIndex,
        PAGESIZE:this.yshPageSize,
        TRUENAME:this.name,
        MOBILEPHONE:this.tel,
        ROLEGUID:this.role,
        LOGINNAME:this.dlm,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetUserList", md5_data)
        .then((response) => {
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.ysh=response.data.DATA
              this.yshTotal=response.data.TOTALCOUNT
          } else {
              this.ysh=[]
              this.yshTotal=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
         this.hideLoading()
          this.ysh=[]
          this.yshTotal=0
      });
    },
    yshPGChange(e){
      this.yshPageIndex=e
      this.getYSHList()
    },
    editUser(e){
      this.showLoading("请稍后")
      var params={
        GUID:e.GUID
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/GetUserById", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          if(response.data.SUCCESS){   
            this.userItem=response.data.DATA
            this.changeUserModal=true
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
          
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
      });
    },
    //修改资料
    submitUserForm() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.sendEdit()
        } else {
         
          return false;
        }
      });
    },
    sendEdit(){
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.userItem, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.userItem)).toUpperCase())
      this.$http.post("Manager/EditUser", md5_data)
        .then((response) => {
          console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
              var y=this.ysh
              var l=[]
              for(var i=0;i<y.length;i++){
                var d=y[i]
                if(d.GUID==this.userItem.GUID){
                    d.TRUENAME=this.userItem.TRUENAME
                    d.SEX=this.userItem.SEX
                    d.MOBILEPHONE=this.userItem.MOBILEPHONE
                    d.EMAIL=this.userItem.EMAIL
                    d.BZ=this.userItem.BZ
                }
                l.push(d)
              }
              this.$message.success("修改成功")
              this.changeUserModal=false
              this.getYSHList()

          } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.ysh=[]
          this.yshTotal=0
      });
    }
  },
  computed: {},
  watch: {
    // type(oldValue,newValue){
    // }
  },
};
</script>
<style scoped>

</style>
