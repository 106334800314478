<template>
  <div id="app" class="appMain">
    <div class="appHead" v-if="showNav">
        <div class="logoBox">
            <img src="statics/img/logo.png" alt="">
        </div>
        <div class="appLeftInner">
            <el-menu :default-active="defActive" class="el-menu-vertical-demo app-menu"    text-color="#333" active-text-color="#409EFF" router v-if="loadpage" mode="horizontal">
              <el-menu-item index="/TaskIssued" v-if="!isAdmin">
                <span slot="title">任务下发</span>
              </el-menu-item>
              <el-menu-item index="/JobAudit" v-if="!isAdmin">
                <span slot="title">列表审核</span>
              </el-menu-item>
              <el-menu-item index="/CaseAudit" v-if="!isAdmin">
                <span slot="title">综合审核</span>
              </el-menu-item>
              <el-menu-item index="/VillageTaskIssued" v-if="!isAdmin">
                <span slot="title">详情审核</span>
              </el-menu-item>
              <el-menu-item index="/UserManage" v-if="isAdmin">
                <span slot="title">人员管理</span>
              </el-menu-item>          
              <el-menu-item index="/DeviceManage" v-if="isAdmin">
                <span slot="title">设备管理</span>
              </el-menu-item>
              <el-menu-item index="/BoroughsList" v-if="isAdmin">
                <span slot="title">行政区管理</span>
              </el-menu-item>
              <el-menu-item index="/VillageTaskCount" v-if="isAdmin">
                <span slot="title">村级调查对象</span>
              </el-menu-item>
              <el-menu-item index="/TaskCount">
                <span slot="title">任务统计</span>
              </el-menu-item>
            </el-menu>
        </div>
        <!-- <div class="headCon" >
           <el-button type="primary" icon="el-icon-download">下载二维码</el-button>
        </div> -->
        <div class="loginInfo">
            <img :src="headImg" class="headImg">
            <div class="ddBox">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item @click.native="changeUserModal = true">修改资料</el-dropdown-item> -->
                    <el-dropdown-item @click.native="changePwdModal = true">修改密码</el-dropdown-item>
                    <el-dropdown-item @click.native="logoutModal = true">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
            </div>
            
        </div>
    </div>
    <div class="appRight" :class="showNav?'':'maxpage'">
        <router-view></router-view>
    </div>
    <el-dialog title="操作提示" :visible.sync="logoutModal" width="30%">
      <span>确定退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutModal = false">取 消</el-button>
        <el-button type="primary" @click="logoutSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdModal">
      <el-form :model="changePwd" status-icon :rules="pwdrules" ref="cpForm" label-width="100px" class="demo-cpForm">
        <el-form-item label="原密码" prop="oldPass">
          <el-input type="password" v-model="changePwd.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="changePwd.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="changePwd.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPwdForm('cpForm')">提交</el-button>
          <el-button @click="resetPwdForm('cpForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改资料" :visible.sync="changeUserModal">
      <el-form :model="changeUser" status-icon :rules="userrules" ref="userForm" label-width="100px" class="demo-userForm">
        <el-form-item label="姓名" prop="TRUENAME">
          <el-input  v-model="changeUser.TRUENAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="changeUser.SEX" label="1">男</el-radio>
          <el-radio v-model="changeUser.SEX" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="电话" prop="MOBILEPHONE">
          <el-input type="number" v-model="changeUser.MOBILEPHONE" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item label="邮箱" prop="EMAIL">
          <el-input  v-model="changeUser.EMAIL" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitUserForm('cpForm')">提交</el-button>
          <el-button @click="resetUserForm('cpForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.changePwd.checkPass !== '') {
          this.$refs.cpForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwd.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var uname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入姓名'));
      } else {
        callback();
      }
    };
    var utel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var uemail = (rule, value, callback) => {
      if (value === '') {
         callback();
      } else if(!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)){
        callback(new Error('请输入正确的邮箱'));
      }else {
        callback();
      }
    };
    return {
      loadpage:false,
      defActive:"",
      userName:"",
      isAdmin:false,
      showNav:true,
      headImg:"statics/img/headImg.png",
      logoutModal:false,
      changePwdModal:false,
      changePwd: {
          oldPass:'',
          newPass: '',
          checkPass: '',
      },
      pwdrules: {
        oldPass: [
          { validator: validatePass0, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      changeUserModal:false,
      changeUser:{
        TRUENAME:'',
        SEX:'1',
        MOBILEPHONE:'',
        EMAIL:'',
      },
      userrules: {
        TRUENAME: [
          { validator: uname, trigger: 'blur' }
        ],
        MOBILEPHONE: [
          { validator: utel, trigger: 'blur' }
        ],
        EMAIL: [
          { validator: uemail, trigger: 'blur' }
        ],
      },
      
    };
  },
  mounted() {
    this.loadItem=null
    this.isLoginPage(this.$route.name)
  },
  methods: {
    isLoginPage(name){
      this.userName=this.$store.state.name
      var  type=this.$store.state.type
      if(type!=""){
        if(type=="1" && name!="UserManage" && name!="DeviceManage" && name!="BoroughsList" && name!="VillageTaskCount" && name!="TaskCount" ){
          this.$router.replace("/UserManage") 
        }else if(type=="2" && name!="TaskIssued" && name!="JobAudit" && name!="CaseAudit" && name!="VillageTaskIssued" && name!="TaskCount"){
            this.$router.replace("/TaskIssued")
        }
      }else{
        this.logout()
      }
      if(name =="TaskCount"){
          if(type=="1"){
             this.isAdmin=true
          }else if(type=="2"){
            this.isAdmin=false
          }
          this.defActive='/'+name
      }else{
          if(name=="UserManage" || name=="DeviceManage" || name=="BoroughsList" || name=="VillageTaskCount"){
            this.isAdmin=true
            this.defActive='/'+name
          }else{
            this.isAdmin=false
            this.defActive='/'+name
          }
      }
     
      this.loadpage=true
    },
    //退出登录
    logoutSend(){
      this.logoutModal=false
      this.logout()
    },
    //修改密码
    submitPwdForm(formName) {
        this.$refs.cpForm.validate((valid) => {
          if (valid) {
            //console.log(this.changePwd)
            var params={
              LOGINNAME:this.$store.state.loginName,
              PASSWORD:this.changePwd.oldPass,
              NEWPASSWORD:this.changePwd.newPass
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Manager/ModifyPass", md5_data)
              .then((response) => {
                //console.log(response)
                if (response.data.SUCCESS) {
                    // this.$cookies.set("user_pwd",this.changePwd.newPass)
                    this.$message.success("修改成功");
                    this.changePwdModal=false
                    this.$refs.cpForm.resetFields();
                } else {
                  this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
              })
              .catch((error) => {
                this.canLogin=true
                this.fullscreenLoading = false
                this.$message.error("登录失败，请稍后再试");
            });
            } else {
              return false;
            }
        });
      },
    resetPwdForm(formName) {
      this.$refs.cpForm.resetFields();
    },
    //修改资料
    submitUserForm(formName) {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    resetUserForm(formName) {
      this.$refs.userForm.resetFields();
    }
  },
  computed: {
    // allAge(){
    //     return this.userA.age + this.userB.age
    // }
  },
  watch: {
    '$route.name': function (newVal, oldVal) {
      this.isLoginPage(newVal)
    }
  },
};
</script>

<style>
.appMain{width: 100%;height: 100%;position: relative;overflow: hidden;}
.appLeftInner{width: 600px;margin: 0 auto; height: 56px;overflow: hidden;margin-top: -2px;}
.el-menu.el-menu--horizontal{border-bottom: 0!important;}
.appHead{height: 56px;position: absolute;left: 0px;top: 0px;right: 0px;background-color: #FFFFFF;}
.appRight{position: absolute;left: 0px;bottom: 0px;right: 0px;top: 57px;overflow: auto;background-color: #F8F8F8;border-radius: 4px;padding: 16px;}
/* .app-menu .is-active{background-color: #F8F8F8!important;}
.app-menu .app-menu-icon{color: #FFFFFF;} */
.logoBox{height: 56px;width: 240px;float:left;background-color: #409EFF;overflow: hidden;}
.logoBox img{width: 165px;display: block;float:left;margin-top: 9px;margin-left: 16px;}

.loginInfo{overflow: hidden;height: 56px;position: absolute;right: 20px;top: 0;}
.loginInfo .headImg{width: 36px;float: left;margin-top: 10px;}
.appHead .headCon{height: 56px;float:left;overflow: hidden;}
.appHead .headCon .btnItem{float:left;margin-right: 20px;}
.loginInfo .ddBox{float:left;margin-left: 16px;cursor: pointer;margin-top: 2px;}
.appMain .maxpage{left: 0;right: 0;top: 0;bottom: 0;overflow: hidden;padding: 0;}
.el-menu{border-right: 0!important;}
/* .el-menu-item:hover{background-color: #FFFFFF!important;} */
</style>
