<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">行政区管理</p>
    </div>
    <div class="tableBox" style="top:50px;left:16px;right:16px">
      <div class="views-main-search">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
        <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
        <el-button type="success" @click="addSheng" class="ml10">添加省</el-button>
      </div>
      <div class="views-main-body">
          <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid"  :filter-node-method="filterNode">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="treeName">{{ node.label }}</span>
              <span>
                <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b" v-if="node.level<5">
                  添加
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                  编辑
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                  删除
                </el-button>
                <!-- <el-button type="text" size="mini" @click.stop="() => exportSHP(node, data)" style="color:#409EFF" v-if="node.level==3">
                  导出房屋图层
                </el-button> -->
              </span>
            </span>
          </el-tree>
      </div>
    </div>
    <el-dialog :title="xzqModalTitle" :visible.sync="xzqModal" width="500px">
      <el-form :model="xzqInfo" status-icon :rules="xzqRules"  ref="xzqForm" label-width="110px" class="demo-xzqForm" >
        <el-form-item label="行政区编码" prop="CODE">
          <el-input v-model="xzqInfo.CODE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="行政区名称" prop="NAME">
          <el-input v-model="xzqInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="EPSG">
          <el-input v-model="xzqInfo.EPSG" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="EPSGVALUE">
          <el-input v-model="xzqInfo.EPSGVALUE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="EPSGEXTENT">
          <el-input v-model="xzqInfo.EPSGEXTENT" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item label="WKT">
          <el-input v-model="xzqInfo.WKT" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitXZQForm()">提交</el-button>
          <el-button @click="closeXZQForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="exportDownBox" v-if="showExportDownIcon">
        <!-- <i class="el-icon-circle-close closeDown" @click="closeExportLoading"></i> -->
        <div class="exportMsg" v-if="exportIng">
            <i class="el-icon-loading exportLoadingIcon"></i>
            <span class="exportLoadingTxt" style="color:#409EFF">正在下载,请稍后...</span>
        </div>
        <div class="exportMsg" v-else>
            <i class="el-icon-check exportLoadingIcon" v-if="exportOk" style="color:#67C23A" @click="toDownExport"></i>
            <span class="exportLoadingTxt" style="color:#67C23A" v-if="exportOk"  @click="toDownExport">下载完成，点击下载</span>
            <i class="el-icon-close exportLoadingIcon" v-if="!exportOk" style="color:#F56C6C" @click="refDownExport"></i>
            <span class="exportLoadingTxt" style="color:#F56C6C" v-if="!exportOk"  @click="refDownExport">下载失败，重新下载</span>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DeviceManage",
  data() {
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入行政区编码"));
      } else {
        callback();
      }
    };
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入行政区名称"));
      } else {
        callback();
      }
    };
    return {
      props: {
          label: 'name',
          children: 'zones',
          isLeaf: 'leaf'
      },
      filterText:"",

      xzqModalType:"",
      xzqModalTitle:"",
      xzqParent:"",
      xzqModal:false,
      xzqInfo:{
        TYPE:"",
        GUID:"",
        CODE:"",
        NAME:"",
        PARENTGUID:"",
        EPSG:"",
        EPSGVALUE:"",
        EPSGEXTENT:"",
        WKT:"",
      },
      xzqRules: {
        CODE: [{ validator: CODE, trigger: "blur" }],
        NAME: [{ validator: NAME, trigger: "blur" }],
      },
      xzqEditItem:null,
      canDo:true,
      auditText:"",



           
      showExportDownIcon:false,
      exportUrl:"",
      exportOk:true,
      exportIng:false,
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    getList(node, resolve){
       //console.log(node)
        var guid=""
        var hasChild=true
        if(node.data != undefined){
            guid = node.data.guid
        }
        if(hasChild){
          setTimeout(()=>{
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var l=response.data.DATA
                  var d=[]
                  for(var i=0;i<l.length;i++){
                    var  xzqname="未命名"
                    if(l[i].NAME && l[i].NAME!=""){
                      xzqname=l[i].NAME
                    }
                    d.push({
                        type:l[i].TYPE,
                        guid:l[i].GUID,
                        name: xzqname,
                        code:l[i].CODE,
                        childCount:l[i].CHILDCOUNT,
                        epsg:l[i].EPSG,
                        epsgValue:l[i].EPSGVALUE,
                        epsgExtent:l[i].EPSGEXTENT,
                        wkt:l[i].WKT,
                        leaf: false,
                    })
                    
                  }
                  resolve(d);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          },500)
            
        }

    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    closeXZQForm(){
      this.xzqModal=false
    },
    submitXZQForm(){
      if (this.canDo) {
          this.$refs.xzqForm.validate((valid) => {
          if (valid) {
              if(this.xzqModalType=="1"){
                this.appendXZQ()
              }else if(this.xzqModalType=="2"){
                this.editXZQ()  
              }
          } else {
            return false;
          }
        });
      }
    },
    appendXZQ(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.xzqInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.xzqInfo)).toUpperCase())
      this.$http.post("Manager/InsertXzq", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          this.canDo=true
          this.xzqModal=false
          if(response.data.SUCCESS){   
            //var type=parseInt(this.xzqInfo.TYPE)+1
            var data={
                type: this.xzqInfo.TYPE,
                guid:response.data.DATA,
                name: this.xzqInfo.NAME,
                code:this.xzqInfo.CODE,
                childCount:0,
                epsg:this.xzqInfo.EPSG,
                epsgValue:this.xzqInfo.EPSGVALUE,
                wkt:this.xzqInfo.WKT,
                leaf: false,
            }
            this.$refs.tree.append(data,this.xzqParent) 
            this.$message.success("添加成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    editXZQ(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.xzqInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.xzqInfo)).toUpperCase())
      this.$http.post("Manager/EditXzq", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          this.canDo=true
          this.xzqModal=false
          if(response.data.SUCCESS){   
            this.$set(this.xzqEditItem,'type',this.xzqInfo.TYPE)
            this.$set(this.xzqEditItem,'name',this.xzqInfo.NAME)
            this.$set(this.xzqEditItem,'code',this.xzqInfo.CODE)
            this.$set(this.xzqEditItem,'epsg',this.xzqInfo.EPSG)
            this.$set(this.xzqEditItem,'epsgValue',this.xzqInfo.EPSGVALUE)
            this.$set(this.xzqEditItem,'epsgExtent',this.xzqInfo.EPSGEXTENT)
            this.$set(this.xzqEditItem,'wkt',this.xzqInfo.WKT)
            this.$message.success("编辑成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    addSheng(){
      this.xzqInfo={
        TYPE:"1",
        GUID:"",
        CODE:"",
        NAME:"",
        PARENTGUID:"",
        EPSG:"",
        EPSGVALUE:"",
        WKT:"",
      },
      this.xzqModalType="1"
      this.xzqParent=""
      this.xzqModalTitle="添加行政区"
      this.xzqModal=true
    },
    append(node, data){
      //console.log(data)
      var type=parseInt(data.type)+1
      this.xzqInfo={
        TYPE:type.toString(),
        GUID:"",
        CODE:"",
        NAME:"",
        PARENTGUID:data.guid,
        EPSG:"",
        EPSGVALUE:"",
        EPSGEXTENT:"",
        WKT:"",
      },
     // console.log(this.xzqInfo)
      this.xzqModalType="1"
      this.xzqParent=data.guid
      this.xzqModalTitle="添加行政区"
      this.xzqModal=true
    },
    edit(node, data){
       this.xzqInfo={
        TYPE:data.type,
        GUID:data.guid,
        CODE:data.code,
        NAME:data.name,
        PARENTGUID:"",
        EPSG:data.epsg,
        EPSGVALUE:data.epsgValue,
        EPSGEXTENT:data.epsgExtent,
        WKT:data.wkt,
      },
      this.xzqEditItem=data
      this.xzqModalType="2"
      this.xzqParent=""
      this.xzqModalTitle="编辑行政区"
      this.xzqModal=true

    },
    remove(node, data){
      if(this.canDo){
        this.$confirm('确定删除'+data.name+'及其全部的子行政区？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delXZQ(data)
          }).catch(() => {
            this.$message.info("已取消")    
          });
      }
    },
    delXZQ(data){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt({GUID:data.guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:data.guid})).toUpperCase())
      this.$http.post("Manager/DeleteXzq", md5_data)
        .then((response) => {
          console.log(response)
          this.hideLoading()
          this.canDo=true
          if(response.data.SUCCESS){   
             this.$refs.tree.remove(data)
            this.$message.success("删除成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    exportSHP(node, data){
      if(this.exportIng){
        this.$message.error("您正在下载数据，请等待本次下载完成！")
      }else{
        this.sendExportSHP(data.code)
      }
    },
    sendExportSHP(code){
        this.exportIng=true
        this.showExportDownIcon=true
        this.exportOk=false
        this.exportUrl=""
        var d={
          CODE:code,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Manager/ExportShp", md5_data)
          .then((response) => {
            if(response.data.SUCCESS){
                this.exportUrl=response.data.DATA
                this.showExportDownIcon=true
                this.exportOk=true
            } else{
              this.exportOk=false   
            }
            this.exportIng=false
          })
          .catch((error) => {
            this.exportOk=false   
            this.exportIng=false
            this.$message.error("请求出错，请稍后再试！")
          }); 
          this.$confirm('后台正在下载数据，请勿刷新页面\r\n您可以在界面左下角查看下载情况', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
    },
    closeExportLoading(){
      this.showExportDownIcon=false
    },
    toDownExport(){
      if(this.exportUrl==""){
         this.$confirm('下载地址异常，请刚重新下载！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
      }else{
        window.open(this.exportUrl);
      }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
  .el-tree-node{margin-top: 5px;}
  .treeName{font-size: 16px;margin-right: 20px;}
  .custom-tree-node{display: block;margin-bottom: 11px;}
</style>
