<template>
  <div class="content">
       <MAP ref="map" class="mapBox" :move="true" :draw="true"></MAP>
       <div class="formBox">
          <div class="views-main-title">
            <p class="txt">{{txt}}</p>
          </div>
          <div class="formCon">
              <el-form :model="fromData" status-icon  :rules="fromrules" ref="form" label-width="100px" class="formMain" label-position="top" v-if="isLost=='0'">
                                        
                    <el-form-item label="照片"  class="formItem formItem1">
                        <div class="imgBox" v-if="photo.length>0">
                            <div class="imgBoxItem" v-for="(item,i) in photo"  v-bind:key="i">
                                <el-image   :src="item.FILE_PATH"  :preview-src-list="photos" class="pp" ></el-image>
                                 <i class="el-icon-delete pd" @click="delPhoto(item.ID,'1')" v-if="taskType=='2'"></i>
                                <p class="pt">
                                  方位角：
                                  <span>{{item.DIRECTION}}({{item.str}})</span>
                                </p>
                                <p class="pt">经度：{{item.LON}}</p>
                                <p class="pt">纬度：{{item.LAT}}</p>
                                <p class="pt">拍摄时间：{{item.PHOTOTIME}}</p>
                                <p class="pt">ID：{{item.ID}}</p>
                            </div>
                        </div>
                        <div v-else>无</div>
                    </el-form-item> 


                <el-form-item label="单位/小区名称"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.MC" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="建筑地址_路（街、巷）"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.LU_QG" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="建筑地址_号"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.HAO_QG" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="建筑地址_栋"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.DONG_QG" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="建筑名称"  class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.JZMC" :readonly="readonly"></el-input>
                </el-form-item>

                
                <el-form-item label="地上层数"  prop="DSCS" class="formItem formItem2">
                  <el-input  type="number" autocomplete="off" v-model="fromData.DSCS" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="地下层数"  prop="DXCS"  class="formItem formItem2">
                  <el-input   type="number" autocomplete="off" v-model="fromData.DXCS" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="调查面积" prop="DCMJ" class="formItem formItem2">
                  <el-input  type="number"   autocomplete="off" v-model="fromData.DCMJ" :readonly="readonly"></el-input>
                </el-form-item>
                <el-form-item label="建筑高度" prop="GD"  class="formItem formItem2">
                  <el-input  type="number" autocomplete="off" v-model="fromData.GD" :readonly="readonly"></el-input>
                </el-form-item>

                   <!--城镇住宅-->
                    <el-form-item label="结构类型"  class="formItem formItem2" v-if="fromData.FWLB=='0110'"> 
                      <el-select  placeholder="请选择结构类型" v-model="fromData.CZFWJGLX" :disabled="readonly">
                        <el-option label="砌体结构" value="1"></el-option>
                        <el-option label="钢筋混凝土结构" value="4"></el-option>
                        <el-option label="钢结构" value="5"></el-option>
                        <el-option label="木结构" value="6"></el-option>
                        <el-option label="其他" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--非城镇住宅-->
                    <el-form-item label="结构类型"  class="formItem formItem2" v-if="fromData.FWLB=='0120'">
                      <el-select  placeholder="请选择结构类型" v-model="fromData.CZFWJGLX" :disabled="readonly">
                        <el-option label="砌体结构" value="1001"></el-option>
                        <el-option label="钢筋混凝土结构" value="2003"></el-option>
                        <el-option label="钢结构" value="4"></el-option>
                        <el-option label="木结构" value="5"></el-option>
                        <el-option label="其他" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--结构类型选择其他时必填-->
                    <el-form-item label="其他结构类型"  prop="QTJGLX" class="formItem formItem2" v-if="qtjglx" >
                      <el-input   autocomplete="off" v-model="fromData.QTJGLX" :readonly="readonly"></el-input>
                    </el-form-item>
                    <!--城镇房屋二级结构类型 当结构类型选择砌体结构、钢筋混凝土时，则必填-->
                    <el-form-item label="二级结构类型"  prop="SUB_JGLXA" class="formItem formItem2" v-if="ejjglxA">
                      <el-select  placeholder="请选择二级结构类型" v-model="fromData.SUB_JGLX" :disabled="readonly">
                        <el-option label="底部框架-抗震墙结构砌体结构" value="3"></el-option>
                        <el-option label="其他" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="二级结构类型"  prop="SUB_JGLXB" class="formItem formItem2" v-if="ejjglxB">
                      <el-select  placeholder="请选择二级结构类型" v-model="fromData.SUB_JGLX" :disabled="readonly">
                        <el-option label="底部框架-抗震墙结构" value="1"></el-option>
                        <el-option label="内框架结构" value="2"></el-option>
                        <el-option label="其他" value="3"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="二级结构类型" prop="SUB_JGLXC" class="formItem formItem2" v-if="ejjglxC">
                      <el-select  placeholder="请选择二级结构类型" v-model="fromData.SUB_JGLX" :disabled="readonly">
                        <el-option label="单跨框架" value="1"></el-option>
                        <el-option label="非单跨框架" value="2"></el-option>
                      </el-select>
                    </el-form-item>

                                    <!--如果加固必填-->
                    <el-form-item label="抗震加固时间" prop="JGSJ" class="formItem formItem2" v-if="fromData.SFKZJG=='1'">
                      <el-input   autocomplete="off" placeholder="请输入年份(格式:YYYY)多次加固用逗号分隔" v-model="fromData.JGSJ" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label="抗震构造措施照片"  class="formItem formItem1" >
                        <div class="imgBox" v-if="kzPhoto.length>0">
                            <div class="imgBoxItem" v-for="(item,i) in kzPhoto"  v-bind:key="i">
                                <el-image   :src="item.FILE_PATH"  :preview-src-list="kzPhotos" class="pp" ></el-image>
                                 <i class="el-icon-delete pd" @click="delPhoto(item.ID,'3')" v-if="taskType=='2'"></i>
                                <p class="pt">
                                  方位角：
                                 <span>{{item.DIRECTION}}({{item.str}})</span>
                                </p>
                                <p class="pt">经度：{{item.LON}}</p>
                                <p class="pt">纬度：{{item.LAT}}</p>
                                <p class="pt">拍摄时间：{{item.PHOTOTIME}}</p>
                                <p class="pt">ID：{{item.ID}}</p>
                            </div>
                        </div>
                        <div v-else>
                          无
                        </div>
                    </el-form-item>
                    <el-form-item label=""  class="formItem formItem1"></el-form-item>
                    <el-form-item label="有无肉眼可见明显裂缝、变形、倾斜"  class="formItem formItem1">
                      <el-select  placeholder="请选择有无肉眼可见明显裂缝、变形、倾斜" v-model="fromData.YWLFBXQX" :disabled="readonly">
                        <el-option label="无" value="0"></el-option>
                        <el-option label="有" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--如果有则显示-->
                    <el-form-item label="裂缝、变形、倾斜照片"  class="formItem formItem1" >
                        <div class="imgBox" v-if="lfPhoto.length>0">
                            <div class="imgBoxItem" v-for="(item,i) in lfPhoto"  v-bind:key="i">
                                <el-image   :src="item.FILE_PATH"  :preview-src-list="lfPhotos" class="pp" ></el-image>
                                 <i class="el-icon-delete pd" @click="delPhoto(item.ID,'4')" v-if="taskType=='2'"></i>
                                <p class="pt">
                                  方位角：
                                  <span>{{item.DIRECTION}}({{item.str}})</span>
                                </p>
                                <p class="pt">经度：{{item.LON}}</p>
                                <p class="pt">纬度：{{item.LAT}}</p>
                                <p class="pt">拍摄时间：{{item.PHOTOTIME}}</p>
                                 <p class="pt">ID：{{item.ID}}</p>
                            </div>
                        </div>
                        <div v-else>无</div>
                    </el-form-item>
                
                <el-form-item label="行政区选择"  class="formItem formItem1" v-if="showXZQSelect">
                  <el-cascader v-model="XZBM"  :props="xzqprops" style='width:100%'></el-cascader>
                </el-form-item>
                <el-form-item label="省行政编码"  prop="PROVINCE" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.PROVINCE"></el-input>
                </el-form-item>
                <el-form-item label="市行政编码"  prop="CITY" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.CITY"></el-input>
                </el-form-item>
                <el-form-item label="区县行政编码"  prop="DISTRICT"  class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.DISTRICT"></el-input>
                </el-form-item>
                <el-form-item label="镇街行政编码" prop="TOWN" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.TOWN"></el-input>
                </el-form-item>
                <el-form-item label="村居行政编码"  prop="VILLAGE" class="formItem formItem2">
                  <el-input   autocomplete="off" :readonly="true" v-model="fromData.VILLAGE"></el-input>
                </el-form-item>
                <el-form-item label="　"  class="formItem formItem2">
                  <el-button type="primary" @click="changeXZQ" class="fl ml10" style="width:100px;float:right" v-if="taskType=='1'">修改</el-button>
                  <el-input   autocomplete="off" :readonly="true" v-else style="visibility:hidden"></el-input>
                </el-form-item>
                
                <el-form-item label="房屋编号"  prop="FWBH" class="formItem formItem2">
                  <el-input   autocomplete="off" v-model="fromData.FWBH" :readonly="readonly"></el-input>
                </el-form-item>
                 <el-form-item label="房屋类别"  prop="FWLB" class="formItem formItem2">
                    <el-select   placeholder="请选择房屋类别" v-model="fromData.FWLB" :disabled="readonly">
                      <el-option label="城镇住宅房屋" value="0110"></el-option>
                      <el-option label="城镇非住宅房屋" value="0120"></el-option>
                    </el-select>
                  </el-form-item>
                  <!--城镇住宅时必填-->
                  <el-form-item label="套数" prop="TAOSHU_QG" class="formItem formItem2">
                    <el-input  type="number" autocomplete="off" v-model="fromData.TAOSHU_QG" :readonly="readonly"></el-input>
                  </el-form-item>  

                     
                      
                      <!--精确到年-->
                      <el-form-item label="建成时间(年份)"  class="formItem formItem2">
                        <el-date-picker type="year" placeholder="选择年" v-model="fromData.BUILD_TIME" :readonly="readonly"></el-date-picker>
                      </el-form-item> 

                    <el-form-item label="现场调查情况"  class="formItem formItem2">
                       <el-input   autocomplete="off" v-model="fromData.XCDCQK" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label="不需调查原因"  class="formItem formItem2" >
                      <el-input   autocomplete="off" v-model="fromData.BXDCYY" :readonly="readonly"></el-input>
                    </el-form-item>
    

                    <el-form-item label="是否产权登记"  class="formItem formItem2">
                      <el-select  placeholder="请选择是否产权登记" v-model="fromData.SFCQDJ" :disabled="readonly">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="产权单位(产权人)"  class="formItem formItem2">
                      <el-input   autocomplete="off" v-model="fromData.CQDW" :readonly="readonly"></el-input>
                    </el-form-item>
        
                    <!--当为城镇非住宅时，必填-->
                    <el-form-item label="非住宅房屋用途" prop="FWYT" class="formItem formItem2" v-if="fromData.FWLB=='0120'">
                      <el-select  v-model="fromData.FWYT" placeholder="请选择非住宅房屋用途" :disabled="readonly">
                        <el-option label="中小学幼儿园教学楼宿舍楼等教育建筑" value="1001"></el-option>
                        <el-option label="其他学校建筑" value="2"></el-option>
                        <el-option label="医疗建筑" value="1003"></el-option>
                        <el-option label="福利院" value="1004"></el-option>
                        <el-option label="养老建筑" value="1005"></el-option>
                        <el-option label="疾控、消防等救灾建筑" value="1006"></el-option>
                        <el-option label="纪念建筑" value="17"></el-option>
                        <el-option label="宗教建筑" value="18"></el-option>
                        <el-option label="文化建筑" value="1009"></el-option>
                        <el-option label="综合建筑" value="19"></el-option>
                        <el-option label="商业建筑" value="1011"></el-option>
                        <el-option label="体育建筑" value="1012"></el-option>
                        <el-option label="通信电力交通邮电广播电视等基础设施建筑" value="1013"></el-option>
                        <el-option label="工业建筑" value="14"></el-option>
                        <el-option label="办公建筑" value="15"></el-option>
                        <el-option label="仓储建筑" value="16"></el-option>
                        <el-option label="其它" value="99999"></el-option>
                      </el-select>
                    </el-form-item>

                    <!--当房屋用途选择综合建筑、文化建筑、商业建筑、办公建筑时，则必填-->
                    <el-form-item label="城镇非住宅二级房屋用途" prop="FWYTA" class="formItem formItem2" v-if="czfzzrjfwtyA">
                      <el-select  placeholder="请选择城镇非住宅二级房屋用途" v-model="fromData.SUB_FWYT" :disabled="readonly">
                        <el-option label="住宅和商业综合" value="1"></el-option>
                        <el-option label="办公和商业综合" value="2"></el-option>
                        <el-option label="其它" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="城镇非住宅二级房屋用途" prop="FWYTB" class="formItem formItem2" v-if="czfzzrjfwtyB">
                      <el-select  placeholder="请选择城镇非住宅二级房屋用途" v-model="fromData.SUB_FWYT" :disabled="readonly">
                        <el-option label="剧院电影院音乐厅礼堂" value="1"></el-option>
                        <el-option label="图书馆文化馆" value="2"></el-option>
                        <el-option label="博物馆展览馆" value="3"></el-option>
                        <el-option label="档案馆" value="4"></el-option>
                        <el-option label="其它" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="城镇非住宅二级房屋用途"  prop="FWYTC" class="formItem formItem2" v-if="czfzzrjfwtyC">
                      <el-select  placeholder="请选择城镇非住宅二级房屋用途" v-model="fromData.SUB_FWYT" :disabled="readonly">
                        <el-option label="金融（银行）建筑" value="7"></el-option>
                        <el-option label="商场建筑" value="1"></el-option>
                        <el-option label="酒店旅馆建筑" value="2"></el-option>
                        <el-option label="餐饮建筑" value="3"></el-option>
                        <el-option label="其它" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="城镇非住宅二级房屋用途" prop="FWYTD" class="formItem formItem2" v-if="czfzzrjfwtyD">
                      <el-select  placeholder="请选择城镇非住宅二级房屋用途" v-model="fromData.SUB_FWYT" :disabled="readonly">
                        <el-option label="科研实验楼" value="1"></el-option>
                        <el-option label="其它" value="99999"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--当房屋用途选择其他时，则必填-->
                    <el-form-item label="其他非住宅房屋用途" prop="FWYTE" class="formItem formItem2" v-if="czfzzrjfwtyE">
                      <el-input   autocomplete="off" v-model="fromData.QTFWYT" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label=""  class="formItem formItem1"></el-form-item>

                    <el-form-item label="是否采用减隔震"  class="formItem formItem2">
                      <el-select  placeholder="请选择是否采用减隔震" v-model="fromData.SFCYJGZ" :disabled="readonly">
                        <el-option label="减震" value="1"></el-option>
                        <el-option label="隔震" value="2"></el-option>
                        <el-option label="未采用" value="3"></el-option>
                        <el-option label="减震、隔震" value="4"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="是否保护性建筑"  class="formItem formItem2">
                      <el-select  placeholder="请选择是否保护性建筑" v-model="fromData.SFBHXJZ" :disabled="readonly">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="全国重点文物保护建筑" value="1"></el-option>
                        <el-option label="省级文物保护建筑" value="2"></el-option>
                        <el-option label="市县级文物保护建筑" value="3"></el-option>
                        <el-option label="历史建筑" value="4"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="是否专业设计建造"  class="formItem formItem2">
                      <el-select  placeholder="请选择是否专业设计建造" v-model="fromData.SFZYSJJZ" :disabled="readonly">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label=""  class="formItem formItem1"></el-form-item>
                    <el-form-item label="是否是否进行过改造"  class="formItem formItem2">
                      <el-select placeholder="请选择是否进行过改造" v-model="fromData.SFSZCG" :disabled="readonly">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--如果改造必填-->
                    <el-form-item label="改造时间"  prop="GZSJ" class="formItem formItem2" v-if="fromData.SFSZCG=='1'">
                      <el-input   autocomplete="off" placeholder="请输入年份(格式:YYYY)多次改造用逗号分隔" v-model="fromData.GZSJ" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label=""  class="formItem formItem1"></el-form-item>
                    <el-form-item label="是否是否进行过抗震加固"  class="formItem formItem2">
                      <el-select   placeholder="请选择是否进行过抗震加固" v-model="fromData.SFKZJG" :disabled="readonly">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                      </el-select>
                    </el-form-item>


                    <el-form-item label="有无物业管理"  class="formItem formItem2">
                      <el-select  placeholder="请选择有无物业管理" v-model="fromData.YWWYGL" :disabled="readonly">
                        <el-option label="无" value="0"></el-option>
                        <el-option label="有" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="调查人"  class="formItem formItem2">
                      <el-input   autocomplete="off" placeholder="请输入调查人" v-model="fromData.DCR" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话"  class="formItem formItem2">
                      <el-input type="number"  autocomplete="off" placeholder="请输入联系电话" v-model="fromData.LXDH" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label="调查人组织"  class="formItem formItem2">
                      <el-input   autocomplete="off" placeholder="请输入调查人组织" v-model="fromData.ORG_NAME" :readonly="readonly"></el-input>
                    </el-form-item>
                    <el-form-item label="调查时间"  class="formItem formItem2">
                      <el-date-picker  align="right" type="date" placeholder="选择日期" style="width:200px;" v-model="DCSJA" :readonly="readonly"></el-date-picker>
                      <el-time-picker placeholder="选择时间" style="width:200px;float:right;" v-model="DCSJB" :readonly="readonly"></el-time-picker>
                    </el-form-item>
                    <el-form-item label="调查状态"  class="formItem formItem2">
                      <el-select  placeholder="请选择调查状态" v-model="fromData.STATUS" :disabled="true">
                        <el-option label="调查中" value="1"></el-option>
                        <el-option label="已调查" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                <el-form-item label="备注"  class="formItem formItem1">
                  <el-input  type="textarea" autocomplete="off" placeholder="请输入备注" :rows="4" v-model="fromData.BZ" :readonly="readonly"></el-input>
                </el-form-item>
              </el-form>
              <div v-else class="isLostBox">
                  <p class="isLostTit">
                      调查对象数据灭失，只显示部分属性
                  </p>
                  <el-form :model="fromData" status-icon  :rules="fromrules" ref="form" label-width="100px" class="formMain" label-position="top">
                      <el-form-item label="调查人"  class="formItem formItem2">
                        <el-input   autocomplete="off"  v-model="fromData.DCR" readonly></el-input>
                      </el-form-item>
                      <el-form-item label="联系电话"  class="formItem formItem2">
                        <el-input type="number"  autocomplete="off"  v-model="fromData.LXDH" readonly></el-input>
                      </el-form-item>
                      <el-form-item label="调查人组织"  class="formItem formItem2">
                        <el-input   autocomplete="off" v-model="fromData.ORG_NAME" readonly></el-input>
                      </el-form-item>
                      <el-form-item label="调查时间"  class="formItem formItem2">
                        <el-date-picker  align="right" type="date"  style="width:200px;" v-model="DCSJA" readonly></el-date-picker>
                        <el-time-picker  style="width:200px;float:right;" v-model="DCSJB" readonly></el-time-picker>
                      </el-form-item>
                      <el-form-item label="备注"  class="formItem formItem1">
                        <el-input  type="textarea" autocomplete="off"  :rows="4" v-model="fromData.BZ" readonly></el-input>
                      </el-form-item>
                  </el-form>
              </div>
          </div>
          <div class="btnCon">
                <div class="btns">  
                  <el-button type="success" @click="toAudit" class="fl ml10"  v-if="taskType=='2'" size="mini">点击审核</el-button>
                  <div class="fl" style="overflow:hidden" v-if="taskType=='3'">
                        <p v-if="passStatus=='3'" style="color:#67C23A;font-size:15px;" class="fl ml10">通过</p>
                        <p v-if="passStatus=='4'" style="color:#F56C6C;font-size:15px;" class="fl ml10">重新调查</p>
                        <el-button type="primary" @click="auditModal=true" class="fl ml10"  size="mini">审核明细</el-button>
                  </div>  
                  <div class="fl" v-if="taskType=='1' || taskType=='4'">
                      <el-button type="danger" @click="delPolgon" class="fl ml10" size="mini" v-if="hasPolygon">重新绘制</el-button>
                      <el-button type="success" @click="drawPolgon" class="fl ml10" size="mini">绘制作业区域</el-button>
                      <el-button type="primary" @click="planPolgon" class="fl ml10" size="mini" v-if="hasPolygon">定位</el-button>
                      <!-- <el-button type="danger" @click="addFeatureFromTask" class="fl ml10" style="width:100px;">手动添加</el-button> -->
                  </div>
                  <el-button type="info" @click="closeForm" class="fr ml10" size="mini">关闭</el-button>
                  <el-button type="primary" @click="submitForm" class="fr ml10" size="mini" v-if="taskType=='1' || taskType=='4'">提交</el-button>
              </div>
          </div>
          <!-- <div class="btnCon">
              <div class="btns">  
                  <el-button type="success" @click="pass" class="fl ml10"  v-if="taskType=='2'" size="mini">通过</el-button>
                  <el-button type="danger" @click="unpass" class="fl ml10"  v-if="taskType=='2'" size="mini">不通过</el-button>
                  <div class="fl" style="overflow:hidden" v-if="taskType=='3'">
                        <p v-if="passStatus=='3'" style="color:#67C23A;font-size:15px;">通过</p>
                        <p v-if="passStatus=='4'" style="color:#F56C6C;font-size:15px;">重新调查</p>
                  </div>  
                  <div class="fl" v-if="taskType=='1' || taskType=='4'">
                      <el-button type="danger" @click="delPolgon" class="fl ml10" size="mini" v-if="hasPolygon">重新绘制</el-button>
                      <el-button type="success" @click="drawPolgon" class="fl ml10" size="mini">绘制作业区域</el-button>
                      <el-button type="primary" @click="planPolgon" class="fl ml10" size="mini" v-if="hasPolygon">定位</el-button>
                      <el-button type="danger" @click="addFeatureFromTask" class="fl ml10" style="width:100px;">手动添加</el-button>
                  </div>
                  <el-input placeholder="请输入审核意见" v-model="auditText" class="auditText" resize="none" :readonly="this.taskType!='2'"> </el-input>
                  <el-button type="info" @click="closeForm" class="fr ml10" size="mini">关闭</el-button>
                  <el-button type="primary" @click="submitForm" class="fr ml10" size="mini" v-if="taskType=='1' || taskType=='4'">提交</el-button>
              </div>
          </div> -->
       </div>
       <el-dialog title="任务审核" :visible.sync="auditModal" :modal-append-to-body='false'>
          <el-form :model="auditForm"  ref="auditForm" label-width="100px" class="demo-auditForm">
            <el-form-item label="照片审核" style="margin-bottom:10px!important">
              <el-select v-model="auditForm.IMAGERESULT" placeholder="选择照片审核" :disabled="taskType=='3'">
                <el-option label="优" value="1"></el-option>
                <el-option label="良" value="2"></el-option>
                <el-option label="差" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="认定审核" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.RDRESULT" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.RDRESULT" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item>
            <el-form-item label="信息审核" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.INFORESULT" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.INFORESULT" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item>
            <!-- <el-form-item label="是否通过" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.RESULT" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.RESULT" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item> -->
            <el-form-item label="是否通过" style="margin-bottom:10px!important">
              <el-radio v-model="auditForm.STATUS" label="1" :disabled="taskType=='3'">通过</el-radio>
              <el-radio v-model="auditForm.STATUS" label="0" :disabled="taskType=='3'">不通过</el-radio>
            </el-form-item>
            <el-form-item label="问题类型" style="margin-bottom:10px!important">
              <el-select v-model="auditForm.ERRTYPE" placeholder="选择问题类型" multiple clearable :disabled="taskType=='3'">
                <el-option label="照片质量" value="1"></el-option>
                <el-option label="房屋类型认定错误" value="2"></el-option>
                <el-option label="房屋结构认定错误" value="3"></el-option>
                <el-option label="房屋层数认定错误" value="4"></el-option>
                <el-option label="信息填写不全" value="5"></el-option>
                <el-option label="其他" value="99"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" style="margin-bottom:10px!important">
              <el-input  v-model="auditForm.OPINION" autocomplete="off" :readonly="taskType=='3'"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitAudit()" v-if="this.taskType=='2'">提交</el-button>
              <el-button @click="auditModal=false">关闭</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
  </div>
</template>
<style scoped>
    .content{width:100%;height: 100%;overflow: hidden;position: absolute;left: 0;right: 0;bottom: 0;top: 0;z-index: +1;}
  .mapBox{width: 40%;position: absolute;left: 0;bottom: 0;top: 0;z-index: +1;}
  .formBox{width: 60%;position: absolute;right: 0;bottom: 0;top: 0;z-index: +1;}
  .txt{height: 40px;margin-left:16px;overflow: hidden;line-height: 40px;}
  .formCon{position: absolute;left: 16px;right: 16px;top: 56px; bottom: 65px;overflow: auto;}
  .btnCon{position: absolute;left: 16px;right: 16px;bottom: 0px;overflow: hidden;height: 55px;background-color: #ECECEC;}
  .formConSH{bottom:110px!important;}
  .btnConSH{height:100px!important;}
  .btnCon .btns{padding: 0 16px;margin-top: 13px;}
  /* .auditTextOut{padding: 0 16px;overflow: hidden;} */
  .auditText{margin-left: 10px;width: 50%;float:left;margin-top: -5px;}
  .el-form-item{margin-bottom: 5px!important;}
</style>
<script>
import MAP from "../components/OpenLayers"
import Overlay from 'ol/Overlay';
export default {
  name: 'CZFW',
  components:{
    MAP,
  },
  props: {
    taskType: {
      type:String,
      default:null
    },
    taskBH:{
       type:String,
      default:null
    },
  },
  data () {
      var PROVINCE = (rule, value, callback) => {
        if (value == '') {
          callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var CITY = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
           callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var DISTRICT = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
            callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var TOWN = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
           callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var VILLAGE = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
           callback(new Error('请选择正确的行政编码'));
        } else {
          callback();
        }
      };
      var FWLB=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择房屋类别'));
        } else {
          callback();
        }
      };
      var FWBH=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入房屋编号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('房屋编号只能是数字'));
        }else if(value.length>15){
             callback(new Error('房屋编号不能大于15位'));
        }else {
          callback();
        }
      };
      
      var TAOSHU_QG = (rule, value, callback) => {
        if (this.fromData.FWLB=='0110' && value === '') {
          callback(new Error('请输入套数'));
        } else {
          callback();
        }
      };
      var DSCS = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
          callback(new Error('地上层数必须为数字'));
        } else {
          callback();
        }
      };
      var DXCS = (rule, value, callback) => {
      if (value != '' && typeof value != 'number' && isNaN(value)) {
          callback(new Error('地下层数必须为数字'));
        } else {
          callback();
        }
      };
      var DCMJ = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
          callback(new Error('调查面积必须为数字'));
        } else {
          callback();
        }
      };
      var GD = (rule, value, callback) => {
        if (value != '' && typeof value != 'number' && isNaN(value)) {
          callback(new Error('建筑高度必须为数字'));
        } else {
          callback();
        }
      };
      var QTJGLX = (rule, value, callback) => {
        if (value === '' && this.fromData.CZFWJGLX=="99999") {
          callback(new Error('请输入其他结构类型'));
        } else {
          callback();
        }
      };
      var SUB_JGLXA = (rule, value, callback) => {
        if (value === '' && this.fromData.CZFWJGLX=="1" && this.fromData.FWLB=='0110') {
          callback(new Error('请选择二级结构类型'));
        } else {
          callback();
        }
      };
      var SUB_JGLXB = (rule, value, callback) => {
        if (value === '' && this.fromData.CZFWJGLX=="1001" && this.fromData.FWLB=='0120') {
          callback(new Error('请选择二级结构类型'));
        } else {
          callback();
        }
      };
      var SUB_JGLXC = (rule, value, callback) => {
        if (value === '' && this.fromData.CZFWJGLX=="2003" && this.fromData.FWLB=='0120') {
          callback(new Error('请选择二级结构类型'));
        } else {
          callback();
        }
      };
      var FWYT = (rule, value, callback) => {
        if (value === ''&& this.fromData.FWLB=='0120') {
          callback(new Error('请选择非住宅房屋用途'));
        } else {
          callback();
        }
      };
      var FWYTA = (rule, value, callback) => {
        if (value === ''&& this.fromData.FWLB=='0120' && this.fromData.FWYT=='19') {
          callback(new Error('请选择非住宅房屋综合用途'));
        } else {
          callback();
        }
      };
      var FWYTB = (rule, value, callback) => {
        if (value === ''&& this.fromData.FWLB=='0120' && this.fromData.FWYT=='1009') {
          callback(new Error('请选择非住宅房屋文化用途'));
        } else {
          callback();
        }
      };
      var FWYTC = (rule, value, callback) => {
        if (value === ''&& this.fromData.FWLB=='0120' && this.fromData.FWYT=='1011') {
          callback(new Error('请选择非住宅房屋商业用途'));
        } else {
          callback();
        }
      };
      var FWYTD = (rule, value, callback) => {
        if (value === ''&& this.fromData.FWLB=='0120' && this.fromData.FWYT=='15') {
          callback(new Error('请选择非住宅房屋办公用途'));
        } else {
          callback();
        }
      };
      var FWYTE = (rule, value, callback) => {
        if (value === ''&& this.fromData.FWLB=='0120' && this.fromData.FWYT=='99999') {
          callback(new Error('请输入非住宅房屋其他用途'));
        } else {
          callback();
        }
      };
      var GZSJ = (rule, value, callback) => {
        if (this.fromData.SFSZCG=='1' && value === '') {
          callback(new Error('请输入改造时间'));
        } else {
          callback();
        }
      };
      var JGSJ = (rule, value, callback) => {
        if (this.fromData.SFKZJG=='1' && value === '') {
          callback(new Error('请输入加固时间'));
        } else {
          callback();
        }
      };

      return {
        showXZQSelect:false,
        txt:"",
        readonly:false,
        xzqprops: {
          lazy: true,
          lazyLoad :(node, resolve)=> {
            var guid=""
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({GUID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({GUID:guid})).toUpperCase())
            this.$http.post("Manager/GetXzqById",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    //var type=parseInt(l[i].TYPE) 
                    nodes.push({
                      value:l[i].CODE,
                      label:l[i].NAME,
                      guid:l[i].GUID,
                      leaf: l[i].CHILDCOUNT==0
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
        },
        XZBM:[],

        DCSJA:"",
        DCSJB:"",
        fromData:{
          BH:"",
          PROVINCE:"",
          CITY:"",
          DISTRICT:"",
          TOWN:"",
          VILLAGE:"",
          FWBH:"",
          FWLB:"",
          XCDCQK:"",
          BXDCYY:"",
          MC:"",
          LU_QG:"",
          HAO_QG:"",
          DONG_QG:"",
          JZMC:"",
          TAOSHU_QG:"",
          BUILD_TIME:"",
          DSCS:"",
          DXCS:"",
          DCMJ:"",
          GD:"",
          SFCQDJ:"",
          CQDW:"",
          CZFWJGLX:"",
          QTJGLX:"",
          SUB_JGLX:"",
          FWYT:"",
          SUB_FWYT:"",
          QTFWYT:"",
          SFCYJGZ:"",
          SFBHXJZ:"",
          SFZYSJJZ:"",
          SFSZCG:"",
          GZSJ:"",
          SFKZJG:"",
          JGSJ:"",
          YWLFBXQX:"",
          LFBXQXZP:"",
          YWWYGL:"",
          PHOTO:"",
          DCR:"",
          LXDH:"",
          BZ:"",
          DCSJ:"",
          STATUS:"1",
        },
        fromrules:{
          PROVINCE: [
            { validator: PROVINCE, trigger: 'blur' }
          ],
          CITY: [
            { validator: CITY, trigger: 'blur' }
          ],
          DISTRICT: [
            { validator: DISTRICT, trigger: 'blur' }
          ],
          DISTOWNTRICT: [
            { validator: TOWN, trigger: 'blur' }
          ],
          VILLAGE: [
            { validator: VILLAGE, trigger: 'blur' }
          ],
          FWLB: [
            { validator: FWLB, trigger: 'blur' }
          ],
          FWBH: [
            { validator: FWBH, trigger: 'blur' }
          ],
          TAOSHU_QG:[
            { validator: TAOSHU_QG, trigger: 'blur' }
          ],
          DSCS:[
            { validator: DSCS, trigger: 'blur' }
          ],
          DXCS:[
            { validator: DXCS, trigger: 'blur' }
          ],
          DCMJ:[
            { validator: DCMJ, trigger: 'blur' }
          ],
          GD:[
            { validator: GD, trigger: 'blur' }
          ],
          QTJGLX:[
            { validator: QTJGLX, trigger: 'blur' }
          ],
          SUB_JGLXA:[
            { validator: SUB_JGLXA, trigger: 'blur' }
          ],
          SUB_JGLXB:[
            { validator: SUB_JGLXB, trigger: 'blur' }
          ],
          SUB_JGLXC:[
            { validator: SUB_JGLXC, trigger: 'blur' }
          ],
          FWYT:[
            { validator: FWYT, trigger: 'blur' }
          ],
          FWYTA:[
            { validator: FWYTA, trigger: 'blur' }
          ],
          FWYTB:[
            { validator: FWYTB, trigger: 'blur' }
          ],
          FWYTC:[
            { validator: FWYTC, trigger: 'blur' }
          ],
          FWYTD:[
            { validator: FWYTD, trigger: 'blur' }
          ],
          FWYTE:[
            { validator: FWYTE, trigger: 'blur' }
          ],
          GZSJ:[
            { validator: GZSJ, trigger: 'blur' }
          ],
          JGSJ:[
            { validator: JGSJ, trigger: 'blur' }
          ],
        },
        auditText:"",
        hasPolygon:false,
        polygonItem:null,
        map:null,

        epsgKey:"EPSG:4490",
        epsgValue:"+proj=longlat +ellps=GRS80 +no_defs",
        epsgExtent:[73.62,20,134,50],
        isEditPolygon:false,

        lfPhoto:[],
        lfPhotos:[],
        kzPhoto:[],
        kzPhotos:[],
        photo:[],
        photos:[],
        passStatus:"",

        isLost:"0",
        auditForm:{
          CASEGUID :this.$store.state.taskGuid,
          USERGUID:this.$store.state.guid,
          IMAGERESULT:"1",
          RDRESULT:"1",
          INFORESULT:"1",
          RESULT:"1",
          STATUS:"1",
          ERRTYPE:[],
          OPINION:"",
        },
        auditModal:false,
      }
  },
  watch: {
    XZBM(newVal,oldVal){
      if(this.showXZQSelect){
         this.fromData.PROVINCE=newVal[0]
         this.fromData.CITY=newVal[1]
         this.fromData.DISTRICT=newVal[2]
         this.fromData.TOWN=newVal[3]
         this.fromData.VILLAGE=newVal[4]
         this.getXZQEPSG()
      }
    },
  },
  mounted() {
    this.passStatus=this.$store.state.taskStatus
    this.loadItem=null
     //console.log(this.taskType)
    if(this.taskType=="1"){
       this.txt="编辑任务"
    }else if(this.taskType=="2"){
      this.txt="任务审核"
      this.readonly=true
    }
    else if(this.taskType=="3"){
      this.txt="查看审核"
      this.readonly=true
    }
    else if(this.taskType=="4"){
      this.txt="新建任务"
      this.showXZQSelect=true
    }
    else if(this.taskType=="5"){
      this.txt="查看任务"
       this.readonly=true
    }

    if(this.taskType!="4"){
      this.getTaskData()
    }
    this.map=this.$refs.map.map
    this.hasPolygon=false
    this.polygonItem=null

   
  },
  computed: {
    //其他结构类型
    qtjglx(){
      var f=false
      if(this.fromData.FWLB=="0110" && this.fromData.CZFWJGLX=="99999" || this.fromData.FWLB=="0120" && this.fromData.CZFWJGLX=="99999"){
        f=true
      }
      return f
    },
    //二级结构类型
    ejjglxA(){
      var f=false
      if(this.fromData.FWLB=="0110" && this.fromData.SUB_JGLX=="1" ){
        f=true
      }
      return f  
    },
    ejjglxB(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.SUB_JGLX=="1001"){
        f=true
      }
      return f  
    },
    ejjglxC(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.SUB_JGLX=="2003"){
        f=true
      }
      return f  
    },
    //城镇非住宅二级用途
    czfzzrjfwtyA(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.FWYT=="19"){
        f=true
      }
      return f  
    },
    czfzzrjfwtyB(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.FWYT=="1009"){
        f=true
      }
      return f  
    },
    czfzzrjfwtyC(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.FWYT=="1011"){
        f=true
      }
      return f 
    },
    czfzzrjfwtyD(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.FWYT=="15"){
        f=true
      }
      return f 
    },
    czfzzrjfwtyE(){
      var f=false
      if(this.fromData.FWLB=="0120" && this.fromData.FWYT=="99999"){
        f=true
      }
      return f 
    },
  },
  methods:{
    //显示编辑行政区
    changeXZQ(){
      this.showXZQSelect=true
    },
    //获取属性
    getTaskData(){
      this.showLoading("请稍后...")
      var d={TYPE:"1",BH:this.taskBH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/GetObjectInfo", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
                this.isLost=response.data.DATA.ISDELETE
                this.fromData =response.data.DATA
                if(this.fromData.DCSJ && this.fromData.DCSJ!=""){
                      var  sj=this.fromData.DCSJ.split(" ")
                      this.DCSJA=sj[0]
                      this.DCSJB=new Date(this.fromData.DCSJ)
                    }
                this.XZBM=[this.fromData.PROVINCE,this.fromData.CITY,this.fromData.DISTRICT,this.fromData.TOWN,this.fromData.VILLAGE,] 
          }else{
            this.errorRequest("22","调查对象查询失败",true)
                this.closeForm()
          }
        })
        .catch((error) => {
          this.hideLoading()
        });
        this.getTaskPolygon()
        if(this.$store.state.taskGuid!=""){
          if(this.$store.state.taskStatus=="3" || this.$store.state.taskStatus=="4"){
             this.getAuditInfo()
          }
        }
    },
    //获取图形
    getTaskPolygon(){
      var d={BH:this.taskBH}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/ShowPhotoAndZB", md5_data)
        .then((response) => {
          //console.log("调查对象图形")
          var d=response.data.DATA
          this.transformMapDatas(d)
          var dps=d.PHOTO
          var fps=d.FUPHOTOLIST
          for(var i=0;i<fps.length;i++){
            dps.push(fps[i])
          }
          this.setMapPhoto(dps)
        })
    },
    transformMapDatas(data){
       //console.log(data)
      if(this.$refs.map){
        var epsgK="EPSG:4490"
        var epsgV="+proj=longlat +ellps=GRS80 +no_defs"
        var epsgE=[73.62,20,134,50]
        if(data.EPSG && data.EPSG!=""){
            epsgK=data.EPSG
        }
        if(data.EPSGVALUE && data.EPSGVALUE!=""){
          epsgV=data.EPSGVALUE
        }
        if(data.EPSGEXTENT && data.EPSGEXTENT!=""){
          epsgE=data.EPSGEXTENT.split(",")
        }
        this.epsgKey=epsgK
        this.epsgValue=epsgV
        this.epsgExtent=epsgE
        //console.log(this.$store.state.epsg)
        if(this.epsgKey != this.$store.state.epsg){
          this.$refs.map.setNewView(epsgK,epsgV,epsgE)
        }
        if(data.ZB && data.ZB!=""){
          var coors=this.comjs.getCoordinateByStr(data.ZB)
          var  center=coors[0][0]
          this.$refs.map.appendFeature("Polygon","editczfw",coors,false,this.afterDraw)
          this.$refs.map.moveTo(center,18)
        }
        this.lfPhoto=[]
        this.lfPhotos=[]
        this.kzPhoto=[]
        this.kzPhotos=[]
        this.photo=[]
        this.photos=[]
        if(data.PHOTO && data.PHOTO.length>0){
          for(var p=0;p<data.PHOTO.length;p++){
             var dp=data.PHOTO[p]
             dp.str=this.comjs.getAzimuthStr(dp.DIRECTION)
             this.photo.push(dp)

          }
          for(var i=0;i<data.PHOTO.length;i++){
              this.photos.push(data.PHOTO[i].FILE_PATH)
          }
        }
        if(data.LFPHOTOLIST && data.LFPHOTOLIST.length>0){
          for(var l=0;l<data.LFPHOTOLIST.length;l++){
             var lp=data.LFPHOTOLIST[l]
             lp.str=this.comjs.getAzimuthStr(lp.DIRECTION)
             this.lfPhoto.push(lp)

          }
          for(var h=0;h<data.LFPHOTOLIST.length;h++){
              this.lfPhotos.push(data.LFPHOTOLIST[h].FILE_PATH)
          }
        }
        if(data.KZPHOTOLIST && data.KZPHOTOLIST.length>0){
          for(var z=0;z<data.KZPHOTOLIST.length;z++){
             var kp=data.KZPHOTOLIST[z]
             kp.str=this.comjs.getAzimuthStr(kp.DIRECTION)
             this.kzPhoto.push(kp)
          }
          for(var k=0;k<data.KZPHOTOLIST.length;k++){
              this.kzPhotos.push(data.KZPHOTOLIST[k].FILE_PATH)
          }
        }
      }else{
        setTimeout(()=>{
          this.transformMapDatas(data)
        },100)
      }
    },
    setMapPhoto(d){
      var oys=this.map.getOverlays().array_
      var _this=this
      for(var t=0;t<oys.length;t++){
        let d=oys[t]
        setTimeout(function(){
          _this.map.removeOverlay(d)
        },50)
      }
      //加载地图上的照片
      for(var i=0;i<d.length;i++){
            let div = document.createElement("div"); 
            div.className="photoOverlay"

            let arrow=document.createElement("img"); 
            arrow.className="arrow"
            arrow.setAttribute("src","statics/img/map/arrow.png")
            div.appendChild(arrow);
            arrow.style.transform="rotate("+d[i].DIRECTION+"deg)"
            let p=document.createElement("p"); 
            p.innerHTML = d[i].DIRECTION+"("+this.comjs.getAzimuthStr(d[i].DIRECTION)+")"
            p.className="ptxt"
            div.appendChild(p);
            let img= document.createElement("img"); 
            img.className="pimg"
            img.setAttribute("src",d[i].FILE_PATH)
            div.appendChild(img);
            let oy = new Overlay({
              id:d[i].ID,
              element: div,
              offset: [-15, -15],
              position:[d[i].LON,d[i].LAT],
            });
            this.map.addOverlay(oy);
      }
    },
    //获取审核信息
    getAuditInfo(){
      //console.log("获取审核意见")
      var d={CASEGUID:this.$store.state.taskGuid}
      var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
      this.$http.post("Manager/GetOpinion", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
            var d=response.data.DATA
            var er=[]
            if(d.ERRTYPE!=""){
              if(d.ERRTYPE.indexOf(",") != -1){             
                er =d.ERRTYPE.split(",")
              }else{
                 er=[d.ERRTYPE]
              }
            }
            d.ERRTYPE = er
            if(this.passStatus=="3"){
              d.STATUS="1"
            }else if(this.passStatus=="4"){
              d.STATUS="0"
            }
            this.auditForm=d
          }
        })
    },
    //处理数据格式
    formartDatas(){
      var d=this.fromData
      // for(var item in d){
      //   if(d[item]==""){
      //     d[item]=null
      //   }
      // }
      if(this.DCSJB && this.DCSJB!=""){
          let hh = this.DCSJB.getHours()
          if (hh < 10) hh = "0" + hh
          let mf = this.DCSJB.getMinutes() < 10 ? '0' + this.DCSJB.getMinutes()
            :
            this.DCSJB.getMinutes()
          let ss = this.DCSJB.getSeconds() < 10 ? '0' + this.DCSJB.getSeconds()
            :
            this.DCSJB.getSeconds()

          this.DCSJB=hh+":"+mf+":"+ss
      }
      if(this.DCSJA !="" && this.DCSJB!=""){
        d.DCSJ=this.DCSJA+" "+this.DCSJB
      }
      if(d.TAOSHU_QG){
        d.TAOSHU_QG=parseInt(d.TAOSHU_QG)
      }
      if(d.DSCS){
        d.DSCS=parseInt(d.DSCS)
      }

      if(d.DXCS){
         d.DXCS=parseInt(d.DXCS)
      }
      if(d.DCMJ){
        d.DCMJ=parseFloat(d.DCMJ)
      }
      if(d.GD){
        d.GD=parseFloat(d.GD)
      }
      if(d.STATUS){
         d.STATUS=parseInt(d.STATUS)
      }
      this.fromData=d
    },


    //提交
    submitForm(){
      //console.log(this.taskType)
      //编辑
      if(this.taskType=="1"){   
        this.submitFormForEdit()
      }
      //添加
      else if(this.taskType=="4"){
          this.submitFormForAdd()
      }
     
    },
    submitFormForEdit(){
        this.$refs.form.validate((valid) => {
          if (valid) {
            if(this.hasPolygon){
              this.sendEditTask()
           }else{
             this.$message.error("请绘制作业区域！")
           }
          }
        })
    },
    sendEditTask(){
        this.showLoading("请稍后...")
        this.formartDatas()
        var md5_data =this.comjs.requestDataEncrypt(this.fromData, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fromData)).toUpperCase())
        this.$http.post("Manager/EditCityHouseObjectInfo", md5_data)
          .then((response) => {
            //console.log(response)
            this.hideLoading()
            if (response.data.SUCCESS) {
                  if(this.isEditPolygon){
                      this.AddTaskPolygon(this.fromData.BH)
                  }else{
                    this.$message.success("已保存");
                    this.$emit('closeEditTask',null);
                  }
            } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.$message.error("提交失败，请稍后再试！");
          });
    },
    submitFormForAdd(){
      this.$refs.form.validate((valid) => {
        if (valid) {
           if(this.hasPolygon){
              this.sendAddTask()
           }else{
             this.$message.error("请绘制作业区域！")
           }
        } else {
          return false;
        }
      });
    },
    //
    sendAddTask(){
      this.showLoading("请稍后...")
      this.formartDatas()
      var md5_data =this.comjs.requestDataEncrypt(this.fromData, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fromData)).toUpperCase())
      this.$http.post("Manager/CreateCityHouse", md5_data)
        .then((response) => {
          //console.log(response)
          if (response.data.SUCCESS) {
                this.AddTaskPolygon(response.data.DATA)
          } else {
            this.hideLoading()
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("提交失败，请稍后再试！");
        });
    },
    getXZQEPSG(){
      var md5_data =this.comjs.requestDataEncrypt({CODE:this.fromData.DISTRICT}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CODE:this.fromData.DISTRICT})).toUpperCase())
      this.$http.post("Manager/GetXzqByCode",md5_data)
      .then((response) => {
        if(response.data.SUCCESS){   
            var l=response.data.DATA
            var epsgK="EPSG:4490"
            var epsgV="+proj=longlat +ellps=GRS80 +no_defs"
            var epsgE=[73.62,20,134,50]
            if(l.EPSG && l.EPSG!=""){
                epsgK=l.EPSG
            }
            if(l.EPSGVALUE && l.EPSGVALUE!=""){
              epsgV=l.EPSGVALUE
            }
            if(l.EPSGEXTENT && l.EPSGEXTENT!=""){
              epsgE=l.EPSGEXTENT.split(",")
            }
            if(this.epsgKey != epsgK){
              this.epsgKey=epsgK
              this.epsgValue=epsgV
              this.epsgExtent=epsgE
              this.$refs.map.setNewView(epsgK,epsgV,epsgE)
              this.$message.error("行政区坐标系发生变化，请重新绘制图形！")
              if(this.hasPolygon){
                this.delPolgon()
              }
            }
        }else{
          this.errorRequest(response.data.DATA,response.data.MSG,false)
        }
      })
    },
    AddTaskPolygon(bh){
        var cs=this.polygonItem.getGeometry().getCoordinates()
        var params={
          BH:bh,
          POLYGONLIST:cs,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Manager/InsertOrEditCityHouseZB", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.$message.success('已保存');
          } else {
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
          this.$emit('closeEditTask',null);
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("调查对象区域添加失败，请联系管理员手动添加！");
           this.$emit('closeEditTask',null);
        });
    },
    //关闭
    closeForm(){
       this.$emit('closeEditTask',null);
    },
    toAudit(){
      this.auditForm={
          CASEGUID :this.$store.state.taskGuid,
          USERGUID:this.$store.state.guid,
          IMAGERESULT:"1",
          RDRESULT:"1",
          INFORESULT:"1",
          RESULT:"1",
          STATUS:"1",
          ERRTYPE:[],
          OPINION:"",
      },
      this.auditModal=true
    },
    submitAudit(){
      var estr=""
      if(this.auditForm.ERRTYPE.length>0){
        for(var i=0;i<this.auditForm.ERRTYPE.length;i++){
          if(i==0){
            estr=this.auditForm.ERRTYPE[i]
          }else {
            estr=estr+","+this.auditForm.ERRTYPE[i]
          }
        }
      }
      this.auditForm.ERRTYPE = estr
      if(this.auditForm.IMAGERESULT=="3" || this.auditForm.RDRESULT=="0" || this.auditForm.INFORESULT=="0" || this.auditForm.RESULT=="0"|| this.auditForm.STATUS=="0"|| this.auditForm.ERRTYPE!=""){
          this.$confirm('审核内容有不通过项，是否继续提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
              this.sendAudit()
            })
      }else{
        this.sendAudit()
      }
    },
    sendAudit(){
      var md5_data =this.comjs.requestDataEncrypt(this.auditForm, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.auditForm)).toUpperCase())
      this.$http.post("Manager/AuditCase", md5_data)
        .then((response) => {
          this.hideLoading()
          if (response.data.SUCCESS) {
              this.$message.success("审核完成！")
              this.auditForm.BH=this.taskBH
              this.$emit('closeEditTask',this.auditForm);
          } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("请求失败，请稍后再试！");
      });
    },
    //添加一个要素
    addFeatureFromTask(){
      var coor=[
        [
          [12359181.590922818, 13416647.923091818],
          [12738309.251217293, 13470459.591004582],
          [12757877.130458297, 13128021.704286993],
          [12481480.8361791, 12966586.700548701],
          [12283356.058863923, 13132913.674097244],
          [12359181.590922818, 13416647.923091818],
      ],
      [
        [12459466.97203297, 13353052.31555855],
        [12664929.704063524, 13365282.240084179],
        [12684497.583304528, 13181833.372199757],
        [12454575.002222719, 13093777.915615233],
        [12368965.53054332, 13198955.266535636],
        [12459466.97203297, 13353052.31555855],
      ],
      [
        [12359181.590922818, 13778653.689050412],
        [12601334.096530257, 13768869.74942991],
        [12554860.383332869, 13617218.68531212],
        [12288248.028674174, 13597650.806071116],
        [12254004.240002416, 13690598.23246589],
        [12359181.590922818, 13778653.689050412],
      ]
    ]
    //console.log(coor)
    this.$refs.map.appendFeature("Polygon","editczfw",coor,false,this.afterDraw)
    },
    //删除绘制的面
    delPolgon(){
        var type=this.polygonItem.getGeometry().getType()
        var fid=this.polygonItem.getId()
        this.$refs.map.delDrawFeature(fid,type)
        this.hasPolygon=false
        this.polygonItem=null
        this.isEditPolygon=true
    },
    //绘制面
    drawPolgon(){
        this.$refs.map.drawPolygon({"from":"czfw"},this.afterDraw)
    },
    //移动到绘制的面
    planPolgon(){
        var center=this.polygonItem.getGeometry().getCoordinates()[0][0]
        //console.log(this.polygonItem.getGeometry().getCoordinates())
        this.$refs.map.moveTo(center,18)
    },
    //绘制和手动添加完的回调
    afterDraw(fea){
      var type=fea.getGeometry().getType()
      var fid=fea.getId()
      //console.log(fea.getGeometry().getCoordinates())
      //移除删除按钮
      var  overlays=this.map.getOverlays().array_
      for(var i=0;i<overlays.length;i++){
          if(overlays[i].get("fid")== fid){
            this.map.removeOverlay(overlays[i])
          }
      }
      //修改要素样式
      this.$refs.map.setFeaStyle(fea,"rgba(103,194,58,0.2)","#67C23A","rgba(103,194,58,0.2)")
      //判断是否为基础要素（最外层）
      if(! this.hasPolygon && !this.polygonItem){
          this.hasPolygon=true
          this.polygonItem=fea
      } else{
        var f1=this.polygonItem.getGeometry().getCoordinates()[0]
        var f2=fea.getGeometry().getCoordinates()[0]
        //判断是否包含
        var turf1= this.$refs.map.getTurfPolygon(f1,"EPSG:3857")
        var turf2= this.$refs.map.getTurfPolygon(f2,"EPSG:3857")
        var include=this.$refs.map.isInclude(turf1,turf2)
        if(include){
            var coors=this.polygonItem.getGeometry().getCoordinates()
            var nf=[]
            //判断内部是否有多个镂空区域
            if(coors.length==1){
              nf=[f1,f2]
              this.polygonItem.getGeometry().setCoordinates(nf)  
            }else{
                var hasOver=true 
                for(var j=1;j<coors.length;j++){
                    var turf3=this.$refs.map.getTurfPolygon(coors[j],"EPSG:3857")
                    if(this.$refs.map.isInclude(turf3,turf2) || this.$refs.map.isOverlay(turf2,turf3)){
                      hasOver=false
                      break;
                    }
                }
                if(hasOver){
                    coors.push(f2)
                    this.polygonItem.getGeometry().setCoordinates(coors)  
                }else{
                  this.$message.error("内部区域不能有交集！")
                }
            }
        }else{
          this.$message.error("请在已绘制区域的内部继续绘制！")
          
        }
        //处理完之后删除绘制的要素
        setTimeout(() => {
           this.$refs.map.delDrawFeature(fid,type)
        }, 50);
       

      }
    },
        //删照片
    delPhoto(id,type){
      this.$confirm('确定删除照片吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.sendDelPhoto(id,type)
        }).catch(() => {
         this.$message.info('已取消');    
      });
    },
    sendDelPhoto(id,type){
      this.showLoading("请稍后...")
      var params={
        ID :id,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Manager/DeletePhoto", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.$message.success("已删除！")
            this.delPagePhoto(id,type)
          } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.hideLoading()
          this.$message.error("请求失败，请稍后再试！");
      });
    },
    delPagePhoto(id,type){
      var np=[]
      var nps=[]
      var fp=""
      if(type=="1"){
        for(var a=0;a<this.photo.length;a++){
            if(this.photo[a].ID==id){
                fp=this.photo[a].FILE_PATH
            }else{
              np.push(this.photo[a])
            }
        }
        for(var b=0;b<this.photos.length;b++){
           if(this.photos[b]!=fp){
             nps.push(this.photos[b])
           }
        }
        this.photo=np
        this.photos=nps
         this.delMapPhoto(id)
      }else if(type=="2"){
        for(var c=0;c<this.fuPhoto.length;c++){
            if(this.fuPhoto[c].ID==id){
                fp=this.fuPhoto[c].FILE_PATH
            }else{
              np.push(this.fuPhoto[c])
            }
        }
        for(var d=0;d<this.fuPhotos.length;d++){
           if(this.fuPhotos[d]!=fp){
             nps.push(this.fuPhotos[d])
           }
        }
        this.fuPhoto=np
        this.fuPhotos=nps
         this.delMapPhoto(id)
      }else if(type=="3"){
        for(var e=0;e<this.kzPhoto.length;e++){
            if(this.kzPhoto[e].ID==id){
                fp=this.kzPhoto[e].FILE_PATH
            }else{
              np.push(this.kzPhoto[e])
            }
        }
        for(var f=0;f<this.kzPhotos.length;f++){
           if(this.kzPhotos[f]!=fp){
             nps.push(this.kzPhotos[f])
           }
        }
        this.kzPhoto=np
        this.kzPhotos=nps        
      }else if(type=="4"){
        for(var g=0;g<this.lfPhoto.length;g++){
            if(this.lfPhoto[g].ID==id){
                fp=this.lfPhoto[g].FILE_PATH
            }else{
              np.push(this.lfPhoto[g])
            }
        }
        for(var h=0;h<this.lfPhotos.length;h++){
           if(this.lfPhotos[h]!=fp){
             nps.push(this.lfPhotos[h])
           }
        }
        this.lfPhoto=np
        this.lfPhotos=nps          
      }
    },
    delMapPhoto(id){
      var oys=this.map.getOverlayById(id)
      this.map.removeOverlay(oys)
    },
  }
}
</script>

